import React from 'react';
import PaypalBtn from '@components/layout/PaymentPaypalBtn';
import CardBtn from '@components/layout/PaymentCardBtn';
import Bank from '@components/layout/Bank';
import CardImages from '@components/layout/CardImages';
import TermsOfService from '@components/layout/TermsOfService';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const PaymentWays = (props) => {
  const [selectedValue, setSelectedValue] = React.useState();
  const [agreeToTerms, setAgreeToTerms] = React.useState(false);
  const [showTermsError, setShowTermsError] = React.useState(false);
  const [fields, setFields] = React.useState({
    email: props.user.info.email || props.fields.email,
    country: 'GR',
    state: '',
    city: '',
    zipCode: '',
    address: '',
    phoneNumber: '',
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleAgreeToTerms = (event) => {
    setAgreeToTerms(event.target.checked);
  };
  const handleChangeFields = (field, e) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  return (
    <React.Fragment>
      <div className="all-payment-ways">
        <div className="total-costs-title">
          {`2. ${window.lang[props.language]['paymentOptions']}`}
        </div>
        {!(!props.paypal && !props.bank && !props.card) && (
          <TermsOfService
            {...props}
            checked={agreeToTerms}
            handleAgreeToTerms={handleAgreeToTerms}
          />
        )}
        {!agreeToTerms && showTermsError && (
          <div className="agree-to-terms-error">
            {`${window.lang[props.language]['pleaseAgreeToTerms']}`}
          </div>
        )}
        <FormControl component="fieldset" disabled={!agreeToTerms}>
          <RadioGroup
            row
            aria-label="way"
            name="way"
            className="radio-positioner"
            style={{ flexDirection: 'column' }}>
            {props.bank && (
              <FormControlLabel
                value="bank"
                onChange={handleChange}
                onClick={() => {
                  if (!agreeToTerms) setShowTermsError(true);
                }}
                control={
                  <Radio
                    color="primary"
                    style={{
                      color:
                        props.checked === true
                          ? window.colorPalette.primary[600]
                          : '',
                    }}
                  />
                }
                label={
                  <div className="payment-ways">
                    <label className="payment-way-title">
                      <img
                        className={`payment-icon payment-icon-bank`}
                        src={`${process.env.PUBLIC_URL}/ecommerce/bankPayment.svg`}
                      />
                      {window.lang[props.language]['bankTransfer']}
                    </label>
                    <p className="payment-way-explanation">
                      {window.lang[props.language]['bankTransferOption']}
                    </p>
                  </div>
                }
              />
            )}
            <div className="payment-settings">
              {selectedValue == 'bank' && agreeToTerms && (
                <React.Fragment>
                  <Bank {...props} />
                  <Button
                    id="submit-payment"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      return props.bank.submitPayment();
                    }}>
                    <Typography
                      style={{
                        color: 'white',
                        textTransform: 'none',
                        textAlign: 'center',
                        flex: 1,
                      }}>
                      {props.loading ? (
                        <CircularProgress size={24} className="submit-loader" />
                      ) : (
                        window.lang[props.language]['completeRegistration']
                      )}
                    </Typography>
                  </Button>
                </React.Fragment>
              )}
            </div>
            {props.card && (
              <FormControlLabel
                value="card"
                onChange={handleChange}
                control={<Radio color="primary" />}
                label={
                  <div className="payment-ways">
                    <label className="payment-way-title">
                      <img
                        className={`payment-icon payment-icon-card`}
                        src={`${process.env.PUBLIC_URL}/ecommerce/cardPayment.svg`}
                      />
                      {window.lang[props.language]['creditOrDebitCard']}
                    </label>
                    <p className="payment-way-explanation">
                      {window.lang[props.language]['creditCardOption']}
                    </p>
                    <CardImages />
                  </div>
                }
              />
            )}
            <div className="payment-settings">
              {selectedValue == 'card' && agreeToTerms && (
                <React.Fragment>
                  <CardBtn
                    {...props.card}
                    fields={fields}
                    language={props.language}
                    onChange={handleChangeFields}
                  />
                  <Button
                    className="submit-payment"
                    id="submit-payment"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      props.card.submitPayment
                        ? props.card.submitPayment(fields)
                        : props.submitPayment(
                            props.eventId,
                            Object.keys(props.products).map((k) =>
                              k.toLowerCase()
                            ),
                            props.totalPrice,
                            props.currencyIso,
                            fields,
                            props.billing.collaboratorName
                          );
                    }}>
                    <Typography style={{ color: 'white' }}>
                      {window.lang[props.language]['proceedPayment']}
                    </Typography>
                  </Button>
                </React.Fragment>
              )}
            </div>
            {props.paypal && (
              <FormControlLabel
                value="paypal"
                onChange={handleChange}
                control={<Radio color="primary" />}
                label={
                  <div className="payment-ways">
                    <label className="payment-way-title">
                      <img
                        className={`payment-icon payment-icon-paypal`}
                        src={`${process.env.PUBLIC_URL}/ecommerce/paypalPayment.svg`}
                      />
                      PayPal
                    </label>
                    <p className="payment-way-explanation">
                      {window.lang[props.language]['paypalOption']}
                    </p>
                  </div>
                }
              />
            )}
            <div className="payment-settings">
              {selectedValue == 'paypal' && agreeToTerms && (
                <PaypalBtn
                  {...props.paypal}
                  language={props.language}
                  clientId={props.clientId}
                />
              )}
            </div>
          </RadioGroup>
        </FormControl>
        {!props.paypal && !props.bank && !props.card && (
          <div className="warning-message">
            <div className="warning-icon-wrapper">
              <ReportProblemOutlinedIcon className="warning-icon" />
            </div>
            <div className="warning-message-text">
              {window.lang[props.language]['noCommonPayment']}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

PaymentWays.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
  products: PropTypes.array,
  eventId: PropTypes.number,
  submitPayment: PropTypes.func,
  fields: PropTypes.object,
  supportEmail: PropTypes.string,
  language: PropTypes.string,
  currencyIso: PropTypes.string,
  checked: PropTypes.bool,
  paypal: PropTypes.number,
  card: PropTypes.number,
  bank: PropTypes.number,
  billing: PropTypes.obj,
  totalPrice: PropTypes.number,
  clientId: PropTypes.number,
};

export default PaymentWays;
