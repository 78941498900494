import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

/*if vimeo
  live = if first call = onSeek
  ondemand = if first call = onProgress



vimeo ondemand
- first play on progress

- onplay (if status == play return false)
- onpause pauses
- onseek if status == play pause on previous second

vimeo live
- onplay returns false always
- onpause pauses
- onseek plays

youtube ondemand
- onplay plays
- onpause on previous second
- onseek nothing

youtube live
- onplay plays
- onpause on previous second
- onseek nothing


file player
- onplay plays
- onpause pause on previous
- onseek nothing

*/
export default class KMBReactPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      file: null,
      image: null,
      videoTitle: null,
    };
    this.playerType = null;
    this.playerFirstCall = false;
    this.playerLive = false;
    this.firstPlay = false;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      this.setState(
        {
          image: nextProps.image,
          file: null,
          videoTitle: nextProps.videoTitle,
          videoImage: nextProps.videoImage,
        },
        () => {
          this.setState({
            image: nextProps.image,
            file: nextProps.file,
            videoTitle: nextProps.videoTitle,
          });
        }
      );
    }
  }
  UNSAFE_componentWillMount() {
    this.setState({
      image: this.props.image,
      file: this.props.file,
      videoTitle: this.props.videoTitle,
    });
  }

  render() {
    if (this.state.file === null) {
      return <div />;
    }
    return (
      <div className="react-jw-player-container">
        <ReactPlayer
          ref={(ref) => (this.playerRef = ref)}
          url={this.state.file}
          controls={true}
          className="react-player"
          muted={this.props.isAutoPlay ? true : false}
          light={this.state.image ? this.state.image : false}
          width="100%"
          height="100%"
          playing={this.props.isAutoPlay ? true : false}
          onReady={() => {
            this.playerType =
              this.playerRef.player.player.constructor.name.toLowerCase();
            this.props.onReady && this.props.onReady(this.playerRef);
          }}
          onStart={() => {
            this.props.onStart && this.props.onStart();
          }}
          onPlay={() => {
            if (this.playerType == 'vimeo' && this.playerLive) {
              return false;
            }
            if (this.playerRef.getCurrentTime() !== null) {
              this.props.onPlay(this.playerRef);
            }
          }}
          onProgress={() => {
            if (this.playerRef.getCurrentTime() !== null) {
              if (this.playerType == 'vimeo') {
                this.playerFirstCall = true;
                if (!this.playerLive && !this.firstPlay) {
                  this.firstPlay = true;
                  this.props.onPlay(this.playerRef);
                }
              }
              this.props.onProgress && this.props.onProgress(this.playerRef);
            }
          }}
          onDuration={() => {}}
          onPause={() => {
            if (this.playerRef.getCurrentTime() !== null) {
              return this.props.onPause(this.playerRef, true);
            }
          }}
          onBuffer={() => {}}
          onBufferEnd={() => {}}
          onSeek={() => {
            if (this.playerType == 'vimeo') {
              if (!this.playerFirstCall) {
                this.playerFirstCall = true;
                this.playerLive = true;
              }
            }
            this.props.onSeek &&
              this.props.onSeek(this.playerRef, this.playerLive);
          }}
          onEnded={() => {
            this.props.onEnded && this.props.onEnded(this.playerRef);
          }}
          onError={() => {
            this.props.onError && this.props.onError(this.playerRef);
          }}
          onEnablePIP={() => {}}
          onDisablePIP={() => {}}
        />
      </div>
    );
  }
}
KMBReactPlayer.propTypes = {
  image: PropTypes.string,
  file: PropTypes.string,
  videoTitle: PropTypes.object,
  onPlay: PropTypes.func,
  onEnded: PropTypes.func,
  onStart: PropTypes.func,
  onError: PropTypes.func,
  onPause: PropTypes.func,
  onSeek: PropTypes.func,
  onReady: PropTypes.func,
  isAutoPlay: PropTypes.bool,
  onProgress: PropTypes.func,
  videoImage: PropTypes.string,
};
