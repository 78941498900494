import { connect } from 'react-redux';
import SessionInner from './SessionInner';
import {
  loadSessionById,
  changeSection,
  showVideo,
  showSpeaker,
  loadSessions,
} from '@actions';

const mapStateToProps = (state) => {
  return {
    event: state.api.event.data,
    loggedIn: !!state.api.user.data.id,
    innerSession: state.api.innerSession.data || {},
    ready: state.api.sessions.data.length ? true : false,
    sessionId: state.api.sessions.showingSpeeches.sessionId,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSection: (section) => {
      dispatch(changeSection(section));
    },
    showVideo: (session, speech) => {
      dispatch(showVideo(session, speech));
    },
    showSpeaker: (id) => dispatch(showSpeaker(id)),
    loadSessions: () => dispatch(loadSessions()),
    loadSessionById: (sessionId) => dispatch(loadSessionById(sessionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionInner);
