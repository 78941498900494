import colorConverter from '../helpers/colorConverter';

/*
const hueSteps = {
  25: 0.56,
  50: 0.48,
  100: 0.4,
  200: 0.32,
  300: 0.24,
  400: 0.16,
  500: 0.08,
  600: 0,
  700: -0.08,
  800: -0.16,
  900: -0.24
};
*/

const hslToHex = (h, s, l) => {
  const a = s * Math.min(l, 1 - l);
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const convertPrimary = (step, color) => {
  let finalLightnessChange = 0;
  const colorObj = colorConverter(color)(color);

  const arrayColorBright = [500, 400, 300, 200, 100, 50, 25];
  const stepper1 = (1 - colorObj.lightness) / (arrayColorBright.length + 1);

  const arrayColorDark = [700, 800, 900];
  const stepper2 = colorObj.lightness / (arrayColorDark.length + 1);

  const minStep = stepper1 < stepper2 ? stepper1 : stepper2;

  if (step < 600)
    finalLightnessChange = (arrayColorBright.indexOf(step) + 1) * minStep;
  else if (step > 600)
    finalLightnessChange = -1 * (arrayColorDark.indexOf(step) + 1) * minStep;

  return hslToHex(
    colorObj.hue,
    colorObj.sat,
    colorObj.lightness + finalLightnessChange
  );
};

export const setPrimaryColor = (newColor) => {
  document.documentElement.style.setProperty(
    '--primary-25',
    ((color) => convertPrimary(25, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-50',
    ((color) => convertPrimary(50, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-100',
    ((color) => convertPrimary(100, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-200',
    ((color) => convertPrimary(200, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-300',
    ((color) => convertPrimary(300, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-400',
    ((color) => convertPrimary(400, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-500',
    ((color) => convertPrimary(500, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-600',
    ((color) => convertPrimary(600, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-700',
    ((color) => convertPrimary(700, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-800',
    ((color) => convertPrimary(800, color))(newColor)
  );
  document.documentElement.style.setProperty(
    '--primary-900',
    ((color) => convertPrimary(900, color))(newColor)
  );

  return {
    primary: {
      25: ((color) => convertPrimary(25, color))(newColor),
      50: ((color) => convertPrimary(50, color))(newColor),
      100: ((color) => convertPrimary(100, color))(newColor),
      200: ((color) => convertPrimary(200, color))(newColor),
      300: ((color) => convertPrimary(300, color))(newColor),
      400: ((color) => convertPrimary(400, color))(newColor),
      500: ((color) => convertPrimary(500, color))(newColor),
      600: ((color) => convertPrimary(600, color))(newColor),
      700: ((color) => convertPrimary(700, color))(newColor),
      800: ((color) => convertPrimary(800, color))(newColor),
      900: ((color) => convertPrimary(900, color))(newColor),
    },
  };
};
