import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
// import { colorPalette } from "@config/colorPalette";

export default function RadioButtonsGroup(props) {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <FormControl component="fieldset" disabled={props.disabled}>
      <RadioGroup
        disabled={props.disabled}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
        }}
        aria-label={props.name}
        name={props.name}
        value={props.checked}
        onChange={handleChange}>
        {Object.keys(props.options).map((key) => {
          return (
            <FormControlLabel
              key={`${props.name}-${key}`}
              value={key}
              style={{ flex: 1 }}
              className="radio-coloring"
              control={
                <Radio
                  style={{
                    color:
                      props.checked === key
                        ? window.colorPalette.primary[600]
                        : '',
                  }}
                />
              }
              label={props.options[key]}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

RadioButtonsGroup.propTypes = {
  options: PropTypes.object.isRequired,
  checked: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  parentClassName: PropTypes.string,
};

RadioButtonsGroup.defaultProps = {
  checked: 'no',
  options: {
    yes: 'Yes',
    no: 'No',
  },
  onChange: () => {},
  disabled: false,
  id: 'radio-input',
  className: '',
  parentClassName: '',
};
