import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPersons } from '@actions';
import { isValidEmail, isEmpty } from '@helpers';
import Select from '@components/layout/Select2';

class Authors extends React.Component {
  constructor(props) {
    super(props);
    ['getJSX', 'remove', 'initPerson', 'onChange', 'setInitialValue'].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.state = {
      instances: props.value.length === 0 ? 1 : props.value.length,
      value: !isEmpty(props.value)
        ? this.setInitialValue([...props.value])
        : [this.initPerson()],
    };
  }

  setInitialValue(value) {
    return value.map((v) => this.initPerson(v));
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const newInstitutesId = nextProps.institutes
      .filter((v) => v.name?.length > 0)
      .map((v) => String(v.index));
    const { value } = this.state;
    let needsUpdate = false;
    for (const author of value) {
      for (const i in author.institutes) {
        if (newInstitutesId.indexOf(author.institutes[i]) < 0) {
          author.institutes.splice(i, 1);
          needsUpdate = true;
        }
      }
    }
    if (needsUpdate) {
      this.setState({ value });
    }
  }
  initPerson(data = false) {
    return {
      firstName: data ? data.firstName : '',
      lastName: data ? data.lastName : '',
      email: {
        value: data.email?.hasOwnProperty('value')
          ? data.email.value
          : data.email || '',
        invalid: false,
      },
      institutes: data
        ? data.institutes.map((v) => {
            return String(v);
          })
        : [],
      id: data ? data.id : false,
      eventAuthorId: data.eventAuthorId || undefined,
    };
  }

  onChange(e, i) {
    const { name, value } = e.target;
    this.setState(
      {
        value: [...this.state.value].map((val, index) => {
          if (i === index) {
            if (name === 'email') {
              return Object.assign({}, val, {
                [name]: Object.assign({}, val[name], {
                  value,
                  invalid: !isValidEmail(value) && value.length > 0,
                }),
              });
            } else if (name === 'institutes') {
              return Object.assign({}, val, {
                [name]: value.length === 0 ? [] : value,
              });
            } else {
              return Object.assign({}, val, {
                [name]: value,
              });
            }
          }
          return val;
        }),
      },
      () => this.props.onChange(this.state.value)
    );
  }

  remove(i) {
    if (this.props.disabled) return;

    this.setState(
      {
        instances: this.state.instances - 1,
        value: [...this.state.value].filter((value, index) => index !== i),
      },
      () => this.props.onChange(this.state.value)
    );
  }

  getJSX() {
    const content = [];
    for (let i = 0; i < this.state.instances; i++) {
      const currentAuthor = this.state.value[i];
      const errorFirstName =
        this.props.hasError && isEmpty(currentAuthor.firstName);
      const errorLastName =
        this.props.hasError && isEmpty(currentAuthor.lastName);

      const instituteOptions = {};
      this.props.institutes
        .filter((v) => v.name?.length > 0)
        .sort((a, b) => a.index - b.index)
        .map((v) => (instituteOptions[v.index] = v.name));
      content.push(
        <div className="author-abstract" key={`instance-${i}`}>
          {i === 0 && (
            <p className="author-abstract-main">
              {window.lang[this.props.language]['mainAuthor']}
            </p>
          )}
          <div className="author-inner">
            <div className={`${errorFirstName ? ' has-error' : ''}`}>
              <label className="author-label" htmlFor="">
                {window.lang[this.props.language]['firstName']} (*)
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                placeholder={window.lang[this.props.language]['firstName']}
                value={this.state.value[i].firstName}
                onChange={(e) => this.onChange(e, i)}
                disabled={this.props.disabled}
              />
            </div>

            <div className={`${errorLastName ? ' has-error' : ''}`}>
              <label className="author-label" htmlFor="">
                {window.lang[this.props.language]['lastName']} (*)
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={window.lang[this.props.language]['lastName']}
                name="lastName"
                disabled={this.props.disabled}
                value={this.state.value[i].lastName}
                onChange={(e) => this.onChange(e, i)}
              />
            </div>
            <div
              className={`${
                this.state.value[i]['email'].invalid ? ' has-error' : ''
              }`}>
              <label className="author-label" htmlFor="">
                {window.lang[this.props.language]['email']}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={window.lang[this.props.language]['email']}
                name="email"
                value={this.state.value[i].email.value}
                onChange={(e) => this.onChange(e, i)}
                disabled={this.props.disabled}
              />
              {this.state.value[i]['email'].invalid && (
                <p className="field-has-error">
                  {window.lang[this.props.language]['invalidEmail']}
                </p>
              )}
            </div>
            <div style={{ flexBasis: '100%' }}>
              <label className="author-label" htmlFor="">
                {window.lang[this.props.language]['institute']}
              </label>
              <Select
                name="institutes"
                multi={true}
                onChange={(e) => {
                  this.onChange(
                    { target: { name: 'institutes', value: e } },
                    i
                  );
                }}
                value={this.state.value[i].institutes}
                disabled={this.props.disabled}
                options={instituteOptions}
              />
            </div>
            {i != 0 && this.props.disabled === false && (
              <div className="icon-close-wrapper">
                <button
                  type="button"
                  className="btn close"
                  onClick={() => this.remove(i)}>
                  <i className="icon-close" />
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }
    return content;
  }

  render() {
    return (
      <div className="form-group form-author">
        <label className="field-label" htmlFor="institutes">
          {window.lang[this.props.language]['authors']}
        </label>
        {this.getJSX()}
        {!this.props.disabled && (
          <div className="actions">
            <button
              className="btn author-btn btn-reversed"
              type="button"
              onClick={() =>
                this.setState({
                  instances: this.state.instances + 1,
                  value: [...this.state.value, ...[this.initPerson()]],
                })
              }>
              <i className="icon-add" aria-hidden="true" />
              {window.lang[this.props.language]['addAuthors']}
            </button>
          </div>
        )}
      </div>
    );
  }
}

Authors.propTypes = {
  eventId: PropTypes.number.isRequired,
  policyId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  getPersons: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  language: PropTypes.string,
  institutes: PropTypes.array,
};

Authors.defaultProps = {
  value: [],
  onChange: () => {},
  hasError: false,
  disabled: false,
};

export default connect(
  (state) => ({
    eventId: state.api.event.data.id,
    policyId: state.api.policy.data.id,
    orgId: state.api.event.data.orgId,
    language: state.api.language.selected,
  }),
  { getPersons }
)(Authors);
