import { combineReducers } from 'redux';
import api from './api';
import menu from './menu';
import notifications from './notifications';

const rootReducer = combineReducers({
  api,
  menu,
  notifications,
});

export default rootReducer;
