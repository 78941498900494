import React from 'react';
import PropTypes from 'prop-types';
import KMBReactPlayer from '@components/layout/KMBReactPlayer';

const Videos = (props) => {
  const { event } = props;
  let url = null;
  if (event.clientPanelSettings.home.homePromoVideoUrl) {
    url = event.clientPanelSettings.home.homePromoVideoUrl;
  }

  return (
    <div className="row section">
      <div className="video-holder">
        <KMBReactPlayer file={url} isAutoPlay={false} />
      </div>
    </div>
  );
};
Videos.propTypes = {
  event: PropTypes.object.isRequired,
};

export default Videos;
