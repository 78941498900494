import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '@components/layout/Modal';

const PasswordResetModal = (props) => {
  return (
    <Modal
      modalIsOpen={props.modalIsOpen}
      closeModal={() => props.closeModal()}
      inner={[
        <div key="main-1">
          <p key="main-2">{lang[props.language]['passwordReset']}</p>
          <div style={{ textAlign: 'right' }} key="main-3">
            <button
              key="main-4"
              style={{ margin: '5px' }}
              className="btn btn-cancel"
              onClick={props.closeModal}>
              Cancel
            </button>
            <button
              key="main-5"
              style={{ margin: '5px' }}
              className="btn btn-primary"
              onClick={() => {
                props.closeModal();
                props.requestResetPassword({ value: props.user.info.email });
              }}>
              OK
            </button>
          </div>
        </div>,
      ]}
    />
  );
};

PasswordResetModal.propTypes = {
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  requestResetPassword: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetModal);
