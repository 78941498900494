import { connect } from 'react-redux';
import Splash from './Splash';
import { changeScreen, togglePasswordVisibility, getEventInfo } from '@actions';

const mapStateToProps = (state) => {
  return {
    policy: state.api.policy.data,
    ready: state.api.policy.ready,
    event: state.api.event.data,
    user: state.api.user.data,
    visibility: state.api.policy.visibility,
    screen: state.api.policy.screen,
    resetPasswordAvailable: state.api.policy.resetPasswordAvailable,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeScreen: (screen) => {
      dispatch(changeScreen(screen));
    },
    togglePasswordVisibility: (key) => {
      dispatch(togglePasswordVisibility(key));
    },
    getEventInfo: (eventId) => {
      dispatch(getEventInfo(eventId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
