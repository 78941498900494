import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ExhibitorCard from './ExhibitorCard';
import KMBLoader from '@components/layout/KMBLoader';
import Select from '@components/layout/Select';

import { getExhibitorById, getExhibitors, showExhibitor } from '@actions';

class Exhibition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      premiumExhibitors: [],
      normalExhibitors: [],
      search: '',
      categoryValue: '',
      categories: {},
    };
    this.createExhibitorsLayout = this.createExhibitorsLayout.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.createCategoriesMap();
    this.props.getExhibitors().then(() => this.createExhibitorsLayout());
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  createCategoriesMap() {
    const categoriesObject = {};
    this.props.categories.forEach(
      (category) => (categoriesObject[category.id] = category.name)
    );
    this.setState({ categories: categoriesObject });
  }

  createExhibitorsLayout() {
    const entries = {
      premium: (
        <div className="col-sm-3">
          <div className="exhibitor-card empty"></div>
        </div>
      ),
      normal: (
        <div className="col-sm-2">
          <div className="exhibitor-card empty"></div>
        </div>
      ),
    };

    const exhibitorTypes = {
      premium: this.props.exhibitors.filter(
        (exhibitor) => exhibitor.type == 'premium'
      ),
      normal: this.props.exhibitors.filter(
        (exhibitor) => exhibitor.type == 'normal'
      ),
    };

    const exhibitorTypesNames = ['premium', 'normal'];
    const exhibitorsArray = { premium: [], normal: [] };
    const hasActiveExhibitors = { premium: false, normal: false };

    for (const type of exhibitorTypesNames) {
      let lastSlotFilled = 0;
      exhibitorTypes[type]
        .sort((a, b) => (a.slot > b.slot ? 1 : -1))
        .forEach((exhibitor) => {
          const gap = exhibitor.slot - lastSlotFilled;
          if (gap > 0) {
            exhibitorsArray[type] = exhibitorsArray[type].concat(
              Array(gap).fill(entries[type])
            );
          }
          lastSlotFilled = exhibitor.slot + exhibitor.size;

          if (exhibitor.active) {
            hasActiveExhibitors[type] = true;
            exhibitorsArray[type].push(
              <ExhibitorCard
                key={exhibitor.id}
                size={
                  type === 'premium' ? exhibitor.size * 3 : exhibitor.size * 2
                }
                heightMultiplier={exhibitor.rowHeight || 1}
                exhibitorId={exhibitor.id}
                showExhibitor={this.props.showExhibitor}
                exhibitorThumbnail={exhibitor?.exhibitorThumbnail[0]?.url || ''}
                isPremium={type === 'premium' ? true : false}
              />
            );
          } else {
            exhibitorsArray[type].push(entries[type]);
          }
        });
    }

    this.setState({
      premiumExhibitors: hasActiveExhibitors['premium']
        ? exhibitorsArray['premium']
        : [],
      normalExhibitors: hasActiveExhibitors['normal']
        ? exhibitorsArray['normal']
        : [],
    });
  }
  changeCategory(val) {
    this.setState({ categoryValue: val }, () =>
      this.props
        .getExhibitors({ categoriesId: val })
        .then(() => this.createExhibitorsLayout(true))
    );
  }
  render() {
    const { exhibitorsReady } = this.props;

    return (
      <React.Fragment>
        <div className="filter-container">
          <div className="search-bar">
            <input
              className="search-input"
              placeholder={window.lang[this.props.language]['search']}
              value={this.state.search}
              onChange={(e) => {
                clearTimeout(this.timeout);
                this.setState(
                  { search: e.target.value },
                  () =>
                    (this.timeout = setTimeout(
                      () =>
                        this.props
                          .getExhibitors({ search: e.target.value })
                          .then(() => this.createExhibitorsLayout(true)),
                      500
                    ))
                );
              }}
            />
          </div>
          <Select
            placeholder={window.lang[this.props.language]['categories']}
            className="panel-select"
            value={this.state.categoryValue}
            onChange={(val) => {
              this.changeCategory(val);
            }}
            options={this.state.categories}
          />
        </div>
        <div className="exhibitor-container">
          {exhibitorsReady ? (
            <React.Fragment>
              <div className="row premium">{this.state.premiumExhibitors}</div>
              <div className="row normal">{this.state.normalExhibitors}</div>
            </React.Fragment>
          ) : (
            <KMBLoader rows={20} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

Exhibition.propTypes = {
  language: PropTypes.any,
  event: PropTypes.object,
  showExhibitor: PropTypes.func.isRequired,
  getExhibitors: PropTypes.func.isRequired,
  exhibitors: PropTypes.array.isRequired,
  exhibitorsReady: PropTypes.bool.isRequired,
  categories: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    exhibitors: state.api.exhibition.data,
    exhibitorsReady: state.api.exhibition.ready,
    language: state.api.language.selected,
    color: state.api.event.data.clientPanelSettings.general.colors.primary,
    categories: state.api.event.data.clientPanelSettings.exhibition.categories,
  };
};
const mapDispatchToProps = (dispatch) => ({
  showExhibitor: (id) => dispatch(showExhibitor(id)),
  getExhibitors: (meta) => dispatch(getExhibitors(meta)),
  getExhibitorById: (exhibitorId) => dispatch(getExhibitorById(exhibitorId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Exhibition);
