import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PDFIcon from '@dynamic_assets/pdfIcon.svg.js';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded';
import { showAbstractPreview } from '@actions';

const AbstractHeader = (props) => {
  const publishedAbstract =
    props.abstract?.publishedAbstractFile.length > 0
      ? props.abstract.publishedAbstractFile[0]
      : null;

  const getAbstractJoinedTopics = () => {
    return props.abstract?.topics.map((topic) => topic.name).join(', ');
  };

  const returnFileIcon = () => {
    switch (publishedAbstract.extension) {
      case 'pdf':
        return <PDFIcon />;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <ImageOutlinedIcon />;
      case 'mp4':
        return <OndemandVideoIcon />;
      default:
        return <FolderOpenRoundedIcon />;
    }
  };

  return (
    <React.Fragment>
      <div className="abstract-types">
        {props.abstract?.type ?? (
          <div className="abstract-type">{props.abstract?.type}</div>
        )}
        {publishedAbstract && (
          <div className="abstract-file-icon">{returnFileIcon()}</div>
        )}
        <div className="abstract-pub-id">{props.abstract?.publishedId}</div>
      </div>

      <div
        className="abstract-title"
        onClick={() => props.showAbstractPreview(props.abstract.id)}>
        {props.abstract?.name}
      </div>
      <div className="abstract-topics">{getAbstractJoinedTopics()}</div>
    </React.Fragment>
  );
};

AbstractHeader.propTypes = {
  language: PropTypes.string,
  abstract: PropTypes.object,
  showAbstractPreview: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showAbstractPreview: (abstractID) =>
    dispatch(showAbstractPreview(abstractID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbstractHeader);
