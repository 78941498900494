import React from 'react';
import PropTypes from 'prop-types';

const Iframe = (props) => {
  const { content, customHeight } = props;

  return (
    <iframe
      src={content}
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      style={{
        margin: 15,
        height: customHeight,
        width: 'calc(100% - 30px)',
        borderRadius: 4,
      }}
    />
  );
};
Iframe.propTypes = {
  content: PropTypes.object.isRequired,
  customHeight: PropTypes.number.isRequired,
};

export default Iframe;
