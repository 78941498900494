import React from 'react';
import Map from '@components/layout/Map';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import KMBLoader from '@components/layout/KMBLoader';
import Widget from './Partials/Widget';
import HotelCard from './Partials/HotelCard';
import { animateScroll as scroll } from 'react-scroll';
import WarningBar from './HotelInner/WarningBar/WarningBar';

export default class HotelManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      guests: [1],
      stars: [],
      locations: [],
      ready: false,
      zoomLocation: null,
      clickedHotel: null,
    };

    [
      'locateHotel',
      'gatherLocations',
      'resetted',
      'buildInfoWindow',
      'handleBodyClick',
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));
  }

  handleBodyClick(e) {
    if (e.target && e.target.id === 'gmbutton') {
      if (e.target.dataset.id) {
        e.preventDefault();
        this.props.selectHotel(parseInt(e.target.dataset.id));
      }
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  UNSAFE_componentWillMount() {
    this.props
      .getHotels(Object.keys(this.props.user.accomodation).length > 0)
      .then((hotels) => {
        this.gatherLocations(hotels);
        this.setFilters(hotels.meta.filters);
      });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.addEventListener('click', this.handleBodyClick);
  }

  cheaperRoom(h) {
    let cheapestPrice = 100000;

    if (h.rooms.length > 0) {
      for (const room in h.rooms) {
        if (Number(h.rooms[room].sellingPrice) < cheapestPrice)
          cheapestPrice = h.rooms[room].sellingPrice;
      }
    } else {
      return 'N/A';
    }

    return cheapestPrice;
  }

  gatherLocations(hotels) {
    const locations = [...hotels.data].map((h) => {
      return {
        id: h.id,
        lat: h.latitude,
        lng: h.longitude,
        name: h.name,
        stars: h.stars,
        cheapestRoom: this.cheaperRoom(h),
      };
    });
    this.setState({ locations, ready: true });
  }

  setFilters(filters) {
    this.setState({ stars: filters.stars, guests: filters.guests });
  }

  locateHotel(clickedHotel, lng, lat) {
    scroll.scrollToTop();

    this.setState({
      zoomLocation: { lng, lat },
      clickedHotel,
    });
  }

  resetted() {
    this.setState({
      zoomLocation: null,
      clickedHotel: null,
    });
  }

  buildInfoWindow({ id, stars, cheapestRoom }) {
    const html = (
      <div className="hotel-info-window" id="gmbutton">
        <div className="price-wrapper-info">
          <div className="from-wrapper-info">
            {window.lang[this.props.language]['from']}
          </div>
          <div className="currency-wrapper-info">
            {cheapestRoom}
            {this.props.currency}
          </div>
        </div>
        <div className="kmb-stars">{this.buildStarsHtml(id, stars)}</div>
      </div>
    );
    const stringHTML = ReactDOMServer.renderToStaticMarkup(html);
    return stringHTML;
  }

  buildStarsHtml(hotelId, stars) {
    const html = [];
    for (let i = 1; i <= stars; i++) {
      html.push(
        <i
          key={`star-${hotelId}-${i}`}
          className={`icon-fill-1 ${i <= stars ? ' active' : '-o'}`}
          aria-hidden="true"
        />
      );
    }
    return html;
  }

  render() {
    const { ready, locations, zoomLocation, clickedHotel } = this.state;
    const { hotels, selectHotel, language, currency } = this.props;
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;
    if (!ready) {
      return <KMBLoader rows={20} />;
    }

    return (
      <React.Fragment>
        <div className="accomodation-container">
          <WarningBar
            user={this.props.user}
            changeSection={this.props.changeSection}
            language={this.props.language}
          />
          <div className="hotel-management-wrapper">
            <Widget
              event={this.props.event}
              language={language}
              guests={this.state.guests}
              stars={this.state.stars}
              getHotels={this.props.getHotels}
              userHasAccommodation={
                Object.keys(this.props.user.accomodation).length > 0
              }
            />
            <div className="right-side-wrapper">
              {ready && (
                <div className="hotels-list">
                  <Map
                    showLocationMarker={false}
                    lang={window.lang[this.props.language]}
                    markers={locations}
                    zoomLocation={zoomLocation}
                    onClickMarker={this.locateHotel}
                    resetted={this.resetted}
                    buildInfoWindow={this.buildInfoWindow}
                    hasInfoWindow={true}
                    primaryColor={primaryColor}
                  />
                  <div className="hotels-wrapper">
                    {Object.keys(this.props.user.accomodation).length === 0 &&
                      hotels.map((hotel) => {
                        return (
                          <HotelCard
                            key={hotel.id}
                            language={language}
                            hotel={hotel}
                            locateHotel={this.locateHotel}
                            selectHotel={selectHotel}
                            clickedHotel={clickedHotel}
                            buildStarsHtml={this.buildStarsHtml}
                            currency={currency}
                            primaryColor={primaryColor}
                            hasReservation={false}
                          />
                        );
                      })}
                    {Object.keys(this.props.user.accomodation).length > 0 &&
                      hotels
                        .filter(
                          (hotel) =>
                            hotel.id ===
                            this.props.user.accomodation.eventHotelId
                        )
                        .map((hotel) => {
                          return (
                            <HotelCard
                              key={hotel.id}
                              language={language}
                              hotel={hotel}
                              locateHotel={this.locateHotel}
                              selectHotel={selectHotel}
                              buildStarsHtml={this.buildStarsHtml}
                              clickedHotel={clickedHotel}
                              currency={currency}
                              primaryColor={primaryColor}
                              hasReservation={true}
                            />
                          );
                        })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

HotelManagement.propTypes = {
  getHotels: PropTypes.func.isRequired,
  hotels: PropTypes.array.isRequired,
  selectHotel: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  changeSection: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  showPayment: PropTypes.func,
  cancelHotelReservation: PropTypes.func,
  currency: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
};
