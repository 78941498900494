import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

const Notifications = (props) => {
  return (
    <div className="notifications">
      {props.notifications.map((n) => {
        return (
          <div
            className={`notification-item alert alert-${n.className}`}
            key={n.index}>
            {n.message}
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
  };
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, undefined)(Notifications);
