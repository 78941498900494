import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { setCaptchaCheck } from '@actions';
import configuration from '@config/conf';
import { connect } from 'react-redux';

const Recaptcha = (props) => {
  const onChange = (value) => {
    props.setCaptchaToken(value, props.componentToVerify);
  };

  return (
    <div className={`recaptcha-wrapper ${props.componentToVerify}`}>
      <ReCAPTCHA
        className="recaptcha-component"
        sitekey={configuration.recaptchaKey}
        onChange={onChange}
        hl={props.language === 'gr' ? 'el' : props.language}
      />
    </div>
  );
};

Recaptcha.propTypes = {
  componentToVerify: PropTypes.string,
  language: PropTypes.string,
  setCaptchaToken: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCaptchaToken: (token, componentToVerify) =>
    dispatch(setCaptchaCheck(token, componentToVerify)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recaptcha);
