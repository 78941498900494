import { connect } from 'react-redux';
import Payments from './Payments';
import {
  showPayment,
  changeSection,
  getSubscriptions,
  validateToken,
  getReceipt,
} from '@actions';

const mapStateToProps = (state) => {
  return {
    user: state.api.user.data,
    currency: state.api.event.data.currencySymbol,
    billing: state.api.event.data.settings.billing,
    supportEmail: state.api.event.data.supportEmail,
    event: state.api.event.data,
    counter: state.api.counter,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showPayment: (products) => dispatch(showPayment(products)),
  goToBilling: () =>
    window.location.replace(window.location.origin + window.location.pathname),
  changeSection: (section) => dispatch(changeSection(section)),
  validateToken: (token) => dispatch(validateToken(token)),
  getSubscriptions: (eventId, policyId) =>
    dispatch(getSubscriptions(eventId, policyId)),
  getReceipt: (eventId, id, paymentRef, token) =>
    dispatch(getReceipt(eventId, id, paymentRef, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
