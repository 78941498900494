import React from 'react';
import PropTypes from 'prop-types';
import KMBLoader from '@components/layout/KMBLoader';

export default class Table extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      id,
      columns,
      items,
      onRender,
      containerClassName,
      tableClassName,
      updating,
      onRenderTh,
      onRenderColSpan,
      onTrClassName,
    } = this.props;

    return (
      <div
        className={`table-container${
          containerClassName ? ` ${containerClassName}` : ''
        }`}>
        <table className={`table ${tableClassName}`}>
          {Object.keys(columns).length && (
            <thead>
              <tr>
                {Object.keys(columns).map((key, thIndex) => {
                  const className = `${key}${
                    columns[key].hasOwnProperty('activeSort')
                      ? ' is-active'
                      : ''
                  }`;
                  let th = <div>{columns[key].name}</div>;

                  if (columns[key].sortable) {
                    th = (
                      <div
                        onClick={() => this.props.onSort(key)}
                        className="sortable-th">
                        {columns[key].name}
                        <span
                          className={
                            key === this.props.sortKey
                              ? 'icon-arrow-up'
                              : 'icon-arrow-down'
                          }
                        />
                      </div>
                    );
                  }

                  if (onRenderTh) {
                    const jsx = onRenderTh(
                      { key, thIndex, name: columns[key].name },
                      className
                    );
                    if (jsx !== undefined) {
                      // we have an override
                      th = jsx;
                    }
                  }

                  return (
                    <th
                      key={`${id}-th-${thIndex}`}
                      className={`kmb-${className}`}>
                      {th}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {!updating &&
              items.map((item, trIndex) => {
                const content = [];
                const hasColSpan =
                  item.hasOwnProperty('colSpan') && item.colSpan ? true : false;

                if (hasColSpan && onRenderColSpan) {
                  const jsx = onRenderColSpan(item, trIndex);
                  if (jsx !== undefined) {
                    content.push(jsx);
                  }
                } else {
                  Object.keys(columns).map((key, tdIndex) => {
                    let value = item.hasOwnProperty(key) ? item[key] : '';

                    if (onRender) {
                      const jsx = onRender({ key, value }, item, trIndex);
                      if (jsx !== undefined) {
                        // we have an override
                        value = jsx;
                      }
                    }
                    content.push(
                      <td
                        key={`${id}-${trIndex}-${tdIndex}`}
                        className={`kmb-${key}`}>
                        {value}
                      </td>
                    );
                  });
                }

                let trClassName = hasColSpan ? 'has-colspan' : '';
                if (onTrClassName) {
                  const t = onTrClassName(trClassName, item);
                  if (t !== undefined) trClassName = t;
                }

                return (
                  <tr
                    onClick={() => this.props.onTrClick(item)}
                    key={`${id}-${trIndex}`}
                    className={trClassName + 'positioner'}>
                    {content}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {updating && <KMBLoader rows={30} thin={false} />}
      </div>
    );
  }
}

Table.defaultProps = {
  id: '',
  items: [],
  columns: {},
  containerClassName: '',
  tableClassName: '',
  onSort: () => {},
  updating: false,
  onTrClick: () => {},
};

Table.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  columns: PropTypes.object,
  onRender: PropTypes.func,
  onRenderTh: PropTypes.func,
  onSort: PropTypes.func,
  containerClassName: PropTypes.string,
  tableClassName: PropTypes.string,
  updating: PropTypes.bool,
  onRenderColSpan: PropTypes.func,
  onTrClick: PropTypes.func,
  onTrClassName: PropTypes.func,
  sortKey: PropTypes.string,
};
