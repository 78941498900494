import React from 'react';
import PropTypes from 'prop-types';

const Image = (props) => {
  const { content, customHeight, pos, fullRow, setSiteToNavigateTo } = props;

  const Img = () => (
    <a onClick={() => setSiteToNavigateTo(content.actionUrl)}>
      <img
        src={content.url}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: 4,
          cursor: 'pointer',
        }}
        alt={`content-image`}
      />
    </a>
  );

  const style = (shouldFloat) => ({
    width: 'auto',
    maxHeight: customHeight,
    height: customHeight,
    display: 'inline-block',
    margin: 15,
    float: shouldFloat ? pos : 'none',
  });

  if (fullRow) {
    return (
      <center>
        <div style={style(false)}>
          <Img />
        </div>
      </center>
    );
  } else {
    return (
      <div style={style(true)}>
        <Img />
      </div>
    );
  }
};
Image.propTypes = {
  content: PropTypes.object.isRequired,
  customHeight: PropTypes.number,
  pos: PropTypes.string,
  fullRow: PropTypes.bool,
  setSiteToNavigateTo: PropTypes.func,
};

export default Image;
