import { connect } from 'react-redux';
import Agenda from './Agenda';
import { loadSessions } from '@actions';

const mapStateToProps = (state, ownProps) => {
  const agenda = state.api.user.data.info.hasOwnProperty('favouriteSessions')
    ? state.api.user.data.info.favouriteSessions.split(',').filter((s) => s)
    : [];
  return {
    event: state.api.event.data,
    ready: state.api.event.ready,
    sessions: state.api.sessions,
    mode: ownProps.mode,
    user: state.api.user.data,
    agenda,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSessions: (meta) => {
      dispatch(loadSessions(meta));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
