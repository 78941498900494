import React from 'react';
import { ShoppingCartIcon } from '../../../../../../dynamic_assets/shopingCartIcon.svg';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CartIcon } from '../../../../../../dynamic_assets/cartIcon.svg';

export default function CartMobileComponent({
  language,
  currency,
  handleProceedToPurchase,
  cart,
  totalItemsInCart,
  totalAmount,
}) {
  const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="cartmobile-container">
      <div className="mobile-cart">
        {
          <Accordion expanded={isAccordionOpen} onChange={toggleAccordion}>
            <AccordionSummary className="custom-accordion-summary">
              <div className="mobile-cart-title">
                <div className="mobile-cart-info">
                  <ShoppingCartIcon />
                  <h4>
                    {window.lang[language]['myCart']}
                    {totalItemsInCart > 0 ? ` (${totalItemsInCart})` : ''}
                  </h4>
                  <ExpandMoreIcon
                    style={{
                      transform: isAccordionOpen ? 'rotateX(180deg)' : 'unset',
                    }}
                  />
                </div>
                {cart.length > 0 && (
                  <button
                    className="mobile-round-button"
                    onClick={handleProceedToPurchase(cart)}>
                    <div className="mobile-button-content">
                      <span>{window.lang[language]['proceedToPurchase']}</span>
                      {totalAmount.toFixed(2)} {currency}
                    </div>
                  </button>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className="custom-accordion-details">
              <div className="mobile-cart-body">
                {cart?.map?.((item, index) => (
                  <div className="mobilecart-item" key={`${item.id}-${index}`}>
                    <div className="mobilecart-item-details">
                      <div>{item.quantity}x</div>
                      <div>{` ${item.eventProductName} - ${item.name} `}</div>
                    </div>
                    <div className="mobilecart-item-price">
                      {isFinite(item.sellingPrice)
                        ? item.sellingPrice.toFixed(2)
                        : 'N/A'}
                      {currency}
                    </div>
                  </div>
                ))}
                {cart?.length === 0 && (
                  <div className="empty-cart-icon">
                    <CartIcon />
                    <p className="primary-message">
                      Your shopping cart is empty!
                    </p>
                    <p className="secondary-message">Start adding products</p>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        }
      </div>
    </div>
  );
}

CartMobileComponent.propTypes = {
  language: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  handleProceedToPurchase: PropTypes.func.isRequired,
  cart: PropTypes.array.isRequired,
  clearCart: PropTypes.func.isRequired,
  totalItemsInCart: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
};
