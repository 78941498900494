import React from 'react';
import PropTypes from 'prop-types';
import Table from '@components/layout/Table';
import Paginator from '@components/layout/Paginator';
import { AccountIconGray25 } from '@dynamic_assets/accounticon.svg';
export default class Speakers extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);

    this.columns = {
      name: {
        name: '',
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillMount() {
    this.props.loadSpeakers({ p: 1, rpp: 10 });
  }

  onRender(col, item) {
    switch (col.key) {
      case 'name': {
        const hasImage = item.speakerThumbnail[0];
        return (
          <div className="speaker-item">
            <div>
              <div>
                <span className="person-thumbnail">
                  {hasImage ? (
                    <img
                      style={{
                        position: 'relative',
                        objectFit: 'cover',
                        width: '70px',
                        height: '70px',
                        padding: '3px',
                      }}
                      src={item.speakerThumbnail[0].url}
                      alt={`${item.firstName} ${item.lastName}`}
                    />
                  ) : (
                    <AccountIconGray25 />
                  )}
                </span>
              </div>
              <div>
                {`${item.firstName} ${item.lastName}`}
                {item.jobTitle || item.institution ? (
                  <p
                    style={{
                      lineHeight: '20px',
                      fontWeight: 500,
                      fontSize: 14,
                    }}>{`${item.jobTitle} - ${item.institution}`}</p>
                ) : null}
              </div>
            </div>
            <div>
              <p>
                {item.shortBio?.substring(0, 200)}
                {item.shortBio?.length > 200 ? '...' : ''}
              </p>
            </div>
          </div>
        );
      }
    }
  }

  render() {
    return (
      <div className="speaker-container">
        <div className="paginator-container sticky">
          <Paginator
            items={this.props.speakers}
            meta={this.props.meta}
            language={this.props.language}
            onChange={(meta) =>
              this.props.loadSpeakers(Object.assign({}, meta))
            }
            fetching={this.props.fetching}
          />
        </div>
        {this.props.event.publishSchedule === 1 &&
        this.props.speakers.length !== 0 ? (
          <div style={{ width: '100%' }}>
            <div className="speaker-table">
              <Table
                id={'list-speakers'}
                columns={this.columns}
                items={this.props.speakers}
                onRender={this.onRender}
                onTrClick={(s) => this.props.showSpeaker(s.id)}
                onSort={(orderBy) => {
                  let order = this.props.meta.order;
                  if (orderBy === this.props.meta.orderBy) {
                    order = order === 'ASC' ? 'DESC' : 'ASC';
                  }
                  this.props.loadSpeakers({ order, orderBy });
                }}
              />
            </div>

            <div className="mobile-table">
              {this.props.speakers.map((speaker) => {
                const hasImage = speaker.speakerThumbnail[0];
                return (
                  <div className="profile-mobile" key={speaker.id}>
                    <div
                      className={`person-thumbnail${
                        speaker.personThumbnail ? '' : ' empty'
                      }`}>
                      <span className="person-thumbnail">
                        {hasImage ? (
                          <img
                            src={speaker.speakerThumbnail[0].url}
                            alt={`${speaker.firstName} ${speaker.lastName}`}
                          />
                        ) : (
                          <AccountIconGray25 />
                        )}
                      </span>
                    </div>
                    <h2 className="speaker-name">
                      {`${speaker.firstName} ${speaker.lastName}`}
                    </h2>
                    <div className="resume">{speaker.shortBio}</div>
                    <button
                      className="button"
                      onClick={() => this.props.showSpeaker(speaker.id)}>
                      FIND OUT MORE
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="empty-results">
            <span className="icon-warning-2" />
            <p>{window.lang[this.props.language]['speakerNotPublished']}</p>
          </div>
        )}
      </div>
    );
  }
}

Speakers.propTypes = {
  loadSpeakers: PropTypes.func.isRequired,
  speakers: PropTypes.array,
  showSpeaker: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  fetching: PropTypes.bool,
};
