import io from 'socket.io-client';

const socketsHelper = {
  socket: null,
  token: null,
  attemps: 0,
  close: () => {
    if (socketsHelper.socket) {
      socketsHelper.socket.close();
      socketsHelper.socket = null;
    }
  },
  isConnecting: () => {
    // Check if the socket exists and is connecting
    return socketsHelper.socket && socketsHelper.connecting;
  },
  isConnected: () => {
    // Check if the socket exists and is connected
    return socketsHelper.socket && socketsHelper.socket.connected;
  },
  init: (url, nameSpace, token) => {
    socketsHelper.connecting = true;
    // eslint-disable-next-line prefer-promise-reject-errors
    if (!token) return Promise.reject('No token provided');
    if (socketsHelper.socket) {
      return Promise.resolve(socketsHelper.socket);
    }
    socketsHelper.socket = null;
    socketsHelper.token = token;

    return new Promise((resolve, reject) => {
      socketsHelper.socket = io(`${url}/${nameSpace}`, {
        query: `ns=/${nameSpace}`,
        handshake: true,
        transports: ['websocket'],
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${socketsHelper.token}`,
            },
          },
        },
      });

      socketsHelper.socket.on('connect', () => {
        socketsHelper.connecting = false;

        socketsHelper.attemps = 0;
        resolve(socketsHelper.socket);
      });

      socketsHelper.socket.on('error', (error) => {
        console.error(error);
        socketsHelper.close();
        socketsHelper.attemps++;

        if (socketsHelper.attemps > 3) {
          socketsHelper.attemps = 0;

          return reject(error);
        }
        // Wait for some time before retrying (simple backoff strategy)
        setTimeout(() => {
          socketsHelper.init(url, nameSpace, token).then(resolve).catch(reject);
        }, 1000 * socketsHelper.attemps);
      });
    });
  },
};

export default socketsHelper;
