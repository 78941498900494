import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import jsxToString from 'jsx-to-string';

const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
  );

  if (window.focus) newWindow.focus();
  return newWindow;
};

const TermsOfService = (props) => {
  return (
    <div className="terms-of-service">
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={props.checked}
            onChange={props.handleAgreeToTerms}
          />
        }
        label={
          <span>
            {window.lang[props.language]['agree']}
            <span
              className="terms-of-service-url"
              onClick={(e) => {
                e.preventDefault();

                const w = popupCenter({
                  url: '',
                  title: 'xtf',
                  w: 300,
                  h: 400,
                });
                const innerHTML = jsxToString(
                  <span style={{ textAlign: 'left' }}>
                    <h4>{window.lang[props.language]['termsOfUse']}</h4>
                    <ul>
                      <li>{window.lang[props.language]['termsOfUse1']}</li>
                      <li>{window.lang[props.language]['termsOfUse2']}</li>
                      <li>{window.lang[props.language]['termsOfUse3']}</li>
                      <li>{window.lang[props.language]['termsOfUse4']}</li>
                    </ul>
                    {(props.policy.disclaimer || '').length > 0 && (
                      <h4>{window.lang[props.language]['disclaimerPolicy']}</h4>
                    )}
                    {(props.policy.disclaimer || '').length > 0 && (
                      <div>{props.policy.disclaimer}</div>
                    )}
                    {(props.policy.billing.cancellationPolicy || '').length >
                      0 && (
                      <div style={{ marginTop: '20px' }}>
                        <h4>
                          {window.lang[props.language]['cancellationPolicy']}
                        </h4>
                        <div>{props.policy.billing.cancellationPolicy}</div>
                      </div>
                    )}
                  </span>
                );
                w.document.body.innerHTML = innerHTML.replace(
                  /(\(.*?\))(\[.*?\])/g,
                  function (match, p1, p2) {
                    return `<a href="${p2
                      .substring(0, p2.length - 1)
                      .slice(1)}" target="_blank">${p1
                      .substring(0, p1.length - 1)
                      .slice(1)}</a>`;
                  }
                );
                w.document.title = 'Terms Of Service';
                const onfocus = () => {
                  w.close();
                  window.removeEventListener('focus', onfocus);
                };
                window.addEventListener('focus', onfocus);
              }}>
              {window.lang[props.language]['termsOfUse']}
            </span>
          </span>
        }
      />
    </div>
  );
};
TermsOfService.propTypes = {
  language: PropTypes.string.isRequired,
  handleAgreeToTerms: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
};
export default TermsOfService;
