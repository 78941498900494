import React from 'react';
import PropTypes from 'prop-types';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import Recaptcha from '@components/layout/Recaptcha';
import { connect } from 'react-redux';
import { setCaptchaCheck } from '@actions';
import configuration from '@config/conf';

const Mail = (props) => {
  const [name, setName] = React.useState('');
  const [question, setQuestion] = React.useState('');
  const [replyTo, setReplyTo] = React.useState('');
  const [sendingStatus, setSendingStatus] = React.useState(false);

  const createCustomEmail = (e) => {
    const {
      event,
      sessionName,
      mailto,
      isMessage,
      createCustomEmail,
      addNotification,
    } = props;
    const template = JSON.stringify({
      subject: event.name + ' ' + sessionName,
      html: `${name}, ${question}`,
      replyTo: replyTo,
      preserveRecipients: isMessage ? true : false,
    });
    const emailArr = [mailto];
    const data = {
      template,
      contacts: [{ emails: emailArr, contactEmail: true }],
    };

    if (name.trim().length > 0 && question.trim().length > 0) {
      setSendingStatus(true);
      createCustomEmail(event.id, data).then(
        () => {
          setQuestion('');
          setName('');
          setReplyTo('');
          addNotification(`Message has been successfully submitted!`);
          setSendingStatus(false);
          props.closeModal();
        },
        () => setSendingStatus(false)
      );
    } else {
      addNotification(`Please fill all the required fields`, 0);
    }
    e.preventDefault();
  };

  return (
    <div
      className={`form-group`}
      style={{
        padding: '15px 10px 20px 15px',
        display: props.hidden ? 'none' : undefined,
        height: '100%',
      }}>
      <form style={{ height: '100%' }} onSubmit={(e) => createCustomEmail(e)}>
        <br />
        <label
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#2d2b3c',
          }}>
          <p className="message-header">
            {props.isMessage
              ? window.lang[props.language]['yourMessage']
              : window.lang[props.language]['videoFormText']}
            {props.isMessage && (
              <CloseRoundedIcon
                className="icon-close"
                style={{ cursor: 'pointer' }}
                onClick={(e) => props.closeModal(e)}></CloseRoundedIcon>
            )}
          </p>
        </label>
        <div className="mail-input-label">
          {window.lang[props.language]['name']}
        </div>
        <input
          className="form-control"
          placeholder={window.lang[props.language]['typeYourName']}
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        {!props.userMail && props.isMessage && (
          <React.Fragment>
            <input
              className="form-control"
              placeholder={window.lang[props.language]['email']}
              value={replyTo}
              onChange={(e) => setReplyTo(e.target.value)}
              required
              type="email"
            />
            <br />
          </React.Fragment>
        )}
        <div className="mail-input-label">
          {window.lang[props.language]['message']}
        </div>
        <textarea
          rows={2}
          style={{ padding: '5px' }}
          className="form-control"
          required
          placeholder={
            props.isMessage
              ? window.lang[props.language]['typeMessage']
              : window.lang[props.language]['question']
          }
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        {props.subNoteText?.length > 0 && (
          <div className="sub-note-text">{props.subNoteText}</div>
        )}
        <div
          className="submit-container"
          style={{
            textAlign: 'left',
            position: 'absolute',
            bottom: '16px',
            width: 'calc(100% - 32px)',
          }}>
          {props.messageFailAttempts >= configuration.allowedNoCaptcha && (
            <Recaptcha componentToVerify="message" />
          )}
          <button
            disabled={props.messageFailAttempts >= 5 && !props.recaptchaIsValid}
            type="submit"
            className="btn btn-primary submit-message"
            style={{
              height: '48px',
              backgroundColor:
                props.event.clientPanelSettings.general.colors.primary,
            }}>
            {sendingStatus ? (
              <CircularProgress size={24} className="submit-loader" />
            ) : (
              window.lang[props.language]['submit']
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

Mail.propTypes = {
  language: PropTypes.string,
  subNoteText: PropTypes.string,
  createCustomEmail: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  event: PropTypes.object,
  sessionName: PropTypes.string,
  mailto: PropTypes.string,
  srcUrl: PropTypes.string,
  recaptchaIsValid: PropTypes.bool.isRequired,
  messageFailAttempts: PropTypes.number.isRequired,
  speechId: PropTypes.number,
  session: PropTypes.object,
  hidden: PropTypes.bool,
  customLectic: PropTypes.string,
  isMessage: PropTypes.bool,
  userMail: PropTypes.string,
  openState: PropTypes.bool,
  closeModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    messageFailAttempts: state.api.recaptcha.message.failedAttempts,
    recaptchaIsValid: state.api.recaptcha.message.valid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCaptchaToken: (token, componentToVerify) =>
      dispatch(setCaptchaCheck(token, componentToVerify)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Mail);
