import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Search } from '@dynamic_assets/search.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CheckIcon from '@material-ui/icons/Check';
import Paginator from './Paginator';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const Toolbar = (props) => {
  return (
    <>
      <div className="toolbar-container">
        <div className="viewing-tools-wrapper">
          {props.singleSelects?.map((select, i) => {
            return (
              <Select
                MenuProps={{ variant: 'menu' }}
                variant="outlined"
                key={`single-select-${i}`}
                onChange={(event) => {
                  if (event.target.value === select.nullEntry) {
                    event.target.value = '';
                  }
                  select.callback(event);
                }}
                defaultValue={select.nullEntry}
                renderValue={(selectedValue) => {
                  if (selectedValue === select.nullEntry) {
                    return select.nullEntry;
                  } else {
                    return select.data[selectedValue];
                  }
                }}>
                {select.nullEntry?.length > 0 && (
                  <MenuItem key="null-entry" value={select.nullEntry}>
                    {select.nullEntry}
                  </MenuItem>
                )}
                {Object.entries(select.data).map(([key, value]) => {
                  return (
                    <MenuItem key={`option-${key}`} value={key}>
                      {value}
                      {select.currentVal === key && (
                        <CheckIcon className="selected-checkmark" />
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            );
          })}

          {props.multiSelects?.map((select, i) => {
            return (
              <Select
                key={`multi-select-${i}`}
                MenuProps={{ variant: 'menu' }}
                variant="outlined"
                defaultValue={select.nullEntry}
                multiple={true}
                value={select.currentVal}
                renderValue={(selectedKeys) => {
                  if (
                    selectedKeys.length === 1 &&
                    selectedKeys.indexOf(select.nullEntry) > -1
                  ) {
                    return select.nullEntry;
                  }
                  return selectedKeys
                    ?.map((selected_key) => {
                      return select.data[selected_key];
                    })
                    .join(', ');
                }}
                onChange={(event) => {
                  if (event.target.value?.length > 1) {
                    event.target.value = event.target.value.filter((key) => {
                      return key !== select.nullEntry;
                    });
                  }
                  event.target.value = event.target.value.map((key) => {
                    if (key === select.nullEntry) return '';
                    return key;
                  });

                  event.target.value =
                    event.target.value.length > 0 ? event.target.value : [''];

                  select.callback(event);
                }}>
                {Object.entries(select.data).map(([key, value]) => {
                  return (
                    <MenuItem key={`option-${key}`} value={key}>
                      <Checkbox
                        checked={
                          select.currentVal?.indexOf(key) > -1 ||
                          select.currentVal?.indexOf(value) > -1
                        }
                      />
                      <ListItemText> {value}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Select>
            );
          })}

          <div className="search-bar">
            <TextField
              id="standard-basic"
              variant="outlined"
              placeholder={lang[props.language]['searchByTitleOrPublishedId']}
              onChange={props.handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color={'var(--gray-500)'} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className="paginator-container sticky">
        <Paginator
          items={props.items}
          meta={props.meta}
          language={props.language}
          onChange={(meta) => props.getItems(meta)}
          searchPrompt={props.searchPrompt}
          fetching={props.fetching}
        />
      </div>
    </>
  );
};

Toolbar.propTypes = {
  language: PropTypes.string.isRequired,
  items: PropTypes.array,
  meta: PropTypes.object,
  schema: PropTypes.object,
  getItems: PropTypes.func,
  handleSearch: PropTypes.func,
  handleTopicChange: PropTypes.func,
  handleTypeChange: PropTypes.func,
  searchPrompt: PropTypes.string,
  getOptions: PropTypes.object,
  selectedTopics: PropTypes.array,
  fetching: PropTypes.bool,
  singleSelects: PropTypes.array,
  multiSelects: PropTypes.array,
};

Toolbar.defaultProps = {
  singleSelects: [],
  multiSelects: [],
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
