import React from 'react';
import PropTypes from 'prop-types';

export default class Iframe extends React.Component {
  render() {
    return (
      <div
        className="video-container"
        style={{ display: this.props.hidden ? 'none' : '' }}>
        <div
          className="player-container"
          style={{ display: this.props.hidden ? 'none' : '' }}>
          <iframe
            src={this.props.srcUrl}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
        <div className="video-info-container">{this.props.videoTitle}</div>

        {!!this.props.callToAction && this.props.callToAction}
      </div>
    );
  }
}

Iframe.propTypes = {
  srcUrl: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  callToAction: PropTypes.obj,
};
