import React from 'react';
import PropTypes from 'prop-types';

const ForgotPasswordIcon = ({ color1, color2, color3 }) => {
  return (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 33C5 17.536 17.536 5 33 5C48.464 5 61 17.536 61 33C61 48.464 48.464 61 33 61C17.536 61 5 48.464 5 33Z"
        fill={color1}
      />
      <path
        d="M37.0827 27.7526L41.166 23.6693M43.4993 21.3359L41.166 23.6693L43.4993 21.3359ZM32.2877 32.5476C32.8901 33.142 33.3689 33.8496 33.6967 34.6299C34.0245 35.4101 34.1947 36.2474 34.1975 37.0937C34.2004 37.9399 34.0358 38.7784 33.7132 39.5608C33.3907 40.3432 32.9166 41.054 32.3182 41.6524C31.7198 42.2508 31.0089 42.725 30.2265 43.0475C29.4441 43.37 28.6057 43.5346 27.7594 43.5318C26.9132 43.529 26.0758 43.3588 25.2956 43.031C24.5154 42.7032 23.8077 42.2243 23.2133 41.6219C22.0445 40.4117 21.3977 38.7909 21.4124 37.1084C21.427 35.426 22.1018 33.8166 23.2915 32.6269C24.4812 31.4372 26.0906 30.7624 27.773 30.7478C29.4554 30.7332 31.0763 31.3799 32.2865 32.5488L32.2877 32.5476ZM32.2877 32.5476L37.0827 27.7526L32.2877 32.5476ZM37.0827 27.7526L40.5827 31.2526L44.666 27.1693L41.166 23.6693L37.0827 27.7526Z"
        stroke={color2}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 56C20.2975 56 10 45.7025 10 33H0C0 51.2254 14.7746 66 33 66V56ZM56 33C56 45.7025 45.7025 56 33 56V66C51.2254 66 66 51.2254 66 33H56ZM33 10C45.7025 10 56 20.2975 56 33H66C66 14.7746 51.2254 0 33 0V10ZM33 0C14.7746 0 0 14.7746 0 33H10C10 20.2975 20.2975 10 33 10V0Z"
        fill={color3}
      />
    </svg>
  );
};

ForgotPasswordIcon.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
};

export default ForgotPasswordIcon;
