import React from 'react';
import { connect } from 'react-redux';
import {
  changeSection,
  getMyAbstracts,
  showAbstract,
  createNewAbstract,
  deleteAbstract,
  getSchema,
} from '@actions';
import PropTypes from 'prop-types';
import Table from '@components/layout/Table';
import Modal from '@components/layout/Modal';
import KMBLoader from '@components/layout/KMBLoader';
import MobileTable from '@components/layout/MobileTable';
import Paginator from '@components/layout/Paginator';

import moment from 'moment-timezone';
class Abstracts extends React.Component {
  constructor(props) {
    super(props);

    this.columns = {
      cosmetic: {
        name: '',
      },
      id: {
        name: window.lang[this.props.language]['id'],
      },
      name: {
        name: window.lang[this.props.language]['name'],
      },
      authors: {
        name: window.lang[this.props.language]['authors'],
      },
      status: {
        name: window.lang[this.props.language]['status'],
      },
      topics: {
        name: window.lang[this.props.language]['topics'],
      },
      // type: {
      //   name: window.lang[this.props.language]["type"]
      // },
      createdAt: {
        name: window.lang[this.props.language]['createdAt'],
      },
      actions: {
        name: '',
      },
    };

    this.state = {
      modalIsOpen: false,
      abstractId: null,
    };

    this.onRender = this.onRender.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.eventAbstractSchemaId) {
      this.props.getSchema(
        this.props.orgId,
        this.props.eventId,
        this.props.eventAbstractSchemaId
      );
    }

    this.props.getMyAbstracts(this.props.eventId);
  }

  onRender(col, item) {
    switch (col.key) {
      case 'cosmetic': {
        return <span className="icon-presentation" />;
      }

      case 'authors': {
        return col.value.map(
          (a, i) =>
            `${a.firstName} ${a.lastName}${
              i < col.value.length - 1 ? ', ' : ''
            }`
        );
      }

      case 'createdAt': {
        return col.value.tz;
      }

      case 'topics': {
        return col.value.map(
          (a, i) => `${a.name}${i < col.value.length - 1 ? ', ' : ''}`
        );
      }
      case 'actions':
        return item.status === 'draft' ? (
          <span
            className="delete-abstract"
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ modalIsOpen: true, abstractId: item.id });
            }}>
            <i className="icon-close" />
          </span>
        ) : (
          <span />
        );

      case 'status': {
        const val = col.value.replace('_', ' ');
        if (col.value === 'awaiting_resubmission') {
          return (
            <span className={col.value}>
              {val}
              <i className="icon-infoline" />
            </span>
          );
        }
        return <span className={col.value}> {val} </span>;
      }
    }
  }

  render() {
    const shouldPayOnce =
      this.props.policy.mandatoryPayment &&
      this.props.user.subscriptionDueAmount > 0;
    const hasPaidOnce =
      Object.keys(this.props.user.subscription?.payment || {}).length > 0 ||
      Object.keys(this.props.user.subscription?.sponsorship || {}).length > 0;
    const canViewMyAbstracts = shouldPayOnce ? hasPaidOnce : true;

    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;
    if (!this.props.ready) {
      return <KMBLoader rows={20} />;
    }

    return (
      <div>
        {JSON.stringify(this.props.schema) === '{}' ? (
          <div className="empty-results">
            <span className="icon-warning-2" />
            <p>
              {window.lang[this.props.language]['abstractsSubmissionNoStart']}
            </p>
          </div>
        ) : (
          <React.Fragment>
            {canViewMyAbstracts &&
              (this.props.abstracts.length === 0 ? (
                <div>
                  <div className="empty-results">
                    <p>
                      {window.lang[this.props.language]['noAbstractsFound']}
                    </p>
                    {this.props.startDate?.tz &&
                      this.props.endDate?.tz &&
                      moment(this.props.startDate.tz).isAfter(
                        moment(this.props.endDate.tz)
                      ) && (
                        <p>
                          {
                            window.lang[this.props.language][
                              'abstractsSubmissionOver'
                            ]
                          }
                        </p>
                      )}
                    {this.props.startDate?.tz &&
                      this.props.endDate?.tz &&
                      moment(this.props.endDate.tz).isAfter(
                        moment(this.props.startDate.tz)
                      ) &&
                      moment()
                        .utc()
                        .isBefore(moment(this.props.endDate.utc)) && (
                        <button
                          className="btn btn-primary"
                          onClick={() => this.props.createNewAbstract()}
                          style={{
                            marginTop: '15px',
                            backgroundColor:
                              this.props.event.clientPanelSettings.general
                                .colors.primary,
                          }}>
                          {window.lang[this.props.language]['createAbstract']}
                        </button>
                      )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="paginator-container sticky">
                    <Paginator
                      items={this.props.abstracts}
                      meta={this.props.meta}
                      language={this.props.language}
                      onChange={(meta) => {
                        this.props.getMyAbstracts(this.props.eventId, meta);
                      }}
                      fetching={false}
                    />
                  </div>
                  <div className="row header-area">
                    {moment(this.props.endDate.tz).isAfter(
                      moment(this.props.startDate.tz)
                    ) &&
                      moment()
                        .utc()
                        .isBefore(moment(this.props.endDate.utc)) && (
                        <div className="col-6 text-left">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.props.createNewAbstract()}
                            style={{
                              marginBottom: '35px',
                              marginTop: '20px',
                              backgroundColor: primaryColor,
                            }}>
                            {window.lang[this.props.language]['createAbstract']}
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="display-mobile">
                    <Table
                      id={'list-abstracts'}
                      columns={this.columns}
                      items={this.props.abstracts}
                      onRender={this.onRender}
                      updating={this.props.fetching}
                      onTrClick={(a) => this.props.showAbstract(a.id)}
                      onTrClassName={(className, item) => {
                        return `status-${item.status}`;
                      }}
                    />
                  </div>
                  <div className="mobile-table">
                    <MobileTable
                      columns={this.columns}
                      items={this.props.abstracts}
                      onRender={(col, item) => this.onRender(col, item)}
                      onTrClick={(id) => this.props.showAbstract(id)}
                    />
                  </div>
                </div>
              ))}
            {!canViewMyAbstracts && (
              <div>
                <div className="empty-results">
                  <span className="icon-warning-2" />
                  <div className="speech-bubble">
                    <p>
                      {window.lang[this.props.language]['hi']}{' '}
                      {this.props.user.info.firstName},{' '}
                      {
                        window.lang[this.props.language][
                          'registrationNotCompleted'
                        ]
                      }
                    </p>
                    <p>
                      {window.lang[this.props.language]['abstractDuesMessage']}
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        this.props.changeSection(
                          'account',
                          undefined,
                          'billing'
                        )
                      }
                      style={{ marginTop: '20px' }}>
                      {window.lang[this.props.language]['goToPayments']}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <Modal
              modalIsOpen={this.state.modalIsOpen}
              closeModal={() => this.setState({ modalIsOpen: false })}
              inner={[
                <div key="main-1">
                  <p key="main-2">
                    {window.lang[this.props.language]['deleteAbstract']}{' '}
                    {window.lang[this.props.language]['deleteDescription']}
                  </p>
                  <div style={{ textAlign: 'right' }} key="main-3">
                    <button
                      key="main-4"
                      style={{ margin: '5px' }}
                      className="btn btn-cancel"
                      onClick={() => this.setState({ modalIsOpen: false })}>
                      Cancel
                    </button>
                    <button
                      key="main-5"
                      style={{ margin: '5px' }}
                      className="btn btn-primary"
                      onClick={() => {
                        this.props
                          .deleteAbstract(
                            this.props.eventId,
                            this.state.abstractId
                          )
                          .then(() => {
                            this.props.getMyAbstracts(this.props.eventId);
                            this.setState({ modalIsOpen: false });
                          });
                      }}>
                      OK
                    </button>
                  </div>
                </div>,
              ]}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventId: state.api.event.data.id,
    abstracts: state.api.myAbstracts.data,
    meta: state.api.myAbstracts.meta,
    fetching: state.api.myAbstracts.fetching,
    language: state.api.language.selected,
    user: state.api.user.data,
    mandatoryPayment: state.api.policy.data.mandatoryPayment,
    ready: state.api.myAbstracts.ready,
    endDate: state.api.schema.data.endDate,
    startDate: state.api.schema.data.startDate,
    timezone: state.api.schema.data.startDate?.timezone,
    schema: state.api.schema,
    event: state.api.event.data,
    policy: state.api.policy.data,
    orgId: state.api.event.data.orgId,
    eventAbstractSchemaId: state.api.event.data.eventAbstractSchemaId,
  };
};

Abstracts.propTypes = {
  changeSection: PropTypes.func.isRequired,
  getMyAbstracts: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  event: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
  abstracts: PropTypes.array,
  meta: PropTypes.object,
  showAbstract: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  mandatoryPayment: PropTypes.number,
  getSchema: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  orgId: PropTypes.number,
  eventAbstractSchemaId: PropTypes.number,
  ready: PropTypes.bool,
  timezone: PropTypes.string,
  schema: PropTypes.object,
  deleteAbstract: PropTypes.func,
  createNewAbstract: PropTypes.func,
  endDate: PropTypes.object,
  startDate: PropTypes.object,
};

export default connect(mapStateToProps, {
  createNewAbstract,
  changeSection,
  getMyAbstracts,
  showAbstract,
  deleteAbstract,
  getSchema,
})(Abstracts);
