import { connect } from 'react-redux';
import { api } from '@actions/ajax';
import {
  changeScreen,
  changeSection,
  togglePasswordVisibility,
  registerUser,
  validateToken,
  verifyEmail,
  calculateSubscription,
  getSubscriptions,
  submitPayment,
  getReceipt,
  getProducts,
} from '@actions';
import SignupPageNew from './SignupPageNew';

const mapStateToProps = (state) => {
  return {
    policy: state.api.policy.data,
    visibility: state.api.policy.visibility,
    errorMessage: state.api.user.errorMessage,
    currency: state.api.policy.data.currencySymbol,
    eventId: state.api.policy.data.eventId,
    currencyIso: state.api.policy.data.currencyIso,
    language: state.api.language.selected,
    user: state.api.user.data,
    products: state.api.products.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeScreen: (screen) => {
      dispatch(changeScreen(screen));
    },
    changeSection: (section) => {
      return dispatch(changeSection(section));
    },
    togglePasswordVisibility: (key) => {
      dispatch(togglePasswordVisibility(key));
    },
    getProducts: (token) => {
      return dispatch(getProducts(token));
    },
    getReceipt: (eventId, id, paymentRef, token) =>
      dispatch(getReceipt(eventId, id, paymentRef, token)),
    submitPayment: (
      eventId,
      products,
      totalPrice,
      currencyIso,
      fields,
      userFields,
      signup = false,
      paymentMethod = 'card',
      orgCrmUserId
    ) =>
      dispatch(
        submitPayment(
          eventId,
          products,
          totalPrice,
          currencyIso,
          fields,
          userFields,
          signup,
          paymentMethod,
          orgCrmUserId
        )
      ).then((d) => {
        if (d.data.location) {
          window.location.replace(d.data.location);
        } else {
          const f = document.createElement('form');
          f.setAttribute('method', 'post');
          f.setAttribute(
            'action',
            'https://paycenter.piraeusbank.gr/redirection/pay.aspx'
          );

          for (const i in d.data) {
            const input = document.createElement('input'); //input element, text
            input.setAttribute('name', [i]);
            input.setAttribute('type', 'hidden');
            input.setAttribute('value', d.data[i]);
            f.appendChild(input);
          }
          document.getElementsByTagName('body')[0].appendChild(f);
          f.submit();
        }
      }),
    registerUser: (fields, token, orgCrmUserId, products) =>
      dispatch(
        registerUser(
          fields,
          token,
          undefined,
          undefined,
          orgCrmUserId,
          products
        )
      ),
    calculateSubscription: (fields, token) =>
      dispatch(calculateSubscription(fields, token)),
    validateToken: (token) => dispatch(validateToken(token)),
    verifyEmail: (fields) => dispatch(verifyEmail(fields)),
    goToBilling: () => changeSection('account', undefined, 'billing'),
    getSubscriptions: (eventId, policyId) =>
      getSubscriptions(eventId, policyId),
    paypalCreateOrder: (eventId, data) =>
      dispatch(
        api({
          endpoint: 'payments',
          action: 'paypalCreateOrder',
          body: { data },
          params: { eventId },
          insecure: true,
        })
      ),
    paypalTransactionCompleted: (data) =>
      dispatch(
        api({
          endpoint: 'payments',
          action: 'paypalTransactionCompleted',
          body: { data },
          insecure: true,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPageNew);
