import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PropTypes from 'prop-types';

export default class InfoPage extends React.Component {
  render() {
    return (
      <div className="page-container">
        <div className="empty-results">
          {/* <span className="icon-warning-2" /> */}
          <HighlightOffIcon className="transaction-failed-icon" />
          <p>{window.lang[this.props.language]['transactionRefused']}</p>
          <span>
            <p>
              {window.lang[this.props.language]['transactionRefusedMessage1']}
              <p>
                {window.lang[this.props.language]['transactionRefusedMessage2']}
              </p>
            </p>
          </span>
          <button
            className="btn btn-primary"
            onClick={() => this.props.goToBilling('billing')}>
            {window.lang[this.props.language]['goToPayments']}
          </button>
        </div>
      </div>
    );
  }
}

InfoPage.propTypes = {
  language: PropTypes.string,
  goToBilling: PropTypes.func,
};
