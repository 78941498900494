import { connect } from 'react-redux';
import Speakers from './Speakers';
import { loadSpeakers, showSpeaker } from '@actions';

const mapStateToProps = (state) => {
  return {
    speakers: state.api.speakers.data,
    fetching: state.api.speakers.fetching,
    meta: state.api.speakers.meta,
    event: state.api.event.data,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadSpeakers: (meta) => dispatch(loadSpeakers(meta)),
  showSpeaker: (id) => dispatch(showSpeaker(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Speakers);
