import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Backdrop } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { getTypeLangCode, bytesToMegabytes } from '@helpers/fileHelpers';
import KMBLoader from '@components/layout/KMBLoader';
import Input from '@material-ui/core/Input';
import { Page } from 'react-pdf/dist/esm/entry.webpack';

const PdfViewer = React.lazy(() => import('./PDFViewer'));

const FileViewer = (props) => {
  const ref = React.useRef();

  const publishedAbstract =
    props.abstract?.publishedAbstractFile?.length > 0
      ? props.abstract.publishedAbstractFile[0]
      : null;

  const [numPages, setNumPages] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  let pageRefMap = {};

  const onDocumentLoadSuccess = (pdf) => {
    pageRefMap = {};
    setNumPages(pdf.numPages);
  };

  const handleScroll = () =>
    setCurrentPage(
      Math.ceil(
        (ref.current?.scrollTop / ref.current?.scrollHeight) * numPages + 0.4
      )
    );

  const goToPage = (pageNum) => {
    const pageRef = pageRefMap[`page_${pageNum}_ref`].current;
    if (pageRef && pageRef.pageElement.current) {
      pageRef.pageElement.current.scrollIntoView();
    }
  };

  const generatePages = () => {
    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pageRefMap[`page_${i + 1}_ref`] = React.useRef();
      pages.push(
        <Page
          loading={<KMBLoader rows={10} height={1} />}
          ref={pageRefMap[`page_${i + 1}_ref`]}
          id={`page_${i + 1}`}
          key={`page_${i + 1}`}
          pageNumber={i + 1}
          width={612}
        />
      );
    }
    return pages;
  };

  const setPage = (e) => {
    let pageValue;
    if (e.target.value === '') {
      setCurrentPage(0);
      return;
    }
    const value = parseInt(e.target.value);
    if (value <= 0) {
      pageValue = 1;
    } else if (value > numPages) {
      pageValue = numPages;
    } else {
      pageValue = Math.floor(value);
    }

    setCurrentPage(pageValue);
    goToPage(pageValue);
  };

  const returnTypedView = () => {
    switch (publishedAbstract?.extension) {
      case 'pdf':
        return (
          <PdfViewer
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            numPages={numPages}
            pdfUrl={publishedAbstract.url}
            pagesWrapperRef={ref}
            handleScroll={handleScroll}
            generatePages={generatePages}
          />
        );
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <div className="image-viewer">
            <img src={publishedAbstract?.url}></img>
          </div>
        );
    }
  };

  return (
    <Backdrop
      className="backdrop-file-viewer"
      open={props.fileViewerOpen}
      onClick={(e) => {
        e.stopPropagation();
        props.handleClose();
      }}>
      <div
        onClick={(e) => {
          //! necessary to prevent accidental backdrop close
          e.stopPropagation();
        }}
        className="file-viewer-wrapper">
        <div className="top-toolbar">
          <div className="toolbar-actions">
            <Button
              onClick={() => window.open(publishedAbstract?.url)}
              className="download-file"
              variant="contained">
              {lang[props.language]['download']}
            </Button>
            {publishedAbstract?.size && (
              <div className="file-size-text preview">
                {`${
                  lang[props.language][
                    getTypeLangCode(publishedAbstract.extension)
                  ]
                } (${bytesToMegabytes(publishedAbstract.size)}MB)`}
              </div>
            )}
            {publishedAbstract?.extension === 'pdf' && (
              <div className="page-indicator">
                <Input
                  className="page-selector"
                  type="number"
                  value={currentPage}
                  onChange={setPage}
                />
                <div className="total-page-text">{` / ${numPages ?? 0}`}</div>
              </div>
            )}
          </div>
          <div className="close-action-wrapper">
            <IconButton
              className="close-file-viewer-wrapper"
              onClick={() => props.handleClose()}>
              <CloseRoundedIcon className="close-share" />
            </IconButton>
          </div>
        </div>
        <div className="content-wrapper">
          {props.headerComponent && props.headerComponent()}
          <Suspense fallback={<KMBLoader rows={10} height={18} />}>
            {returnTypedView()}
          </Suspense>
        </div>
        <div className="file-bottom-info">
          {props.infoComponent && props.infoComponent()}
        </div>
      </div>
    </Backdrop>
  );
};

FileViewer.propTypes = {
  abstract: PropTypes.object,
  fileViewerOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  infoComponent: PropTypes.func,
  headerComponent: PropTypes.func,
  language: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
  };
};

export default connect(mapStateToProps)(FileViewer);
