import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '@helpers';
import Overall from './Overall/';
import Agenda from './Agenda/';

export default class Schedule extends React.Component {
  constructor(props) {
    super(props);

    this.getSubComponent = this.getSubComponent.bind(this);
    this.changeSubMenu = this.changeSubMenu.bind(this);

    this.state = {
      active: 'overall',
    };

    this.submenu = [
      {
        key: 'overall',
        label: window.lang[this.props.language]['overallSchedule'],
      },
    ];
    if (!this.props.agendaVisible) {
      this.submenu.push({
        key: 'agenda',
        label: window.lang[this.props.language]['myAgenda'],
      });
    }
    this.components = {
      Overall,
      Agenda,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  changeSubMenu(active) {
    this.setState({ active });
  }

  getSubComponent() {
    let name = capitalizeFirstLetter(this.state.active);
    if (name === 'Perdate') {
      name = 'Agenda';
    }
    const Component = this.components[name];
    return <Component mode={this.state.active} />;
  }

  render() {
    return (
      <div>
        <main className="container">{this.getSubComponent()}</main>
      </div>
    );
  }
}

Schedule.propTypes = {
  loadSessions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  freeAccess: PropTypes.bool.isRequired,
  agendaVisible: PropTypes.bool.isRequired,
  language: PropTypes.string,
};
