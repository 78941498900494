import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import UserNotifications from '@components/layout/UserNotifications';
import UserMenu from '@components/layout/UserMenu';
import ObservableMenuItem from '@components/layout/ObservableMenuItem';

const AppTopbar = (props) => {
  const [showingFeatures, setShowingFeatures] = React.useState(false);
  const [itemsInMore, setItemsInMore] = React.useState([]);
  const [isMoreActive, setIsMoreActive] = React.useState([]);
  const [enabledMenuItems, setEnabledMenuItems] = React.useState([]);
  const [masterFeatureControl, setMasterFeatureControl] = React.useState([]);

  React.useEffect(() => {
    const newEnabledItems = [];
    Object.entries(props.menu).forEach(([key, value]) => {
      if (value.enabled && !value.hidden && key !== 'billing') {
        newEnabledItems.push({ key, ...value });
      }
    });
    if (props.event?.featuresOrder?.length > 0) {
      setMasterFeatureControl(props.event.featuresOrder);
    }

    const sortedEnabledItems = new Array(props.event?.featuresOrder?.length);

    masterFeatureControl.forEach((feat_control) => {
      newEnabledItems.forEach((feature) => {
        if (
          feat_control.key === props.featureKeyMap[feature.key] &&
          !!feat_control.enabled
        ) {
          sortedEnabledItems[feat_control.index - 1] = feature;
        }
      });
    });

    setEnabledMenuItems(
      sortedEnabledItems.filter((n) => {
        if (n) return n;
      })
    );
  }, [props.menu, props.event?.featuresOrder]);

  React.useEffect(() => {
    setMoreActive();
  }, [props.active, itemsInMore]);

  React.useEffect(() => {
    const itemsInViewBuffer = [];
    Object.entries(props.itemsInView).forEach(([key, value]) => {
      const isEnabled = enabledMenuItems.some(
        (item) => key === item.key && item.enabled
      );
      if (!value && key !== 'billing' && isEnabled) {
        itemsInViewBuffer.push({ key, label: props.menu[key].label });
      }
    });

    setItemsInMore(itemsInViewBuffer);
    setMoreActive();
  }, [props.itemsInView, enabledMenuItems]);

  React.useEffect(() => {
    masterFeatureControl.forEach((feat) => {
      if (feat.key === props.featureKeyMap[props.active] && !feat.enabled) {
        props.changeSection('generalInfo');
      }
    });
  }, []);

  const setMoreActive = () => {
    setIsMoreActive(itemsInMore.some((item) => item.key === props.active));
  };

  const showLogin = props.active !== 'login';
  const showRegister =
    props.policy.data.onlineRegistration === 1 &&
    props.policy.data.type === 'private' &&
    props.active !== 'login';

  return (
    <React.Fragment>
      <div className="app-topbar">
        <div
          className={`centering-wrapper ${
            props.active === 'login' ? 'login-appbar' : ''
          }`}>
          {props.policy.data.clientPanelSettings?.header?.banner[0]?.url
            ?.length > 0 && (
            <div className="topbar-logo">
              <div className="event-logo-wrapper">
                <img
                  src={
                    props.policy.data.clientPanelSettings?.header?.banner[0]
                      ?.url
                  }
                  className="event-logo"
                />
              </div>
            </div>
          )}

          <div className="topbar-navigation">
            <div className="navigation-wrapper">
              {enabledMenuItems.map((item) => {
                return (
                  item.key !== 'billing' && (
                    <ObservableMenuItem
                      active={props.active}
                      changeSection={props.changeSection}
                      changeScreen={props.changeScreen}
                      language={props.language}
                      isInView={props.itemsInView[item.key]}
                      item={item}
                      key={`nav-item-${item.key}`}
                      updateInViewMenuItem={props.updateInViewMenuItem}
                    />
                  )
                );
              })}
            </div>
            {itemsInMore.length > 0 && (
              <React.Fragment>
                <div className="navigation-more">
                  <div
                    className="nav-item"
                    onClick={() => {
                      setShowingFeatures(!showingFeatures);
                    }}>
                    <React.Fragment>
                      <div className="extra-features-wrapper">
                        <p
                          className="extra-features"
                          lang={props.language}
                          style={{
                            color: isMoreActive
                              ? window.colorPalette.primary[600]
                              : 'inherit',
                          }}>
                          {window.lang[props.language]['extraFeatures']}
                        </p>
                        {!showingFeatures && (
                          <ExpandMoreIcon
                            className="expand-features show-more"
                            style={{
                              color: isMoreActive
                                ? window.colorPalette.primary[600]
                                : 'var(--gray-500)',
                            }}
                          />
                        )}
                        {showingFeatures && (
                          <ExpandLessIcon
                            className="expand-features show-less"
                            style={
                              isMoreActive
                                ? { color: window.colorPalette.primary[600] }
                                : {}
                            }
                          />
                        )}
                      </div>
                      {showingFeatures && (
                        <React.Fragment>
                          <div
                            className="click-away"
                            onClick={() => {
                              setShowingFeatures(!showingFeatures);
                            }}
                          />
                          <div className="extra-features-panel">
                            {itemsInMore.map((item) => {
                              return (
                                <div
                                  key={`nav-item-${item.key}`}
                                  className={`nav-item extra-feature-nav${
                                    props.active === item.key ? ' active' : ''
                                  }`}
                                  onClick={() => {
                                    props.changeSection(item.key);
                                  }}>
                                  <p
                                    lang={props.language}
                                    style={
                                      props.active === item.key
                                        ? {
                                            color:
                                              window.colorPalette.primary[600],
                                          }
                                        : {}
                                    }>
                                    {item.label}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          <div className="topbar-actions">
            {props.user.data.id ? (
              <React.Fragment>
                <UserNotifications color={window.colorPalette.primary[600]} />
                <UserMenu
                  logout={props.logout}
                  changeSection={props.changeSection}
                  language={props.language}
                  user={props.user}
                  backgroundColor={window.colorPalette.primary[100]}
                  backgroundCart={window.colorPalette.primary[600]}
                  currency={props.currency}
                  getUser={props.getUser}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {showRegister && (
                  <Button
                    onClick={() => {
                      props.changeSection('login');
                      props.changeScreen('register');
                    }}
                    disableElevation
                    className="registration-nav"
                    variant="outlined">
                    {window.lang[props.language].registration}
                  </Button>
                )}
                {showLogin && (
                  <Button
                    onClick={() => {
                      props.changeSection('login');
                      props.changeScreen('login');
                    }}
                    className="login-nav"
                    variant="contained">
                    {window.lang[props.language]['login']}
                  </Button>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

AppTopbar.propTypes = {
  policy: PropTypes.object,
  featureKeyMap: PropTypes.object,
  event: PropTypes.object,
  language: PropTypes.string,
  currency: PropTypes.string,
  menu: PropTypes.object.isRequired,
  active: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
  changeScreen: PropTypes.func,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getUser: PropTypes.func,
  updateInViewMenuItem: PropTypes.func.isRequired,
  itemsInView: PropTypes.object.isRequired,
};

export default AppTopbar;
