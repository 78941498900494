import React from 'react';
import PropTypes from 'prop-types';

const Files = (props) => {
  const { content } = props;
  return (
    <a href={content.url}>
      <div className="content-info">
        <div className="description layout-item">
          <span className="icon-pdf" style={{ fontSize: 50 }} />
        </div>
      </div>
      <p style={{ textAlign: 'center' }}>{content.text}</p>
    </a>
  );
};
Files.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Files;
