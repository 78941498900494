import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import { colorPalette } from "@config/colorPalette";
import CancelRegistrationModal from './CancelRegistrationModal';

const StepperButtons = (props) => {
  const [cancelConfirmation, setCancelConfirmation] = useState(false);

  return (
    <div className="btn-container product-btn-container">
      {/* <div className="cost-sum-mobile">
        <div className="cost-wrapper">
          <div className="text-wrapper">
            <div className="overall-price-title">
              {`${window.lang[props.language]["totalCost"]}:`}
            </div>
            <div className="taxes-message-mobile">
              {`${window.lang[props.language]["taxesIncluded"]}`}
            </div>
          </div>
          <div className="overall-price">
            {`${calculateTotalProductCost()}${props.currency}`}
          </div>
        </div>
      </div> */}

      <div className="step-btn-row">
        {props.prevStep && (
          <button
            onClick={props.handlePrev}
            className="reverse step-btn back-btn">
            <div className="arrow-container btn-previous">
              <ArrowBackIcon />
            </div>
            <div className="btn-txt-container">
              <div className="btn-txt">
                <span className="txt-mobile">
                  {window.lang[props.language].previous}
                </span>
                <span className="txt-1">
                  {window.lang[props.language].previousStep}:
                </span>
                <br />
                <span className="txt-2">{props.prevStep}</span>
              </div>
            </div>
          </button>
        )}
        {props.nextStep && (
          <button
            className="step-btn row-reverse"
            style={{ backgroundColor: window.colorPalette.primary[600] }}
            onClick={() => {
              props.handleNext();
            }}>
            <div className="btn-txt-container">
              <div className="btn-txt">
                <span className="txt-mobile">
                  {window.lang[props.language].next}
                </span>
                <span className="txt-1">
                  {window.lang[props.language].nextStep}:
                </span>
                <br />
                <span className="txt-2">{props.nextStep}</span>
              </div>
            </div>
            <div className="arrow-container btn-next">
              <ArrowForwardIcon />
            </div>
          </button>
        )}
      </div>

      <div className="bottom-info">
        <div
          onClick={() => setCancelConfirmation(true)}
          style={{
            color: window.colorPalette.primary[600],
            textDecorationColor: window.colorPalette.primary[600],
          }}
          className={`cancel-registration`}>
          {window.lang[props.language].cancelRegistration}
        </div>
      </div>
      {cancelConfirmation && (
        <CancelRegistrationModal
          language={props.language}
          setModalVisibility={() => setCancelConfirmation(false)}
        />
      )}
    </div>
  );
};

StepperButtons.propTypes = {
  language: PropTypes.string.isRequired,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  changeSection: PropTypes.func,
  prevStep: PropTypes.string.isRequired,
  nextStep: PropTypes.string.isRequired,
};

StepperButtons.defaultProps = {
  handleNext: () => {},
  handlePrev: () => {},
};

export default StepperButtons;
