import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class Agenda extends React.Component {
  constructor(props) {
    super(props);
    ['changeDay', 'changeWeek', 'setupRange'].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );

    this.state = {
      activeWeek: props.sessions.calendar.activeWeek,
      activeDay: '-1',
      left: 0,
      originalOffset: 0,
      touchStartX: 0,
      prevTouchX: 0,
      beingTouched: false,
      selectedDay: '',
    };

    this.activeWeek, this.startDate, (this.endDate = null);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clearStatus) {
      this.setState({ activeDay: '-1', selectedDay: '' });
    }
  }
  setupRange() {
    const event = this.props.event;
    const activeWeek =
      this.props.sessions.calendar.weeks[`week_${this.state.activeWeek}`];
    const startDate = activeWeek[0].moment;
    const endWeek = Object.keys(this.props.sessions.calendar.weeks).length;
    let endDate = activeWeek[activeWeek.length - 1].moment;
    if (endDate.isAfter(moment(event.endDate.tz), 'days')) {
      endDate = moment(event.endDate.tz);
    }

    return { activeWeek, startDate, endDate, endWeek };
  }
  handleMove(clientX, endWeek) {
    if (this.state.beingTouched) {
      const touchX = clientX;
      const deltaX =
        touchX - this.state.touchStartX + this.state.originalOffset;

      if (deltaX < -150 && this.state.activeWeek !== endWeek) {
        this.changeWeek(parseInt(this.state.activeWeek) + 1);
      } else if (deltaX > 150 && this.state.activeWeek !== '1') {
        this.changeWeek(parseInt(this.state.activeWeek) - 1);
      }
      this.setState({
        left: deltaX,
      });
    }
  }
  handleStart(clientX) {
    this.setState({
      originalOffset: this.state.left,
      touchStartX: clientX,
      beingTouched: true,
    });
  }
  handleEnd() {
    this.setState({
      touchStartX: 0,
      beingTouched: false,
    });
  }
  changeDay(activeDay) {
    this.setState({
      activeDay,
    });
  }

  changeWeek(activeWeek) {
    this.setState({
      activeWeek,
      activeDay: '-1',
    });
  }

  render() {
    const { activeWeek, endWeek } = this.setupRange();
    let sessions = [];
    let agenda = [];
    if (this.state.activeDay !== '-1') {
      const activeDay =
        this.props.sessions.calendar.weeks[`week_${this.state.activeWeek}`][
          this.state.activeDay
        ];
      sessions = [...activeDay.sessions];
      agenda = sessions
        .filter((session) => session.users.includes(this.props.user.id))
        .map((ses) => ses.id.toString());
    }

    if (this.props.mode === 'agenda' && sessions.length > 0) {
      sessions = sessions.filter((s) => agenda.includes(s.id.toString()));
    }

    return (
      <div className="agenda">
        <div className="select-week">
          <div className="navigation" />
          <span>{window.lang[this.props.language]['day']}</span>
        </div>
        <div className="mini-calendar">
          <div className="arrow-card-wrapper">
            <div
              className={`arrow-card ${
                this.state.activeWeek === '1' && 'disabled'
              } `}>
              <span
                className={`icon-arrow-left`}
                onClick={() => {
                  if (this.state.activeWeek == '1') {
                    null;
                  } else {
                    this.changeWeek(parseInt(this.state.activeWeek) - 1);
                  }
                }}
              />
            </div>
          </div>

          {activeWeek.map((day, index) => {
            const dayMoment = moment(day.moment);
            let className = `day-wrapper${
              index === parseInt(this.state.activeDay) ||
              this.state.selectedDay === dayMoment.format('YYYY-MM-DD 00:00:00')
                ? ' active'
                : ''
            }`;

            if (
              dayMoment.isBefore(moment(this.props.event.startDate.tz)) ||
              dayMoment.isAfter(moment(this.props.event.endDate.tz))
            ) {
              className += ' disabled';
            }
            if (day.disabled) {
              className += ' disabled';
            }
            return (
              <div
                key={`day-${index}`}
                className={className}
                onTouchStart={(touchStartEvent) =>
                  this.handleStart(touchStartEvent.targetTouches[0].clientX)
                }
                onTouchMove={(touchMoveEvent) =>
                  this.handleMove(
                    touchMoveEvent.targetTouches[0].clientX,
                    endWeek
                  )
                }
                onTouchEnd={() => this.handleEnd()}
                onClick={() => {
                  if (
                    parseInt(this.state.activeDay) === index ||
                    dayMoment.format('YYYY-MM-DD 00:00:00') ===
                      this.state.selectedDay
                  ) {
                    this.setState({ selectedDay: '', activeDay: '-1' }, () =>
                      this.props.setDaySelected(false)
                    );
                    this.props.loadSessions();
                  } else {
                    this.setState({
                      selectedDay: dayMoment.format('YYYY-MM-DD 00:00:00'),
                    });
                    const from = dayMoment.format('YYYY-MM-DD 00:00:00');
                    let nextDay = parseInt(dayMoment.format('D')) + 1;
                    if (nextDay < 10) {
                      nextDay = '0' + nextDay;
                    }
                    const to = dayMoment.format(`YYYY-MM-${nextDay} 00:00:00`);
                    this.props.setDaySelected();

                    this.props.loadSessions({
                      dateRange: JSON.stringify({ from, to }),
                    });
                  }
                }}>
                <div className="date-container">
                  <div className="date" onClick={() => this.changeDay(index)}>
                    <p className="bottom">{dayMoment.format('ddd')}</p>
                    <p className="top">{dayMoment.format('D')}</p>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="arrow-card-wrapper">
            <div
              className={`arrow-card ${
                this.state.activeWeek == endWeek && 'disabled'
              } `}>
              <span
                className={`icon-arrow-right`}
                onClick={() => {
                  if (this.state.activeWeek == endWeek) {
                    null;
                  } else {
                    this.changeWeek(parseInt(this.state.activeWeek) + 1);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Agenda.propTypes = {
  event: PropTypes.object.isRequired,
  sessions: PropTypes.object.isRequired,
  mode: PropTypes.string,
  agenda: PropTypes.array.isRequired,
  loadSessions: PropTypes.func.isRequired,
  user: PropTypes.object,
  language: PropTypes.string,
  clearStatus: PropTypes.bool,
  setDaySelected: PropTypes.func,
};

export default Agenda;
