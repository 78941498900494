import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect, useDispatch } from 'react-redux';
import {
  changeSection,
  setHasRequestedChangeSection,
  setHasUnsavedChanges,
} from '../../actions';
import { en } from '../../config/languages';

Modal.setAppElement('body');

const ConfirmationModal = ({
  onDiscard,
  setHasUnsavedChanges,
  hasUnsavedChanges,
  hasRequestedChangeSection,
  setHasRequestedChangeSection,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // toDo: check this useEffect if it's the reason for chrome prevent reloading with a native popup modal.
    // the prevent action is happening only on staging environment.
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleDiscard = () => {
    onDiscard();
    dispatch(setHasUnsavedChanges(false));
    dispatch(setHasRequestedChangeSection(false));

    if (hasRequestedChangeSection) {
      dispatch(changeSection(hasRequestedChangeSection));
    }
  };

  const handleCancel = () => {
    dispatch(setHasRequestedChangeSection(false));
  };

  if (!hasUnsavedChanges) {
    return null;
  }

  return (
    <div className="confirmation-modal">
      <Modal
        className={'confirmation-modal'}
        overlayClassName="Overlay"
        isOpen={hasUnsavedChanges && hasRequestedChangeSection}>
        <div className="modal-content">
          <Typography variant="h5" gutterBottom>
            {en.unsavedChangesMessage}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <span
              dangerouslySetInnerHTML={{
                __html: en.unsavedChangesBody,
              }}></span>
          </Typography>
          <div className="button-container">
            <button className={'stay-button'} onClick={handleCancel}>
              {en.stayPlaceholder}
            </button>
            <button className={'btn btn-red'} onClick={handleDiscard}>
              {en.discardAndProceedPlaceholder}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ConfirmationModal.propTypes = {
  onDiscard: PropTypes.func.isRequired,
  setHasUnsavedChanges: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  hasRequestedChangeSection: PropTypes.bool.isRequired,
  setHasRequestedChangeSection: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state) => {
      return {
        hasUnsavedChanges: state.api.hasUnsavedChanges,
        hasRequestedChangeSection: state.api.hasRequestedChangeSection,
      };
    },
    {
      setHasUnsavedChanges,
      setHasRequestedChangeSection,
    }
  )(ConfirmationModal)
);
