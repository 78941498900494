import React from 'react';
import SingleProduct from '../SingleProduct';
import PropTypes from 'prop-types';

export default function MyProducts({
  products,
  language,
  color,
  currency,
  setProductPrices,
  onAction,
  onCancel,
}) {
  const productCards = [];

  products.map((price) => {
    // remove the id from price.name. the id is the first part of the name before the first space
    let priceName = price.name.split(' ');
    priceName.shift();
    priceName = priceName.join(' ');
    productCards.push(
      <SingleProduct
        key={price.id}
        name={`${priceName}`}
        description={''}
        language={language}
        setProductPrices={setProductPrices}
        color={color}
        isMyProducts={true}
        price={price.totalPrice}
        isConfirmed={price.dueAmount <= 0}
        currency={currency}
        onAction={onAction}
        onCancel={onCancel(price.eventProductId, price.id)}
      />
    );
  });

  return (
    <div className="all-products-container">
      {productCards.length === 0 && (
        <div className="empty-results">
          <span className="icon-warning-2" />

          <p>{window.lang[language]['noMyProducts']}</p>
        </div>
      )}
      {productCards}
    </div>
  );
}

MyProducts.propTypes = {
  products: PropTypes.array,
  language: PropTypes.string,
  color: PropTypes.string,
  currency: PropTypes.string,
  setProductPrices: PropTypes.func,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
};
