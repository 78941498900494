import React from 'react';
import Table from '@components/layout/Table';
import MobileTable from '@components/layout/MobileTable';
import PropTypes from 'prop-types';
import { saveByteArray } from '@helpers';
import { CircularProgress } from '@material-ui/core';

export default class History extends React.Component {
  constructor(props) {
    super(props);
    this.getReceipt = this.getReceipt.bind(this);
    this.state = {
      loadingReceiptId: null,
    };
    this.columns = {
      type: {
        name: window.lang[this.props.language]['type'],
      },
      transactionId: {
        name: window.lang[this.props.language]['transactionId'],
      },
      way: {
        name: window.lang[this.props.language]['way'],
      },
      paidAmount: {
        name: window.lang[this.props.language]['amount'],
      },
      status: {
        name: window.lang[this.props.language]['status'],
      },
      receipt: {
        name: '',
      },
    };
  }

  getReceipt(id) {
    this.setState({ loadingReceiptId: id });
    this.props
      .getReceipt(this.props.user.eventId, id)
      .then((buffer) => {
        const reportName = `receipt_${id ? id : 'ref' + null}.pdf`;
        saveByteArray(reportName, buffer);
        this.setState({ loadingReceiptId: null });
      })
      .catch((e) => {
        console.warn(e);
        this.setState({ loadingReceiptId: null });
      });
  }

  onRender(col, item) {
    let way = '';
    switch (col.key) {
      case 'status':
        return (
          <span
            className={
              item.status == 'Pending' ? 'color-warning' : 'color-success'
            }>
            {
              window.lang[this.props.language][
                item.sumPaid >= item.amount ? 'paid' : 'Pending'
              ]
            }
          </span>
        );
      case 'paidAmount':
        return (
          <span>
            {`${parseFloat(item.amount).toFixed(2)} ${this.props.currency}`}
          </span>
        );
      case 'way':
        if (item.way === 'pos') way = 'POS';
        if (item.way === 'cash') way = 'Cash';
        if (item.way === 'bank') way = 'Bank';
        if (item.way === 'card') way = 'Card';
        if (item.way === 'paypal') way = 'PayPal';
        if (item.way === 'discount') way = 'Discount';
        return <span>{way}</span>;
      case 'receipt':
        if (item.way === 'card' || item.way === 'paypal') {
          return this.state.loadingReceiptId === item.id ? (
            <CircularProgress
              size={28}
              style={{ display: 'block', margin: '0 auto' }}
            />
          ) : (
            <p className="receipt" onClick={() => this.getReceipt(item.id)}>
              {window.lang[this.props.language]['receipt']}
            </p>
          );
        }
    }
  }
  render() {
    const { payments } = this.props;
    return (
      <div className="payments-info">
        {payments?.length > 0 ? (
          <React.Fragment>
            <Table
              id={'payments'}
              columns={this.columns}
              items={payments}
              onRender={(col, item) => this.onRender(col, item)}
            />
            <div className="mobile-table">
              <MobileTable
                columns={this.columns}
                items={payments}
                onRender={(col, item) => this.onRender(col, item)}
              />
            </div>
          </React.Fragment>
        ) : (
          <div className="empty-results payments-table">
            <span className="icon-warning-2" />
            <p>{window.lang[this.props.language]['noPaymentsHistory']}</p>
          </div>
        )}
      </div>
    );
  }
}

History.propTypes = {
  payments: PropTypes.array,
  language: PropTypes.string,
  currency: PropTypes.string,
  user: PropTypes.object,
  getReceipt: PropTypes.func,
  validateToken: PropTypes.func,
};
