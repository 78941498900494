import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NavigateModal from '../../../../../layout/NavigateModal';

const FooterInfo = (props) => {
  const [modalIsOpen, setModalStatus] = useState(false);
  const [linkToNavigateTo, setLink] = useState();
  const { event, active } = props;
  const clientPanelSponsorSettings = React.useRef({});

  const footerItems = event.clientPanelSettings.footer.sponsorsSchema.sort(
    function (a, b) {
      return a.index > b.index ? 1 : -1;
    }
  );

  const navigateOutOfSiteHandler = () => {
    setModalStatus(!modalIsOpen);
  };

  const setSiteToNavigateTo = (link) => {
    if (link.includes('bluetree')) {
      window.open(link);
    } else {
      setLink(link);
      navigateOutOfSiteHandler();
    }
  };

  React.useEffect(() => {
    try {
      clientPanelSponsorSettings.current = JSON.parse(
        event.clientPanelSponsorSettings
      );
    } catch (e) {
      clientPanelSponsorSettings.current = {};
    }
  });

  const primaryColor =
    event &&
    event.clientPanelSettings &&
    event.clientPanelSettings.general.colors.primary;

  return (
    <div
      style={{
        backgroundColor: active === 'generalInfo' ? 'white' : '#f6f8fc',
        width: '100%',
      }}>
      <div className="row footer-section">
        <div
          className="footer-color"
          style={{
            backgroundColor: 'var(--primary-600)',
          }}
        />
        <div className="col-sm-12 footer-container">
          <div className="footer-event-info">
            {footerItems.map((item) => {
              return (
                <div className="footer-flex-item" key={item.id}>
                  <div>
                    <h2 className="footer-title">{item.title}</h2>

                    {item.clientFooterSponsorImage[0] && (
                      <img
                        className={`logo-item ${
                          event.clientPanelSettings.footer.roundBorders
                            ? 'round-image'
                            : ''
                        }`}
                        src={item.clientFooterSponsorImage[0].url}
                        onClick={() =>
                          item.url && setSiteToNavigateTo(item.url)
                        }
                      />
                    )}
                  </div>

                  {item.description && (
                    <p className="footer-subtext">{item.description}</p>
                  )}
                </div>
              );
            })}
          </div>
          {modalIsOpen && (
            <NavigateModal
              changeModalStatus={navigateOutOfSiteHandler}
              linkToNavigateTo={linkToNavigateTo}
              language={props.language}
              color={primaryColor}
            />
          )}
        </div>
        <div className="col-sm-12">
          <h2 className="best-company">
            BlueTree {new Date().getFullYear()} @ Privacy Policy
          </h2>
        </div>
      </div>
    </div>
  );
};
FooterInfo.propTypes = {
  event: PropTypes.object.isRequired,
  active: PropTypes.string.isRequired,
  language: PropTypes.string,
};

export default FooterInfo;
