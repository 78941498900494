import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

export default class ReactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: this.props.modalIsOpen,
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.modalIsOpen !== this.state.modalIsOpen) {
      this.setState({ modalIsOpen: nextProps.modalIsOpen });
    }
  }
  afterOpenModal() {
    if (this.props.afterOpenModal) {
      this.props.afterOpenModal();
    }
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.props.closeModal();
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={() =>
            this.props.noClose ? () => {} : this.closeModal()
          }
          contentLabel="Example Modal"
          className="Modal"
          overlayClassName="Overlay">
          {this.props.inner}
        </Modal>
      </div>
    );
  }
}

ReactModal.propTypes = {
  afterOpenModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  noClose: PropTypes.bool,
  inner: PropTypes.array.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
};
