import React from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { logout } from '@actions';

const safeDocument = typeof document !== 'undefined' ? document : {};

const Logoutter = (props) => {
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue('padding-right')
      ) || 0;

    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;
  };

  React.useEffect(() => {
    blockScroll();
    return () => {
      allowScroll();
    };
  }, []);

  return (
    <div className="logoutter">
      <div className="logoutter-prompt">
        <div className="warning-container">
          <img
            className={`warning-icon`}
            src={`${process.env.PUBLIC_URL}/danger_icon.svg`}
          />
        </div>
        <div className="text-container">
          <span>{window.lang[props.language]['concurrentLogin1']}</span>
          <span>{window.lang[props.language]['concurrentLogin2']}</span>
        </div>
        <div className="button-container">
          <Button
            onClick={() => {
              props.logout(true);
            }}
            className="logoutter-button"
            variant="contained">
            {window.lang[props.language]['ok']}
          </Button>
        </div>
      </div>
    </div>
  );
};

Logoutter.propTypes = {
  visible: PropTypes.bool,
  language: PropTypes.string,
  escapeable: PropTypes.bool,
  logout: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logoutter);
