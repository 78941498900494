import React from 'react';
import PropTypes from 'prop-types';

const ResetSuccessful = ({ color1, color2, color3 }) => {
  return (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 33C5 17.536 17.536 5 33 5C48.464 5 61 17.536 61 33C61 48.464 48.464 61 33 61C17.536 61 5 48.464 5 33Z"
        fill={color1}
      />
      <path
        d="M44.6673 31.9281V33.0015C44.6659 35.5173 43.8512 37.9653 42.3449 39.9803C40.8385 41.9953 38.7212 43.4694 36.3086 44.1827C33.896 44.896 31.3175 44.8104 28.9575 43.9385C26.5976 43.0666 24.5827 41.4553 23.2134 39.3447C21.8441 37.2342 21.1937 34.7375 21.3593 32.2272C21.5248 29.7168 22.4974 27.3272 24.132 25.4147C25.7666 23.5023 27.9756 22.1694 30.4295 21.615C32.8835 21.0605 35.451 21.3142 37.749 22.3381M44.6673 23.6681L33.0007 35.3465L29.5007 31.8465"
        stroke={color2}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 56C20.2975 56 10 45.7025 10 33H0C0 51.2254 14.7746 66 33 66V56ZM56 33C56 45.7025 45.7025 56 33 56V66C51.2254 66 66 51.2254 66 33H56ZM33 10C45.7025 10 56 20.2975 56 33H66C66 14.7746 51.2254 0 33 0V10ZM33 0C14.7746 0 0 14.7746 0 33H10C10 20.2975 20.2975 10 33 10V0Z"
        fill={color3}
      />
    </svg>
  );
};

ResetSuccessful.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
};

export default ResetSuccessful;
