import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Message from '@components/layout/Message';
import IconButton from '@material-ui/core/IconButton';
import { createCustomEmail, addNotification } from '@actions';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';

const AbstractCard = (props) => {
  const [mailOpen, setMailOpen] = React.useState(false);

  const onCloseModal = () => {
    setMailOpen(false);
  };

  return (
    <React.Fragment>
      <div className="abstract-authors">
        <div className="abstract-authors-title">
          {lang[props.language]['authors']}
        </div>
        <div className="abstract-authors-list">
          {props.abstract?.authors.map((author, i) => (
            <div
              className={`author-entry ${
                i === 0 && props.abstract?.authors[0]?.email
                  ? 'corresponding-author'
                  : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (i === 0 && author.email?.length > 0) {
                  setMailOpen(!mailOpen);
                }
              }}
              key={author.id}>
              {i === 0 && author.email?.length > 0 && (
                <div className="author-speech-bubble">
                  <IconButton>
                    <MailOutlineOutlinedIcon
                      className="speech-bubble-icon"
                      color={'var(--gray-300'}
                    />
                  </IconButton>
                </div>
              )}
              <div className="author-name">{`${author.firstName} ${
                author.lastName
              }${i === 0 ? '*' : ''}`}</div>
              {author.institutes?.length > 0 && (
                <div className="author-institute-index">{`
                  [${author.institutes
                    .map(
                      (institute_ID) =>
                        props.abstract?.institutes.findIndex(
                          (institute) => institute.id === institute_ID
                        ) + 1
                    )
                    .join(', ')}]
                      `}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="abstract-institutes">
        {props.abstract?.institutes.map((institute, i) => (
          <div className={`institute-entry`} key={institute.id}>
            {`${i + 1}. ${institute.name} `}
          </div>
        ))}
      </div>
      <div className="correspondance-note">
        *{lang[props.language]['correspondingAuthorText']}
      </div>

      {props.abstract?.authors[0]?.email && (
        <React.Fragment>
          <Message
            position={'fixed'}
            color="var(--primary-600)"
            event={props.event}
            language={props.language}
            userMail={props.user.info.email}
            supportEmail={props.abstract.authors[0].email}
            createCustomEmail={props.createCustomEmail}
            addNotification={props.addNotification}
            onClose={onCloseModal}
            openState={mailOpen}
            externalControl={true}
            subNoteText={`*${lang[props.language]['mailDuplicateText']}`}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

AbstractCard.propTypes = {
  createCustomEmail: PropTypes.func,
  addNotification: PropTypes.func,
  language: PropTypes.string,
  event: PropTypes.object,
  user: PropTypes.object,
  abstract: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    event: state.api.event.data,
    user: state.api.user.data,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createCustomEmail: (eventId, data) =>
    dispatch(createCustomEmail(eventId, data)),
  addNotification: (msg, code) => dispatch(addNotification(msg, code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbstractCard);
