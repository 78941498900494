import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSubscriptions } from '@actions';
import moment from 'moment';

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
      eventPeriods: {},
      activePeriod: '',
    };
    this.normalizeSubscriptions = this.normalizeSubscriptions.bind(this);
  }
  normalizeSubscriptions(data) {
    const { event } = this.props;
    return data.map(
      ({ id, typeName, periodName, startDate, endDate, price }) => ({
        id,
        typeName,
        periodName,
        startDate: moment(startDate.tz).format('DD-MM-Y HH:mm'),
        endDate: moment(endDate.tz).format('DD-MM-Y HH:mm'),
        price: `${price} ${event.currencySymbol}`,
        timezone: startDate.timezone,
      })
    );
  }
  createEventPeriods(subscriptions) {
    return subscriptions.reduce((acc, sub) => {
      if (!acc[sub.periodName]) {
        acc[sub.periodName] = {
          periodStart: sub.startDate,
          periodEnd: sub.endDate,
          prices: [sub],
        };
        return acc;
      }

      acc[sub.periodName] = {
        ...acc[sub.periodName],
        prices: [...acc[sub.periodName].prices, sub],
      };

      return acc;
    }, {});
  }

  getActivePeriod(eventPeriods) {
    return (
      Object.keys(eventPeriods).find((period) => {
        const periodStart = moment(
          eventPeriods[period].periodStart,
          'DD-MM-YYYY HH:mm'
        );
        const periodEnd = moment(
          eventPeriods[period].periodEnd,
          'DD-MM-YYYY HH:mm'
        );

        return moment.utc().isBetween(periodStart, periodEnd);
      }) || Object.keys(eventPeriods)[Object.keys(eventPeriods) - 1]
    );
  }
  componentDidMount() {
    const { event } = this.props;

    this.props.getSubscriptions(event.id).then(({ data }) => {
      const subscriptions = this.normalizeSubscriptions(data);
      const eventPeriods = this.createEventPeriods(subscriptions);
      const activePeriod = this.getActivePeriod(eventPeriods);

      this.setState({
        subscriptions,
        eventPeriods,
        activePeriod,
      });
    });
  }
  render() {
    const { subscriptions, eventPeriods, activePeriod } = this.state;
    const activePeriodBasedOnTime = this.getActivePeriod(eventPeriods);
    const selectedPeriodIsActive = activePeriod === activePeriodBasedOnTime;
    return (
      <React.Fragment>
        <div className="col-sm-12">
          <p className="section-title price-title">Pricing</p>
          <div className="period-container">
            {Object.keys(eventPeriods).map((periodName) => {
              return (
                <div
                  key={periodName}
                  className={`period-name ${
                    periodName === this.state.activePeriod ? 'active' : ''
                  }`}
                  onClick={() => this.setState({ activePeriod: periodName })}>
                  {periodName}
                </div>
              );
            })}
          </div>
        </div>

        <div
          className="card-container pricing"
          style={
            eventPeriods[activePeriod] &&
            Object.keys(eventPeriods[activePeriod]).length > 3
              ? null
              : { justifyContent: 'center' }
          }>
          {subscriptions &&
            eventPeriods[activePeriod] &&
            eventPeriods[activePeriod].prices.map((price, i) => {
              return (
                <div className="pricing-card-wrapper" key={i}>
                  <div
                    className={`col-sm-3 pricing-card ${
                      selectedPeriodIsActive ? '' : 'disabled'
                    }`}
                    key={price.id}>
                    <h2 className="price-group">{price.typeName}</h2>
                    <h2 className="price">{price.price}</h2>
                    <button
                      className={`availability-button ${
                        selectedPeriodIsActive ? '' : 'disabled'
                      }`}>
                      {selectedPeriodIsActive ? 'Available' : 'Unavailable'}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}
Pricing.propTypes = {
  event: PropTypes.object.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.api.user.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: (eventId) => dispatch(getSubscriptions(eventId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
