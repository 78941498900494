import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const SplashHeader = (props) => {
  return (
    <div className="splash-header">
      <div className="splash-header-wrapper">
        <div className="event-info">
          <span className="event-logo-wrapper">
            {props.policy?.clientPanelSettings?.header?.banner[0]?.url?.length >
            0 ? (
              <img
                src={props.policy?.clientPanelSettings?.header?.banner[0]?.url}
                className="event-logo"
              />
            ) : null}
          </span>
          <div className="event-name">{`${props.policy.eventName}`}</div>
        </div>
        <Button
          style={{
            textTransform: 'none',
            backgroundColor: window.colorPalette.primary[600],
            color: 'white',
          }}
          onClick={() => window.open(`mailto:${props.policy.supportEmail}`)}
          disableElevation
          className="contact-button"
          variant="contained">
          {window.lang[props.language].contactText}
        </Button>
      </div>
    </div>
  );
};

SplashHeader.propTypes = {
  policy: PropTypes.object,
  language: PropTypes.string,
};

export default SplashHeader;
