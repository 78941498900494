import React from 'react';
import PropTypes from 'prop-types';
import Table from '@components/layout/Table';
import MobileTable from '@components/layout/MobileTable';
import Loader from '@components/layout/Loader';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelRegistrationModal from '@components/layout/CancelRegistrationModal';

const queryString = require('query-string');

export default class Products extends React.Component {
  constructor(props) {
    super(props);
    this.query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      products: [],
      totalCost: 0,
      moveToPayments: true,
      cancelConfirmation: false,
      checkout_disabled: false,
    };
    this.productColumns = {
      id: {
        name: window.lang[this.props.language]['id'],
      },
      product: {
        name: window.lang[this.props.language]['product'],
      },
      price: {
        name: window.lang[this.props.language]['pricePerItem'],
      },
      quantity: {
        name: window.lang[this.props.language]['quantity'],
      },
      totalPrice: {
        name: window.lang[this.props.language]['totalPrice'],
      },
    };
    this.addRemoveProduct = this.addRemoveProduct.bind(this);
    this.onRenderProducts = this.onRenderProducts.bind(this);
  }

  componentDidMount() {
    const { mandatoryPayment } = this.props.policy;
    const userIsSponsored = this.props.fields.sponsored === '1';

    if (
      this.props.products.length == 0 || //products dont exist
      mandatoryPayment == 0 ||
      userIsSponsored
    ) {
      this.props.setNoProducts();
      this.props.handleNext();
    } else {
      const checkout_disabled =
        this.overbookedExists(this.props.products) ||
        this.soldOutExists(this.props.products);
      const updatedProducts = this.props.products?.map((product) => {
        product['totalPrice'] = this.calculateIndividualProductPrice(product);
        return product;
      });
      this.setState({ checkout_disabled, products: updatedProducts });
    }
  }

  calculateIndividualProductPrice(updatedProduct) {
    const totalPrice =
      updatedProduct.quantity * parseFloat(updatedProduct.price.split(' ')[0]);
    return parseFloat(totalPrice).toFixed(2) + this.props.currency;
  }

  calculateTotalProductCost() {
    let totalCost = 0;
    this.state.products.map((product) => {
      totalCost = totalCost + parseFloat(product['totalPrice'].split(' ')[0]);
    });
    return parseFloat(totalCost).toFixed(2);
  }
  calculateTotalCost() {
    return (
      Number(this.calculateTotalProductCost()) +
      this.props.subscriptionInfo?.eventSubscriptionPrice
    ).toFixed(2);
  }
  addRemoveProduct(item, type) {
    const newProducts = [];
    let checkout_disabled = this.state.checkout_disabled;

    this.state.products.map((product) => {
      const updatedProduct = product;
      if (product.id === item.id) {
        if (type === 'add' && product.maxQuantity > product.quantity) {
          updatedProduct['quantity'] = product.quantity + 1;
        } else if (type === 'remove' && product.quantity > 0) {
          updatedProduct['quantity'] = product.quantity - 1;
        }
        updatedProduct['totalPrice'] =
          this.calculateIndividualProductPrice(updatedProduct);
      }
      newProducts.push(updatedProduct);
    });

    if (type === 'remove')
      checkout_disabled =
        this.overbookedExists(newProducts) || this.soldOutExists(newProducts);

    this.setState({ checkout_disabled, products: newProducts });
    this.props.setProducts(newProducts); //Set products so they can be used in Payment
  }

  overbookedExists(newProducts) {
    return newProducts.some((item) => {
      const totalQuantity = newProducts.reduce((total, product) => {
        if (product.productId !== item.productId) return total;
        return total + product.quantity;
      }, 0);

      const overBookedItem =
        item.maxQuantity >= 0 &&
        (item.quantity > item.maxQuantity || totalQuantity > item.maxQuantity);
      if (overBookedItem) return true;
      return false;
    });
  }

  soldOutExists(newProducts) {
    return newProducts.some((item) => {
      const totalQuantity = newProducts.reduce((total, product) => {
        if (product.productId !== item.productId) return total;
        return total + product.quantity;
      }, 0);

      if (item.maxQuantity === 0 && totalQuantity > 0) return true;
      return false;
    });
  }

  onRenderProducts(col, item) {
    const disableQuantity =
      item.quantity === 0 && item.maxQuantity <= item.quantity;
    let totalQuantity = 0;
    this.props.products.forEach((product) => {
      if (item.productId === product.productId) {
        totalQuantity += product.quantity;
      }
    });

    const disabledAddMore =
      item.quantity === item.maxQuantity || totalQuantity >= item.maxQuantity;

    const productIsOverbooked =
      item.maxQuantity >= 0 &&
      (item.quantity > item.maxQuantity || totalQuantity > item.maxQuantity);

    const productIsSoldout = item.availableQuantity <= 0;

    switch (col.key) {
      case 'id': {
        return (
          <div
            className="id-wrapper"
            style={{
              verticalAlign: 'top',
            }}>
            {item.id}
          </div>
        );
      }
      case 'product': {
        return (
          <div className="product-info-wrapper">
            <div className="name-wrapper">{item.name}</div>

            <Tooltip
              disableFocusListener
              leaveDelay={0}
              title={`${item.description}`}>
              <div className="description-wrapper">{item.description}</div>
            </Tooltip>

            <div className="payments-wrapper">
              {item.availablePaymentMethodsKeys.map((item) => {
                if (item === 'bank') {
                  return (
                    <img
                      className={`payment-icon payment-icon-bank`}
                      src={`${process.env.PUBLIC_URL}/ecommerce/bankPayment.svg`}
                    />
                  );
                } else if (item === 'card') {
                  return (
                    <React.Fragment>
                      {this.props.policy.billing.creditCard === 1 ? (
                        <img
                          className={`payment-icon payment-icon-card`}
                          src={`${process.env.PUBLIC_URL}/ecommerce/cardPayment.svg`}
                        />
                      ) : null}
                      {this.props.policy.billing.paypal === 1 ? (
                        <img
                          className={`payment-icon payment-icon-paypal`}
                          src={`${process.env.PUBLIC_URL}/ecommerce/paypalPayment.svg`}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                }
              })}
            </div>
            {productIsSoldout && (
              <div className="product-error">
                {window.lang[this.props.language]['itemSoldOut']}
              </div>
            )}
            {!productIsSoldout && productIsOverbooked && (
              <div className="product-error">
                {window.lang[this.props.language]['itemOverbooked']}
              </div>
            )}
          </div>
        );
      }
      case 'price': {
        return <div className="price-wrapper">{item.price}</div>;
      }
      case 'quantity': {
        return (
          <React.Fragment>
            <div
              className="add-remove"
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <IconButton
                className="quantity-icon-button"
                aria-label="add-Quantity"
                disabled={item.quantity === 0}
                onClick={() => {
                  this.addRemoveProduct(item, 'remove');
                }}>
                <RemoveCircleOutlineIcon
                  style={{
                    color:
                      item.quantity === 0
                        ? 'var(--gray-100)'
                        : window.colorPalette.primary[400],
                  }}
                  className={`remove-icon ${
                    item.quantity === 0 ? 'disabled' : ''
                  } quantity-icon`}
                />
              </IconButton>
              <span
                className={` quantity-number ${
                  disableQuantity ? 'disabled' : ''
                }`}>
                {item.quantity}
              </span>
              <IconButton
                className="quantity-icon-button"
                aria-label="add-Quantity"
                disabled={disabledAddMore}
                onClick={() => {
                  this.addRemoveProduct(item, 'add');
                }}>
                <AddCircleOutlineIcon
                  style={{
                    color: disabledAddMore
                      ? 'var(--gray-100)'
                      : window.colorPalette.primary[400],
                  }}
                  className={`add-icon ${
                    disabledAddMore ? 'disabled' : ''
                  } quantity-icon`}
                />
              </IconButton>
            </div>
            {item.maxQuantity <= 0 ? (
              <div className="not-available-message">
                {window.lang[this.props.language]['itemNotAvailable']}
              </div>
            ) : null}
          </React.Fragment>
        );
      }
      case 'totalPrice': {
        return (
          <div className="total-price-wrapper">
            {Number.parseFloat(item.totalPrice) === 0
              ? '0.00€'
              : item.totalPrice}
          </div>
        );
      }
      case 'action':
        return (
          <input
            className="product-input"
            type="checkbox"
            id={`payments-checkbox-${item.type}`}
            disabled={item.disabled}
          />
        );
    }
  }

  getCostSum(sizeMode) {
    return (
      <div className="cost-sum-wrapper">
        <div className={`cost-sum cost-sum-${sizeMode}`}>
          <div className="cost-wrapper">
            <div className="cost-titles">
              {sizeMode === 'desktop' ? (
                <div className="price-header">
                  {`${
                    window.lang[this.props.language]['additionalProductsCost']
                  }:`}
                </div>
              ) : null}
              {/* <div className="price-header">
                {`${window.lang[this.props.language]["registrationCost"]}:`}
              </div> */}
              <div className="price-header total">
                {`${window.lang[this.props.language]['totalCost']}:`}
              </div>
              <div className="price-header tax-note">
                {`${window.lang[this.props.language]['taxesIncluded']}`}
              </div>
            </div>
            <div className="cost-numbers">
              {sizeMode === 'desktop' ? (
                <div className="price-display">
                  {`${this.calculateTotalProductCost()}${this.props.currency}`}
                </div>
              ) : null}
              <div className="price-display total">
                {`${
                  isFinite(this.calculateTotalCost())
                    ? this.calculateTotalCost()
                    : 0
                }${this.props.currency}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="product-table">
        {this.state.products.length === 0 ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="additional-products-text">
              {window.lang[this.props.language]['additionalProducts']}
            </div>
            <React.Fragment>
              {this.state.products.length > 0 ? (
                <React.Fragment>
                  <div className="normal-table">
                    <Table
                      id={'pending-Payments'}
                      columns={this.productColumns}
                      items={this.state.products}
                      onRender={this.onRenderProducts}
                      tableClassName="products-table-signup"
                    />
                  </div>
                  <div className="mobile-table">
                    <div
                      className="mobile-table-background"
                      style={{
                        background: window.colorPalette.primary[25],
                      }}
                    />
                    <MobileTable
                      policy={this.props.policy}
                      columns={this.productColumns}
                      items={this.state.products}
                      onRender={this.onRenderProducts}
                      register={true}
                      onAddRemove={this.addRemoveProduct}
                      language={this.props.language}
                    />
                  </div>
                  {this.getCostSum('desktop')}
                </React.Fragment>
              ) : (
                <div
                  className="empty-results"
                  style={{
                    backgroundColor: 'white',
                    marginTop: '-10px',
                    padding: '80px 0',
                  }}>
                  <p>{window.lang[this.props.language]['noItemsFound']}</p>
                  <p>
                    {
                      window.lang[this.props.language][
                        'calculateRegistrationFeeMessage'
                      ]
                    }
                  </p>
                </div>
              )}
              <div className="btn-container product-btn-container">
                <div className="cost-sum-mobile">
                  <div className="cost-wrapper">
                    <div className="text-wrapper">
                      <div className="overall-price-title">
                        {`${window.lang[this.props.language]['totalCost']}:`}
                      </div>
                      <div className="taxes-message-mobile">
                        {`${window.lang[this.props.language]['taxesIncluded']}`}
                      </div>
                    </div>
                    <div className="overall-price">
                      {`${
                        isFinite(this.calculateTotalCost())
                          ? this.calculateTotalCost()
                          : 0
                      }${this.props.currency}`}
                    </div>
                  </div>
                </div>

                <div className="step-btn-row">
                  <button
                    onClick={() => this.props.handlePrev()}
                    className="reverse step-btn back-btn">
                    <div className="arrow-container btn-previous">
                      <ArrowBackIcon />
                    </div>
                    <div className="btn-txt-container">
                      <div className="btn-txt">
                        <span className="txt-mobile">
                          {window.lang[this.props.language].previous}
                        </span>
                        <span className="txt-1">
                          {window.lang[this.props.language].previousStep}:
                        </span>
                        <br />
                        <span className="txt-2">{this.props.prevStep}</span>
                      </div>
                    </div>
                  </button>
                  <button
                    className="step-btn row-reverse"
                    disabled={this.state.checkout_disabled}
                    style={{
                      backgroundColor: window.colorPalette.primary[600],
                    }}
                    onClick={() => {
                      this.props.handleNext();
                    }}>
                    <div className="btn-txt-container">
                      <div className="btn-txt">
                        <span className="txt-mobile">
                          {window.lang[this.props.language].next}
                        </span>
                        <span className="txt-1">
                          {window.lang[this.props.language].nextStep}:
                        </span>
                        <br />
                        <span className="txt-2">{this.props.nextStep}</span>
                      </div>
                    </div>
                    <div className="arrow-container btn-next">
                      <ArrowForwardIcon />
                    </div>
                  </button>
                </div>

                <div className="bottom-info">
                  <div
                    onClick={() => {
                      this.setState({ cancelConfirmation: true });
                    }}
                    style={{
                      color: window.colorPalette.primary[600],
                      textDecorationColor: window.colorPalette.primary[600],
                    }}
                    className={`cancel-registration`}>
                    {window.lang[this.props.language].cancelRegistration}
                  </div>
                </div>
              </div>
              {this.state.cancelConfirmation && (
                <CancelRegistrationModal
                  language={this.props.language}
                  setModalVisibility={() =>
                    this.setState({ cancelConfirmation: false })
                  }
                />
              )}
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    );
  }
}
Products.propTypes = {
  activeStep: PropTypes.string,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  language: PropTypes.string,
  user: PropTypes.object,
  products: PropTypes.array,
  currency: PropTypes.string,
  getProducts: PropTypes.func,
  setProducts: PropTypes.func,
  setTotalProductCost: PropTypes.func,
  subscriptionInfo: PropTypes.object,
  policy: PropTypes.object,
  registerUser: PropTypes.func,
  fields: PropTypes.object,
  orgCrmUserId: PropTypes.number,
  setNoProducts: PropTypes.func,
  knownUserFields: PropTypes.object,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  changeSection: PropTypes.func,
};
