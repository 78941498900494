import React from 'react';
import PropTypes from 'prop-types';
import { isObject, isArray } from '@helpers';

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.toggleSelect = this.toggleSelect.bind(this);
    this.getPrompt = this.getPrompt.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onInput = this.onInput.bind(this);
    this.handleBody = this.handleBody.bind(this);
    this.excludedArea = null;
    this.originalValue = props.value;

    this.state = {
      toggled: props.toggled,
      selections: props.value
        ? isArray(props.value)
          ? props.value
          : [props.value.toString()]
        : [],
      selectButton: 'select',
    };
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBody);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBody);
  }

  handleBody(e) {
    if (!this.excludedArea.contains(e.target)) {
      this.setState({
        toggled: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value === prevProps.value) return;

    const newSelections = this.props.value
      ? isArray(this.props.value)
        ? this.props.value.map((v) => v.toString())
        : [this.props.value.toString()]
      : [];

    this.setState({ selections: newSelections });
  }

  toggleSelect() {
    if (this.props.disabled) {
      return;
    }

    this.setState(
      (prevState) => {
        return {
          toggled: !prevState.toggled,
        };
      },
      () => {
        if (this.props.onToggle && this.state.toggled) {
          this.props.onToggle(this.excludedArea);
        }
      }
    );
  }

  onInput(e) {
    this.props.onInput(e);
  }

  onChange(key, event) {
    if (event && event.target.type) {
      return;
    }

    if (key === false || key === '') {
      this.setState({
        selections: [],
        toggled: false,
      });
      this.props.onChange(key);
      return;
    }

    if (!this.state.selections.includes(key)) {
      const newSelections = this.props.multi
        ? [...this.state.selections, key]
        : [key];

      this.setState(
        {
          selections: this.props.isDropDown ? [] : newSelections,
          toggled: false,
        },
        () => {
          this.props.multi
            ? this.props.onChange(newSelections)
            : this.props.onChange(key);
        }
      );
    } else {
      const updatedSelections = this.props.multi
        ? this.state.selections.filter((s) => s !== key)
        : this.state.selections;

      this.setState(
        {
          toggled: false,
          selections: updatedSelections,
        },
        () => {
          if (!this.props.multi) return;
          this.props.onChange(updatedSelections);
        }
      );
    }
  }

  getPrompt() {
    const { value, options, placeholder } = this.props;

    if (!value) return placeholder;
    if (!isArray(value)) return options[value];
    return value.map((v) => options[v]).join(', ');
  }

  render() {
    const className = `bottom-area${this.state.toggled ? '' : ' hidden'}`;

    return (
      <div
        className={`select-advanced multiselect ${
          this.props.className ? `${this.props.className}` : ''
        }`}
        ref={(ref) => (this.excludedArea = ref)}>
        <div className="select-checkboxes-outer">
          <div
            className="upper-area"
            onKeyPress={this.onInput}
            tabIndex="0"
            onClick={this.toggleSelect}>
            <span className="selected-content">{this.getPrompt()}</span>
            {this.props.hasIndicator && (
              <span
                className={`icon-arrow-${this.state.toggled ? 'up' : 'down'} `}
              />
            )}
          </div>
          {this.state.toggled && (
            <div className={className}>
              <div>
                {this.props.beforeRenderUl ? this.props.beforeRenderUl() : ''}
                <ul className="options-wrapper">
                  {this.props.placeholderInsideSelect && (
                    <li onClick={() => this.onChange('')}>
                      {this.props.placeholder}
                    </li>
                  )}
                  {Object.entries(this.props.options).map(([key, obj]) => {
                    let label = isObject(obj)
                      ? obj.label
                        ? obj.label
                        : obj
                      : obj;
                    const className =
                      !this.props.isDropDown &&
                      this.state.selections.includes(key)
                        ? { className: 'selected' }
                        : {};
                    if (this.props.onRender) {
                      const jsx = this.props.onRender({ key, obj });
                      if (jsx) {
                        // we have an override
                        label = jsx;
                      }
                    }
                    return (
                      <li
                        key={`li-${key}`}
                        {...className}
                        onClick={(e) => this.onChange(key, e)}>
                        {label}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.any,
  options: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]),
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  toggled: PropTypes.bool,
  forceClose: PropTypes.bool,
  multi: PropTypes.bool,
  hasIndicator: PropTypes.bool,
  placeholderInsideSelect: PropTypes.bool,
  isDropDown: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  beforeRenderUl: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  className: PropTypes.string,
  onRender: PropTypes.func,
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  placeholder: '',
  onChange: () => {},
  beforeRenderUl: false,
  selectAllButton: true,
  toggled: false,
  forceClose: false,
  multi: false,
  hasIndicator: true,
  placeholderInsideSelect: true,
  isDropDown: false,
  value: '',
  className: '',
  disabled: false,
  onInput: () => {},
};
