import { connect } from 'react-redux';
import HotelInner from './HotelInner';
import { changeSection, getHotels, getAvailableRooms } from '@actions';

const mapStateToProps = (state) => {
  const hotel = state.api.hotels.ready
    ? [...state.api.hotels.data].filter(
        (s) => s.id === parseInt(state.api.hotels.showingHotel.hotelId)
      )[0]
    : {};
  return {
    hotel,
    user: state.api.user.data,
    language: state.api.language.selected,
    hotelDates: state.api.hotels.meta?.dateRange,
    hotelGuests: state.api.hotels.meta?.guests,
    event: state.api.event.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSection: (section) => dispatch(changeSection(section)),
  getHotels: () => dispatch(getHotels()),
  getAvailableRooms: (eventHotelId, meta) =>
    dispatch(getAvailableRooms(eventHotelId, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelInner);
