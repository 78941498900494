import React from 'react';
import PropTypes from 'prop-types';

const Heading = (props) => {
  const { content } = props;
  return (
    <div className="content-info" style={{ height: '100%' }}>
      <div className="description layout-item">
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};
Heading.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Heading;
