import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

const Paginator = (props) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rpp, setRpp] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  const [rppSelectorOpen, setRppSelectorOpen] = React.useState(false);

  const views = [10, 20, 50, 100];

  React.useEffect(() => {
    if (!props.fetching) {
      setCurrentPage(props.meta.p);
      setRpp(props.meta.rpp);
      setTotal(props.meta.totalRows);
    }
  }, [props.meta]);

  const getItemStart = () => {
    const targetStart = 1 + currentPage * rpp - rpp;
    return isNaN(targetStart) ? 0 : targetStart;
  };

  const getItemEnd = () => {
    let targetEnd = currentPage * rpp;
    targetEnd = isNaN(targetEnd) ? 0 : targetEnd;
    return targetEnd > total ? total : targetEnd;
  };

  const navigateBack = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    props.onChange({
      p: currentPage - 1,
      rpp,
    });
  };
  const navigateNext = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    props.onChange({
      p: currentPage + 1,
      rpp,
    });
  };

  const setNewView = (newView, event) => {
    event.stopPropagation();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setRppSelectorOpen(false);
    props.onChange({
      p: 1,
      rpp: newView,
    });
  };

  return (
    <div className="paginator-wrapper">
      {rppSelectorOpen && (
        <div
          className="backdrop-focuser"
          onClick={() => setRppSelectorOpen(false)}
        />
      )}
      <IconButton
        className="mobile-back"
        onClick={navigateBack}
        disabled={getItemStart() <= 1 || !total}>
        <ArrowBackRoundedIcon />
      </IconButton>
      <div
        className={`page-indicator ${rppSelectorOpen ? 'open' : ''}`}
        onClick={() => setRppSelectorOpen(true)}>
        <div className="view-wrapper">{`${getItemStart() ?? 0}-${
          getItemEnd() ?? 0
        } ${lang[props.language]['of']} ${total ?? 0}`}</div>
        {rppSelectorOpen &&
          views.map((view, i) => (
            <div
              key={i}
              className={`view-wrapper in-menu ${
                view === rpp ? 'selected' : ''
              }`}
              onClick={(e) => setNewView(view, e)}>
              {`${view} ${lang[props.language]['perPage']}`}
            </div>
          ))}
      </div>
      <div className="page-navigation">
        <IconButton
          className="desktop-back"
          onClick={navigateBack}
          disabled={getItemStart() <= 1 || !total}>
          <NavigateBeforeRoundedIcon />
        </IconButton>
        <IconButton
          className="desktop-next"
          onClick={navigateNext}
          disabled={getItemEnd() === total || !total}>
          <NavigateNextRoundedIcon />
        </IconButton>
        <IconButton
          className="mobile-next"
          onClick={navigateNext}
          disabled={getItemEnd() === total || !total}>
          <ArrowForwardRoundedIcon />
        </IconButton>
      </div>
    </div>
  );
};

Paginator.propTypes = {
  meta: PropTypes.object,
  eventId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  language: PropTypes.string,
  searchPrompt: PropTypes.string,
  fetching: PropTypes.bool.isRequired,
  getOptions: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.event.data.id,
    language: state.api.language.selected,
  };
};

export default connect(mapStateToProps)(Paginator);
