import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  verifyEmail,
  changeSection,
  changeScreen,
  getReceipt,
  setCaptchaCheck,
  validateToken,
} from '@actions';
import { saveByteArray } from '@helpers';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Recaptcha from '@components/layout/Recaptcha';

const queryString = require('query-string');

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    this.onChange = this.onChange.bind(this);
    this.props.setCaptchaToken(null, 'register');

    this.state = { sentRequest: false, email: '', paymentRef: '' };
  }
  componentDidMount() {
    // Manually handling back button due to bug in
    // in freeaccess policies
    if (this.query.tk) {
      this.props
        .validateToken(this.query.tk)
        .then((d) => this.setState({ paymentRef: d.data.paymentRef }));
    }
    if (!this.props.policy.freeAccess) {
      window.onpopstate = () => {
        this.props.changeScreen('login');
      };
    }
  }

  onChange(e) {
    const email = e.target.value.trim();
    this.setState({ email });
  }

  getReceipt(id) {
    this.props
      .getReceipt(this.props.eventId, id, this.state.paymentRef, this.query.tk)
      .then((buffer) => {
        const reportName = `receipt_${
          id ? id : 'ref' + this.state.paymentRef
        }.pdf`;
        saveByteArray(reportName, buffer);
      })
      .catch((e) => console.error(e));
  }
  parseCooldownTime(cooldown) {
    let timeUnit = 'seconds';
    let finalCooldown = cooldown / 60;

    if (finalCooldown > 1) timeUnit = 'minutes';
    if (finalCooldown <= 1) finalCooldown = cooldown;
    else finalCooldown = Math.ceil(finalCooldown);

    return `${finalCooldown} ${timeUnit}`;
  }

  render() {
    if (this.query.status === 'CAPTURED') {
      return (
        <div>
          <div className="empty-results">
            <CheckCircleOutlineIcon
              style={{ width: '32px', height: '32px' }}
              className="transaction-success-icon"
            />
            <p className="transaction-success-message">
              {window.lang[this.props.language]['transactionCompleted']}
            </p>
            <span>
              <p className="trasnaction-success-info-1">
                {window.lang[this.props.language]['orderId']}:{' '}
                {this.state.orderId}
              </p>
              <p className="trasnaction-success-info-2">
                {window.lang[this.props.language]['paymentReference']}:{' '}
                {this.state.paymentRef}
              </p>
            </span>
            <div className="receipt-icon">
              <img
                className={`payment-icon payment-icon-bank`}
                src={`${process.env.PUBLIC_URL}/ecommerce/pdf.svg`}
              />
            </div>
            <p
              className="receipt"
              onClick={() => this.getReceipt(null)}
              style={{ fontSize: '13px', color: '#02A9F4' }}>
              {window.lang[this.props.language]['downloadReceipt']}
            </p>
            <button
              className="btn btn-primary"
              onClick={() =>
                window.location.replace(location.href.split('?')[0])
              }
              style={{ marginTop: '20px' }}>
              {window.lang[this.props.language]['goToLogin']}
            </button>
          </div>
        </div>
      );
    } else if (this.query.status === 'REFUSED') {
      return (
        <div>
          <div className="empty-results transaction-failed-container">
            <HighlightOffIcon
              style={{ width: '32px', height: '32px' }}
              className="transaction-failed-icon"
            />
            <div className="trasnaction-failed-message">
              <p>{window.lang[this.props.language]['transactionRefused']}</p>
            </div>
            <div className="trasnaction-failed-info">
              <div className="trasnaction-failed-info-1">
                <p>
                  {
                    window.lang[this.props.language][
                      'transactionRefusedMessage1'
                    ]
                  }
                </p>
              </div>
              <div className="trasnaction-failed-info-2">
                <p style={{ textAlign: 'left' }}>
                  {
                    window.lang[this.props.language][
                      'transactionRefusedMessage2'
                    ]
                  }
                </p>
              </div>
              <hr />
            </div>
            <button
              className="btn btn-primary"
              onClick={() =>
                window.location.replace(location.href.split('?')[0])
              }
              style={{ marginTop: '20px', width: 300, maxWidth: '100%' }}>
              {window.lang[this.props.language]['ok']}
            </button>
          </div>
        </div>
      );
    } else if (this.query.status) {
      return (
        <div>
          <div className="empty-results transaction-failed-container">
            <HighlightOffIcon
              style={{ width: '32px', height: '32px' }}
              className="transaction-failed-icon"
            />
            <div className="trasnaction-failed-message">
              <p>{window.lang[this.props.language]['transactionFailed']}</p>
            </div>
            <div className="trasnaction-failed-info">
              <div className="trasnaction-failed-info-1">
                <p>
                  {
                    window.lang[this.props.language][
                      'transactionFailedMessage1'
                    ]
                  }
                </p>
              </div>
              <div className="trasnaction-failed-info-2">
                <p style={{ textAlign: 'left' }}>
                  {
                    window.lang[this.props.language][
                      'transactionFailedMessage2'
                    ]
                  }
                </p>
              </div>
              <hr />
            </div>
            <button
              className="btn btn-primary"
              onClick={() =>
                window.location.replace(location.href.split('?')[0])
              }
              style={{ marginTop: '20px', width: 300, maxWidth: '100%' }}>
              {window.lang[this.props.language]['ok']}
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="row splash">
        <div
          className="right-side col-md-6 order-md-2"
          style={{ maxHeight: '100%' }}>
          <div className="vertical-center">
            <div className="presentational">
              <div className="presentational-inner">
                {this.props.policy.banners.length > 0 && (
                  <div className="event-banner vertical-center">
                    <img
                      className="blur"
                      src={this.props.policy.banners[0].url}
                      alt={this.props.policy.eventName}
                    />
                    <img
                      className="real"
                      src={this.props.policy.banners[0].url}
                      alt={this.props.policy.eventName}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="left-side col-md-6 order-md-1">
          <form
            className="form-container"
            onSubmit={(e) => {
              this.setState({ sentRequest: true });
              e.preventDefault();
              this.props
                .verifyEmail(this.state.email)
                .then(() => this.props.changeSection('login'))
                .catch(() => this.setState({ sentRequest: false }));
            }}>
            <div className="form-inner-wrapper">
              <div className="splash-screen-title">
                {window.lang[this.props.language][this.props.screen]}
              </div>
              <p style={{ padding: '0px 15px', color: '#919191' }}>
                {window.lang[this.props.language]['signupMessage1']}
              </p>
              <input
                className="form-control"
                required={true}
                type="text"
                value={this.state.email}
                onChange={this.onChange}
                name="email"
                placeholder={window.lang[this.props.language]['email']}
                pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
              />
              {this.props.cooldown <= 0 && (
                <div className="register-recaptcha-positioner">
                  <Recaptcha componentToVerify="register" />
                </div>
              )}
              {this.props.cooldown > 0 ? (
                <div style={{ marginTop: '8px' }}>
                  {`Please wait ${this.parseCooldownTime(this.props.cooldown)}
                  and try again.`}
                </div>
              ) : (
                <button
                  type="submit"
                  disabled={
                    !this.props.cooldown <= 0 ||
                    !this.props.recaptchaIsValid ||
                    this.state.email?.length === 0 ||
                    this.state.sentRequest
                  }
                  className={`btn login btn-primary${
                    this.state.valid ? '' : ''
                  }`}
                  style={{
                    padding: '10px 0px',
                    width: '100%',
                    maxWidth: '100%',
                  }}>
                  {window.lang[this.props.language]['submit']}
                </button>
              )}
              <div className="go-to-screen-link">
                <span className="non-navigational">
                  {`${window.lang[this.props.language]['alreadyRegistered']} `}
                </span>
                <span
                  className="navigational"
                  onClick={() => {
                    this.props.changeSection('login');
                    this.props.changeScreen('login');
                  }}>
                  {` ${window.lang[this.props.language]['loginHere']}`}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  language: PropTypes.string,
  policy: PropTypes.object,
  user: PropTypes.object,
  screen: PropTypes.string.isRequired,
  changeScreen: PropTypes.func,
  changeSection: PropTypes.func,
  getReceipt: PropTypes.func,
  verifyEmail: PropTypes.func,
  eventId: PropTypes.number,
  recaptchaIsValid: PropTypes.bool.isRequired,
  validateToken: PropTypes.func,
  registerFailAttempts: PropTypes.number.isRequired,
  setCaptchaToken: PropTypes.func,
  cooldown: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    policy: state.api.policy.data,
    visibility: state.api.policy.visibility,
    errorMessage: state.api.user.errorMessage,
    screen: state.api.policy.screen,
    currency: state.api.policy.data.currencySymbol,
    recaptchaIsValid: state.api.recaptcha.register.valid,
    registerFailAttempts: state.api.recaptcha.register.failedAttempts,
    eventId: state.api.policy.data.eventId,
    currencyIso: state.api.policy.data.currencyIso,
    language: state.api.language.selected,
    cooldown: state.api.requestThrottle.register.cooldown,
    user: state.api.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyEmail: (fields) => dispatch(verifyEmail(fields)),
    changeScreen: (screen) => {
      dispatch(changeScreen(screen));
    },
    changeSection: (section) => {
      dispatch(changeSection(section));
    },
    getReceipt: (eventId, id, paymentRef, token) =>
      dispatch(getReceipt(eventId, id, paymentRef, token)),
    validateToken: (token) => dispatch(validateToken(token)),
    setCaptchaToken: (token, componentToVerify) =>
      dispatch(setCaptchaCheck(token, componentToVerify)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
