import React from 'react';
import Map from '../../../../../layout/Map';
import PropTypes from 'prop-types';

const GMaps = (props) => {
  const { content, customHeight } = props;
  const location = { lat: content.lat, lng: content.lng };
  return (
    <div className="gmap">
      <Map
        showLocationMarker={true}
        markers={[location]}
        zoomLocation={location}
        singleMarker={true}
        height={customHeight}
      />
    </div>
  );
};

GMaps.propTypes = {
  content: PropTypes.string.isRequired,
  customHeight: PropTypes.string,
};

export default GMaps;
