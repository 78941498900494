import React from 'react';
import PropTypes from 'prop-types';
import SingleProduct from './SingleProduct';

import { getProductsClient } from '@actions';
import { connect } from 'react-redux';
import {
  changeSection,
  createProducts,
  deleteProductPurchase,
  selectProduct,
  setProductsTabIndex,
} from '../../../../../actions';
import { Tab, Tabs } from '@material-ui/core';
import AllProducts from './partials/AllProducts';
import MyProducts from './partials/MyProducts';
class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: [],
      minPrice: {},
      cart: [],
    };
    this.minPrice = {};
    this.changeModalStatus = this.changeModalStatus.bind(this);
    this.setProductPrices = this.setProductPrices.bind(this);
    this.handleProceedToPurchase = this.handleProceedToPurchase.bind(this);
    this.handleCancelPurchase = this.handleCancelPurchase.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    if (this.props.policyType === 'public') {
      this.props.changeSection('generalInfo');
    }
  }

  changeModalStatus() {
    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  }

  setProductPrices(prices) {
    this.setState({ prices }, () => this.changeModalStatus());
  }

  componentDidMount() {
    this.props.getProductsClient();
  }
  handleTabChange(_, newTabValue) {
    this.props.setProductsTabIndex(newTabValue);
  }
  handleProceedToPurchase(cart) {
    return (e) => {
      e?.stopPropagation?.();
      this.props.createProducts(this.props.eventId, cart);
    };
  }
  handleCancelPurchase(productId, priceId) {
    return () => {
      this.props.cancelPurchace(this.props.eventId, productId, priceId);
    };
  }

  render() {
    const { products, productsReady, language, color, currency, user } =
      this.props;
    const loadingCards = [];

    for (let i = 1; i < 5; i++) {
      loadingCards.push(
        <SingleProduct
          key={i}
          loading={true}
          selectProduct={this.props.selectProduct}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="products-wrapper">
          <div className="products-header">
            <Tabs
              className="custom-tabs"
              value={this.props.productsTabIndex}
              onChange={this.handleTabChange}
              aria-label="simple tabs example">
              <Tab label={lang[this.props.language]['allProducts']} />
              <Tab label={lang[this.props.language]['myProducts']} />
            </Tabs>
          </div>
          <div className="card-container">
            {productsReady ? (
              this.props.productsTabIndex === 0 ? (
                <AllProducts
                  products={products}
                  language={language}
                  color={color}
                  currency={currency}
                  setProductPrices={this.setProductPrices}
                  handleProceedToPurchase={this.handleProceedToPurchase}
                  userProducts={user.products}
                  onAction={() => {
                    this.props.changeSection('billing');
                  }}
                />
              ) : (
                <MyProducts
                  products={user.products}
                  language={language}
                  color={color}
                  currency={currency}
                  setProductPrices={this.setProductPrices}
                  onAction={() => {
                    this.props.changeSection('billing');
                  }}
                  onCancel={this.handleCancelPurchase}
                />
              )
            ) : (
              loadingCards
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Products.propTypes = {
  getProductsClient: PropTypes.func.isRequired,
  productsReady: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  selectProduct: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  createProducts: PropTypes.func.isRequired,
  changeSection: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  productsTabIndex: PropTypes.number.isRequired,
  setProductsTabIndex: PropTypes.func.isRequired,
  cancelPurchace: PropTypes.func.isRequired,
  policyType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    products: state.api.products.data,
    productsReady: state.api.products.ready,
    language: state.api.language.selected,
    color: state.api.event.data.clientPanelSettings.general.colors.primary,
    currency: state.api.event.data.currencySymbol,
    eventId: state.api.event.data.id,
    policyType: state.api.policy.data.type,
    user: state.api.user.data,
    productsTabIndex: state.api.products.productsTabIndex,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProductsClient: () => dispatch(getProductsClient()),
  selectProduct: (id) => dispatch(selectProduct(id)),
  createProducts: (eventId, data) => dispatch(createProducts(eventId, data)),
  changeSection: (section) => dispatch(changeSection(section)),
  setProductsTabIndex: (tabIndex) => dispatch(setProductsTabIndex(tabIndex)),
  cancelPurchace: (eventId, productId, priceId) =>
    dispatch(deleteProductPurchase(eventId, productId, priceId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Products);
