import React from 'react';
import PropTypes from 'prop-types';

export const Logout = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    stroke={color}
    xmlns="http://www.w3.org/2000/svg">
    <title>logout</title>
    <path
      fill="none"
      d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7M14 15L19 10M19 10L14 5M19 10H7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Logout.propTypes = {
  color: PropTypes.string,
};
