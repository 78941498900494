import React from 'react';
import PropTypes from 'prop-types';

export default class SponsorImage extends React.Component {
  render() {
    return (
      <div className="sponsor-container">
        {this.props.sponsorSchema.map((sponsor) => {
          return (
            <div className="sponsor-container-item" key={sponsor.id}>
              <img
                src={sponsor.clientVideoSponsorImage[0]?.url}
                onClick={() => {
                  this.props.setSiteToNavigateTo(sponsor.url);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

SponsorImage.propTypes = {
  sponsorSchema: PropTypes.array.isRequired,
  changeModalStatus: PropTypes.func,
  setSiteToNavigateTo: PropTypes.func,
};
