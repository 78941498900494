import React from 'react';
import PropTypes from 'prop-types';
import KMBReactPlayer from '@components/layout/KMBReactPlayer';

export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: '',
      videoUrl: null,
      otherVideos: [],
      sessionId: null,
      speechId: null,
      ready: true,
      isAutoPlay: false,
      mailto: '',
      videoTitle: <span />,
      completed: false,
      srcUrl: '',
      question: '',
      name: '',
      session: null,
      status: '',
    };
    this.streamEnded = false;
    this.playerRef = null;
    this.position = 0;

    this.statsInterval = parseInt(Math.random() * 120000 + 4 * 60000); //aproximatly 5 minutes
    this.timeoutStats = setInterval(() => {}, this.statsInterval);
  }
  componentWillUnmount() {
    if (this.state.status === 'play') {
      this.handleEvents('pause', null);
    }
    if (this.timeoutStats) {
      clearInterval(this.timeoutStats);
    }
  }

  shouldComponentUpdate(update) {
    const eventSpeechId =
      this.props.speechId == 0 ? undefined : this.props.speechId;
    const live =
      (this.props.srcUrl || '').length > 0 &&
      this.props.session.thirdPartyHosting &&
      !this.props.speechId;

    if (
      update.watchingOffline &&
      !this.props.watchingOffline &&
      this.state.uid?.length > 0
    ) {
      this.props.watchtimeStatistics(this.props.event.id, {
        eventUserId: this.props.userId,
        eventSessionId: this.props.sessionId,
        eventSpeechId: eventSpeechId,
        time: live
          ? parseInt(Date.now() / 1000 - this.playerRef.getCurrentTime())
          : parseInt(this.playerRef.getCurrentTime()),
        type: 'end',
        uid: this.state.uid,
      });
      this.setState({ uid: '' });
    } else if (
      !update.watchingOffline &&
      this.props.watchingOffline &&
      this.state.status === 'play'
    ) {
      this.props
        .watchtimeStatistics(this.props.event.id, {
          eventUserId: this.props.userId,
          time: live
            ? parseInt(Date.now() / 1000 - this.playerRef.getCurrentTime())
            : parseInt(this.playerRef.getCurrentTime()),
          eventSpeechId: eventSpeechId,
          eventSessionId: this.props.sessionId,
          type: 'start',
        })
        .then((response) => {
          this.setState({ uid: response.data.uid }, () => {
            // start timeout
            this.endStats();
          });
        });
    }

    return true;
  }
  onError() {
    return (
      this.props.errorUrl.length > 0 &&
      this.setState(
        { errorStream: true, isAutoPlay: true, ready: false },
        () => {
          this.setState({ ready: true });
        }
      )
    );
  }
  endStats() {
    const live =
      (this.props.srcUrl || '').length > 0 &&
      this.props.session.thirdPartyHosting &&
      !this.props.speechId;

    if (this.timeoutStats) {
      clearInterval(this.timeoutStats);
    }

    this.timeoutStats = setInterval(() => {
      if (this.streamEnded) {
        if (this.timeoutStats) {
          clearInterval(this.timeoutStats);
        }
      }
      if (this.playerRef) {
        this.position = this.playerRef.getCurrentTime();
      }
      if (!this.position || this.props.watchingOffline) {
        return;
      }

      const eventSpeechId =
        this.props.speechId == 0 ? undefined : this.props.speechId;
      this.props
        .watchtimeStatistics(this.props.event.id, {
          eventUserId: this.props.userId,
          eventSessionId: this.props.sessionId,
          eventSpeechId: eventSpeechId,
          time: live
            ? parseInt(Date.now() / 1000 - this.position)
            : parseInt(this.position),
          type: 'end',
          uid: this.state.uid,
        })
        .then((response) => {
          if (this.state.uid?.length > 0) return;
          this.setState({ uid: response.data.uid });
        });
    }, this.statsInterval);
  }
  handleEvents(type, event) {
    if (this.state.errorStream || !this.props.session.enableVideoMinutes) {
      return false;
    }

    event = event || { position: this.position };
    const eventSpeechId =
      this.props.speechId == 0 ? undefined : this.props.speechId;

    if (type == 'play' && !this.props.watchingOffline) {
      const live =
        (this.props.srcUrl || '').length > 0 &&
        this.props.session.thirdPartyHosting &&
        !this.props.speechId;
      this.props
        .watchtimeStatistics(this.props.event.id, {
          eventUserId: this.props.userId,
          time: live
            ? parseInt(Date.now() / 1000 - this.position)
            : parseInt(event.position),
          eventSpeechId: eventSpeechId,
          eventSessionId: this.props.sessionId,
          type: 'start',
        })
        .then((response) => {
          this.setState({ uid: response.data.uid, status: type }, () => {
            // start timeout
            this.endStats();
          });
        });
    } else if (type == 'pause' && !this.props.watchingOffline) {
      // end timeout
      clearInterval(this.timeoutStats);
      this.status = type;
      const live =
        (this.props.srcUrl || '').length > 0 &&
        this.props.session.thirdPartyHosting &&
        !this.props.speechId;
      this.props
        .watchtimeStatistics(this.props.event.id, {
          eventUserId: this.props.userId,
          eventSessionId: this.props.sessionId,
          eventSpeechId: eventSpeechId,
          time: live
            ? parseInt(Date.now() / 1000 - this.position)
            : parseInt(event.position),
          type: 'end',
          uid: this.state.uid,
        })
        .then(() => {
          this.setState({ uid: '', status: type });
        });
    }
    this.position = event.position;
  }
  render() {
    return (
      <div
        className="video-container"
        style={{ display: this.props.hidden ? 'none' : '' }}>
        <div className="player-container">
          <KMBReactPlayer
            file={
              this.state.errorStream ? this.props.errorUrl : this.props.videoUrl
            }
            onReady={(e) => {
              this.playerRef = e;
            }}
            isAutoPlay={this.props.isAutoPlay}
            onStart={() => {
              if (
                this.state.errorStream ||
                !this.props.session.enableVideoMinutes
              ) {
                return false;
              }
              this.endStats();
            }}
            onSeek={(e, isLiveVimeo = false) => {
              if (this.state.status === 'play' && !isLiveVimeo) {
                this.handleEvents('pause', {
                  position: parseInt(this.position || 0),
                  duration: parseInt(e.getDuration()),
                });
              }
              this.handleEvents('play', {
                position: parseInt(e.getCurrentTime() || 0),
                duration: parseInt(e.getDuration()),
              });
            }}
            onPlay={(e) => {
              if (this.state.status == 'play') {
                return false;
              }

              this.position = e.getCurrentTime() || 0;
              this.handleEvents('play', {
                position: parseInt(e.getCurrentTime() || 0),
                duration: parseInt(e.getDuration()),
              });
            }}
            onEnded={(e, pauseOnCurrent = false) => {
              this.streamEnded = true;
              // This code is used in case there is an error in the stream url
              // and when the error url ends, it resets the state, so the normal url
              // can begin again
              (this.props.isAutoPlay &&
                this.state.errorStream &&
                this.setState({ errorStream: false })) ||
                null;

              //check if pause is called far from current position
              let currentTime = e.getCurrentTime();
              if (Math.abs(currentTime - this.position) > 2) {
                currentTime = this.position;
              }
              this.handleEvents('pause', {
                position: parseInt(
                  pauseOnCurrent ? currentTime : this.position
                ),
                duration: parseInt(e.getDuration()),
              });
            }}
            onPause={(e, pauseOnCurrent = false) => {
              //check if pause is called far from current position
              let currentTime = e.getCurrentTime();
              if (Math.abs(currentTime - this.position) > 2) {
                currentTime = this.position;
              }
              this.handleEvents('pause', {
                position: parseInt(
                  pauseOnCurrent ? currentTime : this.position
                ),
                duration: parseInt(e.getDuration()),
              });
            }}
            onError={(e) => this.onError(e)}
            onProgress={(e) => {
              if (this.state.status == 'play') {
                this.position = e.getCurrentTime();
              }
            }}
          />
        </div>
        <div className="video-info-container">{this.props.videoTitle}</div>
        {!!this.props.callToAction && this.props.callToAction}
      </div>
    );
  }
}

Player.propTypes = {
  srcUrl: PropTypes.string.isRequired,
  session: PropTypes.object,
  speechId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  watchtimeStatistics: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  videoUrl: PropTypes.string,
  isAutoPlay: PropTypes.bool,
  videoImage: PropTypes.string,
  errorUrl: PropTypes.string,
  videoTitle: PropTypes.object,
  callToAction: PropTypes.object,
  hidden: PropTypes.bool,
  watchingOffline: PropTypes.bool,
};
