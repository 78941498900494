import React from 'react';
import PropTypes from 'prop-types';

export default class Thumbnail extends React.Component {
  render() {
    const { video, sessions } = this.props;
    const isSpeech = this.props.video.eventSpeechId ? true : false;
    const session = sessions.find((s) => s.id === video.eventSessionId);
    let thumbnail = video.videoThumbnail;

    if (isSpeech) {
      const speech = session?.speeches?.find(
        (s) => s.id === video.eventSpeechId
      );
      thumbnail =
        speech?.videoSpeechThumbnail?.length > 0
          ? speech?.videoSpeechThumbnail[0].url
          : thumbnail;
    } else
      thumbnail =
        session?.videoSessionThumbnail?.length > 0
          ? session?.videoSessionThumbnail[0].url
          : thumbnail;

    return (
      <div
        key={
          isSpeech
            ? `speech-${this.props.video.eventSpeechId}`
            : `session-${this.props.video.eventSessionId}`
        }
        className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 kmb-jwp-container">
        <div className="thumbnail-container">
          <div
            className="video-thumbnail"
            style={{
              backgroundImage: `url("${thumbnail}")`,
            }}
          />
          <i className="play-btn-container">
            <i className="icon-play-btn" />
          </i>
        </div>
        <div
          className="container"
          onClick={() => {
            this.props.showVideo(
              this.props.video.eventSessionId,
              this.props.video.eventSpeechId
            );
          }}
        />
        <h5 className="video-title">{this.props.video.name}</h5>
        <p className="video-info">
          {!!this.props.video.speakers?.length > 0 &&
            this.props.video.speakers.map((speaker, count) => {
              let comma = '';
              if (count < this.props.video.speakers.length && count !== 0) {
                comma = ', ';
              }
              return comma + `${speaker.lastName} ${speaker.firstName}`;
            })}
        </p>
      </div>
    );
  }
}

Thumbnail.propTypes = {
  language: PropTypes.string,
  sessions: PropTypes.array,
  showVideo: PropTypes.func,
  video: PropTypes.object,
};
