import React from 'react';
import PropTypes from 'prop-types';

export const AccountIcon = ({ color }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32">
    <title>usericon</title>
    <path
      stroke={color}
      fill={color}
      d="M16.031 0c2.75 0 5.188 1 7.188 3s3 4.375 3 7.188c0 1.688-0.438 3.313-1.25 4.813s-1.938 2.75-3.313 3.625c2.688 1.188 4.813 2.938 6.438 5.313 1.625 2.438 2.438 5.063 2.438 8.063h-2.875c0-3.188-1.125-5.938-3.438-8.25-2.25-2.25-5-3.375-8.188-3.375-3.25 0-6 1.125-8.25 3.375-2.25 2.313-3.375 5.063-3.375 8.25h-2.938c0-3 0.813-5.625 2.438-8.063 1.625-2.375 3.813-4.125 6.5-5.313-1.438-0.875-2.5-2.125-3.375-3.625-0.813-1.5-1.188-3.125-1.188-4.813 0-2.813 1-5.188 3-7.188s4.375-3 7.188-3zM16.031 2.938c-2 0-3.75 0.688-5.125 2.125-1.438 1.438-2.188 3.125-2.188 5.125s0.75 3.688 2.188 5.125c1.375 1.438 3.125 2.125 5.125 2.125s3.688-0.688 5.125-2.125c1.438-1.438 2.125-3.125 2.125-5.125s-0.688-3.688-2.125-5.125c-1.438-1.438-3.125-2.125-5.125-2.125z"
    />
  </svg>
);

export const AccountIconGray25 = () => <AccountIcon color="var(--gray-25)" />;

AccountIcon.propTypes = {
  color: PropTypes.string,
};
