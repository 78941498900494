import { connect } from 'react-redux';
import QuestionnairePage from './QuestionnairePage';
import { setCaptchaCheck, validateToken, submitQuestionnaire } from '@actions';

const mapStateToProps = (state) => {
  return {
    policy: state.api.policy.data,
    visibility: state.api.policy.visibility,
    errorMessage: state.api.user.errorMessage,
    recaptchaIsValid: state.api.recaptcha.questionnaire.valid,
    questionnaireFailedAttempts:
      state.api.recaptcha.questionnaire.failedAttempts,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validateToken: (token) => dispatch(validateToken(token)),
    submitQuestionnaire: (fields, token) =>
      dispatch(submitQuestionnaire(fields, token)),
    setCaptchaToken: (token, componentToVerify) =>
      dispatch(setCaptchaCheck(token, componentToVerify)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnairePage);
