import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NoImageIcon from '@public/no_img.svg';
import Button from '@material-ui/core/Button';
import { showAbstractPreview } from '@actions';
import AbstractHeader from './CardComponents/AbstractHeader';
import AbstractInfo from './CardComponents/AbstractInfo';
import { bytesToMegabytes, isValidFileType } from '@helpers/fileHelpers';
import FileViewer from '@components/layout/FileViewer';

const AbstractCard = (props) => {
  const [fileViewerOpen, setFileViewerOpen] = React.useState(false);

  const publishedAbstract =
    props.abstract.publishedAbstractFile.length > 0
      ? props.abstract.publishedAbstractFile[0]
      : null;

  const canViewFile = publishedAbstract && isValidFileType(publishedAbstract);

  const publishedAbstractThumbnail =
    props.abstract.publishedAbstractThumbnail.length > 0
      ? props.abstract.publishedAbstractThumbnail[0]
      : null;

  const [showFullAbstract, setShowFullAbstract] = React.useState(false);

  const toggleShowFullAbstract = () => {
    setShowFullAbstract(!showFullAbstract);
  };

  return (
    <div
      className={`abstract-card abstract-${props.abstract.id} abstract-card-list-item`}>
      {props.showThumbnail && (
        <div
          onClick={() => props.showAbstractPreview(props.abstract.id)}
          className="thumbnail-wrapper">
          {publishedAbstractThumbnail ? (
            <img
              className={`warning-icon`}
              src={publishedAbstractThumbnail.url}
            />
          ) : (
            <img className={`warning-icon`} src={NoImageIcon} />
          )}
        </div>
      )}
      <div
        className={`abstract-info ${
          props.showThumbnail ? '' : 'no-thumbnail'
        }`}>
        <AbstractHeader abstract={props.abstract} />
        <div
          className={`abstract-main-text ${
            showFullAbstract ? 'show-full' : ''
          }`}>
          {props.abstract?.abstractText}
        </div>
        <div className="abstract-action-bar">
          <Button
            className="toggle-more"
            onClick={(e) => {
              e.stopPropagation();
              toggleShowFullAbstract();
            }}
            variant="contained">
            {showFullAbstract
              ? lang[props.language]['readLess']
              : lang[props.language]['readMore']}
            ...
          </Button>
          <div>
            <Button
              disabled={!canViewFile}
              className="view-file"
              onClick={() => setFileViewerOpen(true)}
              variant="contained">
              {lang[props.language]['viewFile']}
            </Button>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <Button
              disabled={!publishedAbstract}
              onClick={(e) => {
                e.stopPropagation();
                window.open(publishedAbstract?.url);
              }}
              className="download-file"
              variant="contained">
              {lang[props.language]['download']}
            </Button>
          </div>
          {publishedAbstract && publishedAbstract.size && (
            <div className="file-size-text">
              {`${lang[props.language]['fileSize']} (${bytesToMegabytes(
                publishedAbstract.size
              )}MB)`}
            </div>
          )}
        </div>
        <div className="card-section-divider" />
        <AbstractInfo abstract={props.abstract} />
      </div>
      {canViewFile && fileViewerOpen && (
        <FileViewer
          fileViewerOpen={fileViewerOpen}
          handleClose={() => setFileViewerOpen(false)}
          abstract={props.abstract}
          infoComponent={() => <AbstractInfo abstract={props.abstract} />}
        />
      )}
    </div>
  );
};

AbstractCard.propTypes = {
  language: PropTypes.string,
  abstract: PropTypes.object,
  showThumbnail: PropTypes.bool,
  showAbstractPreview: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showAbstractPreview: (abstractID) =>
    dispatch(showAbstractPreview(abstractID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbstractCard);
