import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import PropTypes from 'prop-types';

export default function PaymentPayPalButton(props) {
  const { amount, currency, clientId } = props;
  return (
    <React.Fragment>
      <PayPalButton
        mode={window.location.host === 'bluetree.events' ? 'live' : 'sandbox'}
        id="paypal-option"
        amount={amount}
        createOrder={props.createOrder}
        onApprove={props.onApprove}
        currency={'USD'}
        onError={props.onError}
        catchError={props.onError}
        options={{
          clientId: clientId || 'sb',
          currency: currency || 'EUR',
        }}
      />
    </React.Fragment>
  );
}

PaymentPayPalButton.propTypes = {
  currency: PropTypes.string,
  clientId: PropTypes.string,
  onApprove: PropTypes.func,
  createOrder: PropTypes.func,
  onError: PropTypes.func,
  amount: PropTypes.number,
};
