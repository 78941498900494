import { connect } from 'react-redux';
import HotelManagement from './HotelManagement';
import {
  getHotels,
  selectHotel,
  changeSection,
  showPayment,
  cancelHotelReservation,
} from '@actions';

const mapStateToProps = (state) => {
  return {
    hotels: state.api.hotels.data,
    ready: state.api.hotels.ready,
    user: state.api.user.data,
    counter: state.api.counter,
    language: state.api.language.selected,
    currency: state.api.event.data.currencySymbol,
    event: state.api.event.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSection: (section, pageProps) =>
    dispatch(changeSection(section, undefined, pageProps)),
  getHotels: (hasReservation, meta) =>
    dispatch(getHotels(hasReservation, undefined, undefined, meta)),
  selectHotel: (id) => dispatch(selectHotel(id)),
  showPayment: (products, info) => dispatch(showPayment(products, info)),
  cancelHotelReservation: (eventId, eventHotelId, reservationId) =>
    dispatch(cancelHotelReservation(eventId, eventHotelId, reservationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelManagement);
