import React from 'react';
import PropTypes from 'prop-types';
import NavigateModal from '../../../../../layout/NavigateModal';

export default class SponsorStrip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      linkToNavigateTo: '',
    };
  }

  navigateOutOfSiteHandler = () => {
    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  };

  setSiteToNavigateTo = (link) => {
    if (link.includes('bluetree.events')) {
      window.open(link);
    } else {
      this.setState({ linkToNavigateTo: link }, () =>
        this.navigateOutOfSiteHandler()
      );
    }
  };

  sortByIndex = (a, b) => {
    if (a.index > b.index) {
      return 1;
    }
    if (a.index < b.index) {
      return -1;
    }
    return 0;
  };

  render() {
    const { event } = this.props;
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;

    return (
      <div
        style={{
          display: 'flex',
          marginTop: 15,
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>
        {event.clientPanelSettings.home.homeJumbotronAsBackground
          ? event.clientPanelSettings.home.homeSponsors
              .sort(this.sortByIndex)
              .map((s) => {
                return (
                  <div
                    key={s.id}
                    style={{ flexBasis: '25%', textAlign: 'center' }}>
                    <a
                      target="_blank"
                      style={{ maxHeight: 60, cursor: 'pointer' }}
                      onClick={() => s?.url && this.setSiteToNavigateTo(s.url)}>
                      {s.clientHomeSponsorImage[0]?.url && (
                        <img
                          src={s.clientHomeSponsorImage[0].url}
                          style={{
                            objectFit: 'contain',
                            maxWidth: 260,
                            maxHeight: 60,
                          }}
                        />
                      )}
                    </a>
                  </div>
                );
              })
          : null}
        {this.state.modalIsOpen && (
          <NavigateModal
            changeModalStatus={this.navigateOutOfSiteHandler}
            linkToNavigateTo={this.state.linkToNavigateTo}
            language={this.props.language}
            color={primaryColor}
          />
        )}
      </div>
    );
  }
}

SponsorStrip.propTypes = {
  time: PropTypes.string,
  event: PropTypes.object.isRequired,
  location: PropTypes.string,
  language: PropTypes.string,
};
