import React from 'react';
import PropTypes from 'prop-types';

export default class Exhibition extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      size,
      heightMultiplier,
      exhibitorId,
      exhibitorThumbnail,
      isPremium,
    } = this.props;

    const mobileSize = size <= 6 ? 6 : 12;
    const setHeight = isPremium ? 200 : 160;
    const actualHeight = setHeight * heightMultiplier;

    return (
      <div
        className={`col-${mobileSize} col-lg-${
          isPremium ? size : 4
        }   col-xl-${size}`}>
        <div
          className={`exhibitor-card ${
            heightMultiplier === 1 && 'reduced-padding'
          }`}
          style={{ height: actualHeight }}
          onClick={() => this.props.showExhibitor(exhibitorId)}>
          {exhibitorThumbnail ? <img src={exhibitorThumbnail} /> : null}
        </div>
      </div>
    );
  }
}

Exhibition.propTypes = {
  size: PropTypes.number.isRequired,
  heightMultiplier: PropTypes.number.isRequired,
  exhibitorId: PropTypes.number.isRequired,
  showExhibitor: PropTypes.func.isRequired,
  exhibitorThumbnail: PropTypes.string.isRequired,
  isPremium: PropTypes.bool,
};
