import { connect } from 'react-redux';
import Sponsors from './Sponsors';

const mapStateToProps = (state) => {
  return {
    meta: state.api.speakers.meta,
    event: state.api.event.data,
    language: state.api.language.selected,
  };
};

export default connect(mapStateToProps, undefined)(Sponsors);
