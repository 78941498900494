import React from 'react';
import PropTypes from 'prop-types';
import KMBLoader from '@components/layout/KMBLoader';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';

export default class MobileTable extends React.Component {
  constructor(props) {
    super(props);
  }
  timeDateFormatter(date) {
    return {
      date: date.props.children[0].props.children,
      time: date.props.children[1].props.children,
    };
  }

  registerTable(item) {
    const disableQuantity =
      item.quantity === 0 && item.quantity === item.maxQuantity;
    return (
      <React.Fragment>
        {!!item.id && <div className="card-id">{`${item.id}`}</div>}
        {!!item.name && <div className="card-name">{`${item.name}`}</div>}
        {!!item.price && <div className="card-price">{`${item.price}`}</div>}
        {!!item.description && (
          <Tooltip
            disableFocusListener
            leaveDelay={0}
            title={`${item.description}`}>
            <div className="card-description">{`${item.description}`}</div>
          </Tooltip>
        )}
        {!!item.availablePaymentMethodsKeys && (
          <div className="card-payments">
            {item.availablePaymentMethodsKeys.map((item) => {
              if (item === 'bank') {
                return (
                  <img
                    className={`payment-icon payment-icon-bank`}
                    src={`${process.env.PUBLIC_URL}/ecommerce/bankPayment.svg`}
                  />
                );
              } else if (item === 'card') {
                return (
                  <React.Fragment>
                    {this.props.policy.billing.creditCard === 1 ? (
                      <img
                        className={`payment-icon payment-icon-card`}
                        src={`${process.env.PUBLIC_URL}/ecommerce/cardPayment.svg`}
                      />
                    ) : null}
                    {this.props.policy.billing.paypal === 1 ? (
                      <img
                        className={`payment-icon payment-icon-paypal`}
                        src={`${process.env.PUBLIC_URL}/ecommerce/paypalPayment.svg`}
                      />
                    ) : null}
                  </React.Fragment>
                );
              }
              return (
                <span className="payments-wrapper" key={'payment-card'}>
                  <img
                    className={`payment-icon payment-icon-${item}`}
                    src={`${process.env.PUBLIC_URL}/ecommerce/${item}Payment.svg`}
                  />
                </span>
              );
            })}
          </div>
        )}
        {!!item.price && (
          <div className="card-quantity">
            <div className="quantity-change">
              <div
                className="add-remove"
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                <IconButton
                  aria-label="add-Quantity"
                  disabled={item.quantity === 0}
                  onClick={() => {
                    this.props.onAddRemove(item, 'remove');
                  }}>
                  <RemoveCircleOutlineIcon
                    className={`remove-icon ${
                      item.quantity === 0 ? 'disabled' : ''
                    } quantity-icon`}
                  />
                </IconButton>
                <span
                  className={`quantity-number ${
                    disableQuantity ? 'disabled' : ''
                  }`}>
                  {item.quantity}
                </span>
                <IconButton
                  aria-label="add-Quantity"
                  disabled={item.quantity === item.maxQuantity}
                  onClick={() => {
                    this.props.onAddRemove(item, 'add');
                  }}>
                  <AddCircleOutlineIcon
                    className={`add-icon ${
                      item.quantity === item.maxQuantity ? 'disabled' : ''
                    } quantity-icon`}
                  />
                </IconButton>
              </div>
            </div>
            <div
              className={`price-view ${
                item.maxQuantity === 0 ? 'unavailable-price' : ''
              }`}>
              {item.price}
            </div>
          </div>
        )}
        {!!item.maxQuantity && (
          <div className="card-quantity-warning">
            {item.maxQuantity === 0 ? (
              <div className="not-available-message">
                {window.lang[this.props.language]['itemNotAvailable']}
                <br />
                <span
                  className="click-here"
                  style={{
                    color: window.colorPalette.primary[400],
                  }}>
                  {window.lang[this.props.language]['clickHere']}
                </span>
              </div>
            ) : null}
          </div>
        )}
      </React.Fragment>
    );
  }

  regularTable(item) {
    return (
      <React.Fragment>
        <div className="card-info-container">
          {Object.keys(this.props.columns).map((key, i) => {
            let value = item.hasOwnProperty(key) ? item[key] : '';
            if (this.props.onRender) {
              const jsx = this.props.onRender({ key, value }, item);
              if (jsx !== undefined) {
                // we have an override
                value = jsx;
              }
            }
            if (key === 'date') {
              this.timeDateFormatter(value);
            }
            return (
              <React.Fragment key={i}>
                {key === 'date' ? (
                  <React.Fragment>
                    <div className={`${key} col`}>
                      <div className="col-name">Date</div>
                      <div className={`col-entry`}>
                        {this.timeDateFormatter(value).date}
                      </div>
                    </div>
                    <div className={`${key} col`}>
                      <div className="col-name">Time</div>
                      <div className={`col-entry`}>
                        {this.timeDateFormatter(value).time}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className={`${key} col`}>
                    <div className="col-name">
                      {this.props.columns[key].name}
                    </div>
                    <div className={`col-entry`}>{value}</div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        {this.props.onTrClick && (
          <button
            className="button"
            onClick={() => this.props.onTrClick(item.id)}>
            {this.props.lectical ? this.props.lectical : 'OPEN'}
          </button>
        )}
      </React.Fragment>
    );
  }

  renderItems() {
    return this.props.items.map((item) => {
      return (
        <div className="card" key={item.id}>
          {this.props.register
            ? this.registerTable(item)
            : this.regularTable(item)}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="mobile-table-container">
        <div className="card-container">{this.renderItems()}</div>
        {this.props.updating && <KMBLoader rows={30} thin={false} />}
      </div>
    );
  }
}

MobileTable.defaultProps = {
  id: '',
  items: [],
  columns: {},
  containerClassName: '',
  tableClassName: '',
  onSort: () => {},
  updating: false,
  onTrClick: null,
};

MobileTable.propTypes = {
  id: PropTypes.string.isRequired,
  register: PropTypes.bool,
  items: PropTypes.array.isRequired,
  columns: PropTypes.object,
  policy: PropTypes.object,
  onRender: PropTypes.func,
  onRenderTh: PropTypes.func,
  onSort: PropTypes.func,
  containerClassName: PropTypes.string,
  tableClassName: PropTypes.string,
  updating: PropTypes.bool,
  onRenderColSpan: PropTypes.func,
  onTrClick: PropTypes.func,
  onTrClassName: PropTypes.func,
  lectical: PropTypes.string,
  registrer: PropTypes.bool,
  onAddRemove: PropTypes.func,
  language: PropTypes.string,
};
