const supportedFileMap = {
  pdf: {
    extensions: ['pdf'],
    langLabel: 'pdfFile',
  },
  image: {
    extensions: ['jpg', 'jpeg', 'png'],
    langLabel: 'imageFile',
  },
};

const bytesToMegabytes = (bytes) => {
  return parseFloat(
    (bytes * 9.5367431640625 * Math.pow(10, -7)).toFixed(2)
  ).toString();
};

const isValidFileType = ({ extension }) => {
  const values = Object.values(supportedFileMap);
  for (const { extensions } of values) {
    const fileIsValid = extensions.some((ext) => extension === ext);
    if (fileIsValid) return true;
  }
  return false;
};

const getTypeLangCode = (type) => {
  const values = Object.values(supportedFileMap);
  for (const { extensions, langLabel } of values) {
    const typeExists = extensions.some((ext) => ext === type);
    if (typeExists) return langLabel;
  }
  return 'unsupportedFileType';
};

export { supportedFileMap, bytesToMegabytes, isValidFileType, getTypeLangCode };
