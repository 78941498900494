import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import KMBLoader from '@components/layout/KMBLoader';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import { ClickAwayListener } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddButton from './partials/AddButton';

const LightTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 13,
    letterSpacing: 0.9,
  },
}))(Tooltip);

function SingleProduct(props) {
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [showQuantityControls, setShowQuantityControls] = React.useState(false);

  useEffect(() => {
    if (props.numberOfProductsInCart === 0) {
      setShowQuantityControls(false);
    }
  }, [props.numberOfProductsInCart]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const increaseQuantity = () => {
    if (props.maxQuantityReached) {
      return;
    }
    if (props.remainingQuantity <= 0) {
      return;
    }
    const newQuantity = props.numberOfProductsInCart + 1;
    props?.onChangeQuantity?.(newQuantity);
  };

  const decreaseQuantity = () => {
    if (props.numberOfProductsInCart > 0) {
      const newQuantity = props.numberOfProductsInCart - 1;
      props?.onChangeQuantity?.(newQuantity);
    }
  };

  const handleClearCart = () => {
    props?.onCancel?.();
    setShowQuantityControls(false);
  };

  const { currency } = props;

  if (props.loading) {
    return <KMBLoader rows={10} />;
  }

  const cancelPurchaseText = window.lang[props.language]['cancelPurchase'];

  return (
    <div className="card">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <LightTooltip title={props.name} placement="bottom-end" open={open}>
          <div
            className="product-name"
            alt={props.name}
            onClick={handleTooltipOpen}>
            {props.name}
          </div>
        </LightTooltip>
      </ClickAwayListener>
      <div className="details">
        <div className="description-wrapper">
          <Typography
            className={`custom-description ${expanded ? 'expanded' : ''}`}>
            {props.description}
          </Typography>
          {props.description && props.description.length > 28 && (
            <IconButton
              onClick={toggleExpanded}
              style={{ transform: expanded ? 'rotateX(180deg)' : '' }}>
              <ExpandMoreIcon />
            </IconButton>
          )}
        </div>
        <div className="price-button-wrapper">
          <div className="price">
            {isFinite(props.price) ? props.price.toFixed(2) : 'N/A'}
            {currency}
          </div>
          {showQuantityControls ? (
            <div className="quantity-controls">
              <IconButton onClick={decreaseQuantity} disabled={false}>
                <RemoveCircleOutlineIcon />
              </IconButton>
              <span>{props.numberOfProductsInCart}</span>
              <IconButton
                onClick={increaseQuantity}
                disabled={
                  props.remainingQuantity <= 0 || props.maxQuantityReached
                }>
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
          ) : (
            <AddButton
              isConfirmed={props.isConfirmed}
              maxQuantityReached={props.maxQuantityReached}
              isMyProducts={props.isMyProducts}
              language={props.language}
              showQuantityControls={showQuantityControls}
              numberOfProductsInCart={props.numberOfProductsInCart}
              onChangeQuantity={props?.onChangeQuantity}
              setShowQuantityControls={setShowQuantityControls}
              remainingQuantity={props.remainingQuantity}
              onAction={props.onAction}
            />
          )}
          {props.isMyProducts && !props.isConfirmed && (
            <button className="link-button" onClick={handleClearCart}>
              {cancelPurchaseText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

SingleProduct.propTypes = {
  loading: PropTypes.bool,
  product: PropTypes.object,
  language: PropTypes.string,
  modalIsOpen: PropTypes.bool,
  setProductPrices: PropTypes.func,
  color: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  selectProduct: PropTypes.func,
  name: PropTypes.string,
  description: PropTypes.string,
  onAction: PropTypes.func,
  isMyProducts: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  numberOfProductsInCart: PropTypes.number,
  remainingQuantity: PropTypes.number,
  maxQuantityReached: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeQuantity: PropTypes.func,
};

export default SingleProduct;
