import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getTwillioToken } from '@actions';
import { connect } from 'react-redux';
import TwilioChat from 'twilio-chat';
import Modal from '@components/layout/Modal';
import moment from 'moment';

class Message extends Component {
  static propTypes = {
    author: PropTypes.string,
    body: PropTypes.string.isRequired,
    me: PropTypes.bool,
    remove: PropTypes.func,
    services: PropTypes.object,
    chatCoordinator: PropTypes.bool,
    state: PropTypes.object,
    color: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modalIsOpen: false,
    };
  }
  render() {
    const classVariable = this.props.me ? 'me' : 'log';

    return (
      <div
        className={`message ${classVariable}`}
        onMouseEnter={() => this.setState({ visible: true })}
        onMouseLeave={() => this.setState({ visible: false })}
        style={{ backgroundColor: classVariable === 'me' && this.props.color }}>
        {this.props.author && (
          <React.Fragment>
            {(this.props.me || this.props.chatCoordinator) &&
              this.state.visible && (
                <span
                  className="icon-close"
                  style={{ cursor: 'pointer', paddingRight: 4 }}
                  onClick={() => {
                    this.setState({ modalIsOpen: true });
                  }}
                />
              )}
            <div
              className="author"
              style={{
                display: this.props.me ? 'none' : 'inline-block',
              }}>
              {this.props.author}:
            </div>
          </React.Fragment>
        )}

        <span>{this.props.body}</span>
        <div className="timestamp">
          {this.props.state &&
            this.state.visible &&
            moment(this.props.state.timestamp).format('Do MMM HH:mm ')}
        </div>
        <Modal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={() => this.setState({ modalIsOpen: false })}
          inner={[
            <div key="main-1">
              <p key="main-2">
                You are about to remove this message from the conversation.Are
                you sure?
              </p>
              <div className="main-3" key="main-3">
                <button
                  key="main-4"
                  className="btn btn-cancel"
                  onClick={() => this.setState({ modalIsOpen: false })}>
                  Cancel
                </button>
                <button
                  key="main-5"
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.remove();
                    this.setState({ modalIsOpen: false });
                  }}>
                  OK
                </button>
              </div>
            </div>,
          ]}
        />
      </div>
    );
  }
}

class MessageForm extends Component {
  static propTypes = {
    onMessageSend: PropTypes.func.isRequired,
    color: PropTypes.string,
  };

  componentDidMount = () => {
    this.input.focus();
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    this.props.onMessageSend(this.input.value);
    this.input.value = '';
  };

  render() {
    return (
      <form className="message-form" onSubmit={this.handleFormSubmit}>
        <div className="input-container">
          <input
            type="text"
            ref={(node) => (this.input = node)}
            placeholder="Type your message..."
          />
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => this.handleFormSubmit}
            type="submit">
            <button type="submit" style={{ cursor: 'pointer' }}>
              <i className="icon-send" style={{ color: this.props.color }} />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

class MessageList extends Component {
  static propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object),
    chatCoordinator: PropTypes.bool,
    color: PropTypes.color,
  };

  static defaultProps = {
    messages: [],
  };

  componentDidUpdate = () => {
    this.node.scrollTop = this.node.scrollHeight;
  };

  render() {
    return (
      <div className="message-list" ref={(node) => (this.node = node)}>
        {this.props.messages.map((message, i) => (
          <Message
            key={i}
            {...message}
            chatCoordinator={this.props.chatCoordinator}
            color={this.props.color}
          />
        ))}
      </div>
    );
  }
}

class KMBChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      messages: [],
    };
    this.channel = null;
    this.updateChannelUi = this.updateChannelUi.bind(this);
  }
  componentDidMount() {
    if (this.channel === false) {
      return;
    }
    this.getToken(this.props)
      .then(this.createChatClient)
      .then(this.joinGeneralChannel)
      .then(this.configureChannelEvents)
      .catch((error) => {
        this.setState({
          messages: [
            ...this.state.messages,
            { body: `Error: ${error.message}` },
          ],
        });
      });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.channel === false) {
      return;
    }
    if (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) &&
      this.props.hidden === nextProps.hidden
    ) {
      this.getToken(nextProps)
        .then(this.createChatClient)
        .then(this.joinGeneralChannel)
        .then(this.configureChannelEvents)
        .catch((error) => {
          this.setState({
            messages: [
              ...this.state.messages,
              { body: `Error: ${error.message}` },
            ],
          });
        });
    }
  }
  componentWillUnmount() {
    if (this.channel) {
      this.channel.leave().then(() => (this.channel = false));
    }
  }
  createChatClient = (token) => {
    return new Promise((resolve) => {
      const client = new TwilioChat.create(token);

      resolve(client);
    });
  };

  createGeneralChannel = (chatClient) => {
    return new Promise((resolve, reject) => {
      this.addMessage({ body: 'Creating general channel...' });
      chatClient
        .createChannel({
          uniqueName: `bt-${this.props.orgId}-${this.props.eventId}-${this.props.sessionId}`,
          friendlyName: 'General Chat',
        })
        .then(() => this.joinGeneralChannel(chatClient))
        .catch(() => reject(Error('Could not create general channel.')));
    });
  };
  joinGeneralChannel = (chatClient) => {
    return new Promise((resolve, reject) => {
      return chatClient
        .getSubscribedChannels()
        .then(() => {
          chatClient
            .getChannelByUniqueName(
              `bt-${this.props.orgId}-${this.props.eventId}-${this.props.sessionId}`
            )
            .then((channel) => {
              this.addMessage({ body: 'Joining general channel...' });
              this.channel = channel;
              const join = () => {
                this.channel
                  .join()
                  .then(() => {
                    this.addMessage({
                      body: 'Joined general channel as ' + this.state.username,
                    });
                    this.updateChannelUi();
                    resolve(true);
                  })
                  .catch((e) => {
                    let check = false;
                    if (e.message === 'Member already exists') {
                      check = true;
                      this.addMessage({
                        body: `Could not join general channel.${
                          check ? ' Retrying...' : ''
                        }`,
                      });
                      return this.channel.leave().then(() => {
                        join();
                      });
                    }

                    reject(Error(`Could not join general channel.`));
                  });
              };
              join();
            })
            .catch(() => this.createGeneralChannel(chatClient));
        })
        .catch(() => reject(Error('Could not get channel list.')));
    });
  };
  getToken = (nextProps) => {
    return new Promise((resolve) => {
      this.addMessage({ body: 'Connecting...' });
      if (nextProps.sessionId !== null) {
        const fname = this.props.userInfo.firstName || '';
        const lname = this.props.userInfo.lastName || '';
        this.props
          .getTwillioToken(nextProps.eventId, nextProps.sessionId)
          .then((response) => {
            const { identity } = response.data;
            const uname = (fname + lname).length
              ? `${fname} ${lname[0]}.`
              : `user${identity}`;
            this.setState({ username: uname, author: identity });
            resolve(response.data.token);
          });
      }
    });
  };
  handleNewMessage = (text) => {
    if (text.trim().length != 0) {
      if (this.channel) {
        this.channel.sendMessage(
          JSON.stringify({ name: this.state.username, context: text })
        );
      }
    }
  };
  updateChannelUi() {
    this.channel.getMessages(100).then((page) => {
      this.addPageMessages(page, null);
    });
  }
  addPageMessages(page, history) {
    let msg_history = page.items.map((message) => {
      const spl = message.state.author.split('_');
      const uname = ((spl[1] || '') + (spl[2] || '')).length
        ? `${spl[1]} ${spl[2]}.`
        : `user${spl[0]}`;
      let body, username;
      try {
        (body = JSON.parse(message.state.body).context),
          (username = JSON.parse(message.state.body).name);
      } catch (e) {
        body = message.state.body;
        username = uname;
      }
      return {
        author: username,
        body,
        me: parseFloat(message.author) == parseFloat(this.state.author),
        remove: message.remove,
        updateAttributes: message.updateAttributes,
        services: message.services,
        channel: message.channel,
        index: message.index,
        state: message.state,
      };
    });

    if (history) {
      msg_history = msg_history.concat(history);
    }
    if (page.hasPrevPage) {
      page.prevPage().then((page) => this.addPageMessages(page, msg_history));
    } else {
      this.addMessage(msg_history);
    }
  }
  addMessage = (msg) => {
    let message;

    try {
      message = {
        body: JSON.parse(msg.body).context,
        author: parseFloat(msg.author),
        username: JSON.parse(msg.body).name,
        remove: msg.remove,
        services: msg.services,
        state: msg.state,
        channel: msg.channel,
        index: msg.index,
      };
    } catch (e) {
      message = msg;
    }

    if (message instanceof Array) {
      this.setState({
        messages: this.state.messages.concat(message),
      });
    } else {
      let messageData = {};
      if (message.author) {
        const me = parseFloat(message.author) == parseFloat(this.state.author);
        message.author = message.username;
        messageData = {
          ...message,
          me,
        };
      } else {
        messageData = {
          ...message,
          me: false,
        };
      }

      this.setState({
        messages: [...this.state.messages, messageData],
      });
    }
  };
  configureChannelEvents = () => {
    if (this.channel) {
      this.channel.on(
        'messageAdded',
        ({ author, body, remove, services, state, channel, index }) => {
          this.addMessage({
            author,
            body,
            remove,
            services,
            state,
            channel,
            index,
          });
        }
      );
      this.channel.on('messageRemoved', (res) => {
        let messages = [];
        this.state.messages.map((message) => {
          if (message.index !== res.state.index) {
            messages = [...messages, message];
          }
        });
        this.setState({ messages });
      });
    }
  };
  downloadTxtFile = () => {
    let parsedMsgs = [];
    // Prepare the messages in a readable format
    this.state.messages.map((msg) => {
      let timestamp,
        authorId = '';

      if (msg.state) {
        timestamp = moment(msg.state.timestamp).format('HH:mm Do/MMM');
        authorId = msg.state.author;
      }
      parsedMsgs = [
        ...parsedMsgs,
        `${authorId}-${msg.author}: ${msg.body} ${timestamp} \n`,
      ];
    });
    // Remove the first 3 messages because they are connecting...,joined etc.
    parsedMsgs.splice(0, 3);
    // Remove the commas
    parsedMsgs = parsedMsgs.join('');
    const element = document.createElement('a');
    const file = new Blob([parsedMsgs], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = `chat-history-session-${this.props.sessionId}-event-${this.props.eventId}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  render() {
    return (
      <div
        className="chat-container"
        style={{ display: this.props.hidden ? 'none' : '' }}>
        <div className="kmb-chat ">
          <MessageList
            messages={this.state.messages}
            chatCoordinator={this.props.chatCoordinator}
            color={this.props.color}
          />
          <MessageForm
            onMessageSend={this.handleNewMessage}
            color={this.props.color}
          />
        </div>
        <div className="download-container">
          {this.state.messages.length > 3 && this.props.chatCoordinator ? (
            <span className="download" onClick={this.downloadTxtFile}>
              Download Chat history
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventId: state.api.event.data.id,
    orgId: state.api.event.data.orgId,
    userInfo: state.api.user.data.info,
    userId: state.api.user.data.id,
  };
};

KMBChat.propTypes = {
  getTwillioToken: PropTypes.func,
  orgId: PropTypes.number,
  eventId: PropTypes.number,
  sessionId: PropTypes.number,
  userInfo: PropTypes.object,
  chatCoordinator: PropTypes.bool,
  hidden: PropTypes.bool,
  color: PropTypes.string,
};

export default connect(mapStateToProps, {
  getTwillioToken,
})(KMBChat);
