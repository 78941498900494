import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bluetreeInternalURLs } from '../../helpers';

const NavigateModal = ({
  changeModalStatus,
  language,
  color,
  linkToNavigateTo,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  const handleClose = (e) => {
    e.stopPropagation();
    document.body.style.overflow = 'auto';
    changeModalStatus();
  };
  const isInternalUrl = bluetreeInternalURLs.some((internalURL) =>
    linkToNavigateTo.includes(internalURL)
  );

  if (isInternalUrl) {
    window.open(linkToNavigateTo);
    handleClose(new Event('click'));
    return null;
  }

  return (
    <>
      <div className="backdrop" onClick={handleClose} />
      <div className="navigate-modal" id="modal">
        <div className="lectical">
          {window.lang[language]['navigateOutOfSite']}
        </div>
        <div className="btn-row">
          <div
            className="confirm btn"
            style={{ backgroundColor: color }}
            onClick={(e) => {
              handleClose(e);
              window.open(linkToNavigateTo);
            }}>
            {window.lang[language]['confirm']}
          </div>
          <div className="cancel btn" onClick={handleClose} style={{ color }}>
            {window.lang[language]['cancel']}
          </div>
        </div>
      </div>
    </>
  );
};

NavigateModal.propTypes = {
  changeModalStatus: PropTypes.func,
  language: PropTypes.string,
  color: PropTypes.string,
  linkToNavigateTo: PropTypes.string,
};

export default NavigateModal;
