import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import moment from 'moment';
import Banner from './Partials/Banner';
import Info from './Partials/Info';
import SponsorStrip from './Partials/SponsorStrip';

import Speakers from './Partials/Speakers';
import Videos from './Partials/Videos';
import Pricing from './Partials/Pricing';
import Sponsors from './Partials/Sponsors';
import Accomodation from './Partials/Accomodation';

const GeneralInfo = (props) => {
  const { ready, event, language } = props;
  const location = event.location;
  const time = moment(event.startDate.tz).format('MMMM Do YYYY, h:mm ');
  window.scrollTo(0, 0);

  if (!ready) {
    return null;
  }

  return (
    <React.Fragment>
      <Banner
        language={language}
        time={time}
        location={location}
        event={event}
      />
      <SponsorStrip event={event} language={language} />
      {event.description ? <Info event={event} /> : null}
      {/* <Venue event={event} /> */}
      {event.clientPanelSettings.home.homeShowKeySpeakers ? (
        <Speakers event={event} />
      ) : null}
      {event.clientPanelSettings.home.homeShowPromoVideo ? (
        <Videos event={event} />
      ) : null}
      {event.clientPanelSettings.home.homeShowSubscriptions ? (
        <Pricing event={event} />
      ) : null}
      {event.clientPanelSettings.home.homeShowAccommodation ? (
        <LazyLoadComponent threshold={1000}>
          <Accomodation event={event} />
        </LazyLoadComponent>
      ) : null}
      {event.clientPanelSettings.home.homeShowSponsors ? (
        <Sponsors event={event} />
      ) : null}
    </React.Fragment>
  );
};

GeneralInfo.propTypes = {
  ready: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  language: PropTypes.string,
};

export default GeneralInfo;
