import React from 'react';

const Screen404 = () => {
  return (
    <React.Fragment>
      <img className="img-404" src={`${process.env.PUBLIC_URL}/logo.png`} />
      <div className="background-404"></div>

      <div className="text-404">
        <div className="subtext-404">
          Hi, you have reached a page that does not exist.
        </div>
        <div className="subtext-404">Congratulations.</div>
        <div className="subtext-404">
          {
            "Unfortunately we can't do a lot from here, so we suggest you try again to search for what you are looking."
          }
        </div>
        <div className="subtext-404">
          In case something should exist here, try contacting the event admin.
        </div>
      </div>
    </React.Fragment>
  );
};

Screen404.propTypes = {};
export default Screen404;
