import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHotels } from '@actions';
import Map from '@components/layout/Map';

class Accomodation extends React.Component {
  componentDidMount() {
    this.props.getHotels(false);
  }

  render() {
    const { hotels } = this.props;
    const image =
      'https://staging-admin.bluetree.events/images/medlive-noimage.jpg';
    if (hotels.length > 0) {
      return (
        <div className="row section">
          <p className="section-title ">Accommodation</p>

          <div className="col-sm-12 accommodation-container" />
          <div className="col-sm-12 col-md-4 hotel-col">
            <div className="row">
              <div className="col-sm-12 hotel-info-container">
                <h2 className="hotel-name">{hotels[0].name}</h2>
                <p className="hotel-info">{hotels[0].address}</p>
                <p className="hotel-info">{hotels[0].description}</p>
              </div>

              <div className="col-sm-12 hotel-col">
                <Map
                  showLocationMarker={false}
                  markers={[
                    {
                      lat: -120,
                      lng: 200,
                    },
                  ]}
                  zoomLocation={{ lat: -120, lng: 200 }}
                  singleMarker={true}
                  height={280}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-12 hotel-img-container">
            <img
              src={
                hotels[0].hotelImages.length > 0
                  ? hotels[0].hotelImages[0].url
                  : image
              }
            />
          </div>
          <div className="col-sm-12 hotel-mobile">
            <h2 className="hotel-name">{hotels[0].name}</h2>
            <p className="hotel-info">{hotels[0].address}</p>
            <p className="hotel-info">{hotels[0].description}</p>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
Accomodation.propTypes = {
  event: PropTypes.object.isRequired,
  hotels: PropTypes.array,
  getHotels: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hotels: state.api.hotels.data,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getHotels: (hasReservation) => dispatch(getHotels(hasReservation)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Accomodation);
