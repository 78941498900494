import { connect } from 'react-redux';
import Schedule from './Schedule';
import { loadSessions } from '@actions';

const mapStateToProps = (state) => {
  return {
    event: state.api.event.data,
    ready: state.api.event.ready,
    loading: state.api.sessions.fetching,
    agendaVisible: (state.api.user.data || {}).hasOwnProperty('rn'),
    freeAccess: state.api.policy.data.freeAccess === 1,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSessions: () => {
      dispatch(loadSessions());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
