import { connect } from 'react-redux';
import Account from './Account';
import {
  updateUser,
  requestResetPassword,
  getReceipt,
  getBadge,
  getCert,
} from '@actions';

const mapStateToProps = (state) => {
  return {
    user: state.api.user.data,
    policy: state.api.policy.data,
    language: state.api.language.selected,
    currency: state.api.event.data.currencySymbol,
    event: state.api.event.data,
    pageProps: state.menu.pageProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (eventId, policyId, userId, data) =>
    dispatch(updateUser(eventId, policyId, userId, data)),
  getReceipt: (eventId, id) => dispatch(getReceipt(eventId, id)),
  getBadge: (eventId) => dispatch(getBadge(eventId)),
  getCert: (eventId) => dispatch(getCert(eventId)),
  requestResetPassword: (email) => {
    dispatch(requestResetPassword(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
