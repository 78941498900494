import React from 'react';
import PropTypes from 'prop-types';

const Info = (props) => {
  const { event } = props;
  return (
    <div className="content-info">
      <div className="col-sm-12">
        {event.description && (
          <div className="description layout-item">
            <p dangerouslySetInnerHTML={{ __html: event.description }} />
          </div>
        )}
      </div>
    </div>
  );
};
Info.propTypes = {
  event: PropTypes.object.isRequired,
};

export default Info;
