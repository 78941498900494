import React from 'react';
import PropTypes from 'prop-types';

const CopyToClipboard = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 15H4.5C3.96957 15 3.46086 14.7893 3.08579 14.4142C2.71071 14.0391 2.5 13.5304 2.5 13V4C2.5 3.46957 2.71071 2.96086 3.08579 2.58579C3.46086 2.21071 3.96957 2 4.5 2H13.5C14.0304 2 14.5391 2.21071 14.9142 2.58579C15.2893 2.96086 15.5 3.46957 15.5 4V5M11.5 9H20.5C21.6046 9 22.5 9.89543 22.5 11V20C22.5 21.1046 21.6046 22 20.5 22H11.5C10.3954 22 9.5 21.1046 9.5 20V11C9.5 9.89543 10.3954 9 11.5 9Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CopyToClipboard.propTypes = {
  color: PropTypes.string,
};

export default CopyToClipboard;
