import React from 'react';
import PropTypes from 'prop-types';

const CardImages = () => {
  return (
    <div className="card-images">
      <img
        style={{
          border: '1px solid var(--gray-50)',
          margin: '0px 2px 0px 15px',
          objectFit: 'contain',
          borderRadius: '5px',
        }}
        height="32"
        width="48"
        src={`${process.env.PUBLIC_URL}/ecommerce/amex_logo.jpg`}
      />
      <img
        style={{
          border: '1px solid var(--gray-50)',
          margin: '0px 2px',
          objectFit: 'contain',
          borderRadius: '5px',
        }}
        height="32"
        width="48"
        src={`${process.env.PUBLIC_URL}/ecommerce/discover_logo.jpg`}
      />
      <img
        style={{
          margin: '0px 2px',
          border: '1px solid var(--gray-50)',
          padding: '3px',
          objectFit: 'contain',
          borderRadius: '5px',
        }}
        height="32"
        width="48"
        src={`${process.env.PUBLIC_URL}/ecommerce/maestro.png`}
      />
      <img
        style={{
          margin: '0px 2px',
          border: '1px solid var(--gray-50)',
          padding: '1px',
          objectFit: 'contain',
          borderRadius: '5px',
        }}
        height="32"
        width="48"
        src={`${process.env.PUBLIC_URL}/ecommerce/mastercard.png`}
      />
      <img
        style={{
          margin: '0px 2px',
          border: '1px solid var(--gray-50)',
          padding: '5px',
          objectFit: 'contain',
          borderRadius: '5px',
        }}
        height="32"
        width="48"
        src={`${process.env.PUBLIC_URL}/ecommerce/Visa.png`}
      />
    </div>
  );
};

CardImages.propTypes = {
  language: PropTypes.string,
};

export default CardImages;
