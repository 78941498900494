import React from 'react';
import App from '../components/App';
import { Provider } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import { getHistory } from '../helpers/history';
import PropTypes from 'prop-types';

const ReduxStore = ({ store }) => (
  <Provider store={store}>
    <Router history={getHistory()}>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/schedule" component={App} />
        <Route exact path="/schedule/*" component={App} />
        <Route exact path="/speakers" component={App} />
        <Route exact path="/sponsors" component={App} />
        <Route exact path="/ebag" component={App} />
        <Route exact path="/register" component={App} />
        <Route exact path="/speakers/*" component={App} />
        <Route exact path="/accomodation" component={App} />
        <Route exact path="/accomodation/*" component={App} />
        <Route exact path="/floormap" component={App} />
        <Route exact path="/social" component={App} />
        <Route exact path="/billing" component={App} />
        <Route exact path="/videos" component={App} />
        <Route exact path="/videos/view/:id" component={App} />
        <Route exact path="/account" component={App} />
        <Route exact path="/abstracts" component={App} />
        <Route exact path="/abstracts/create" component={App} />
        <Route exact path="/abstracts/view/:id" component={App} />
        <Route exact path="/login" component={App} />
        <Route exact path="/products" component={App} />
        <Route exact path="/exhibition" component={App} />
        <Route exact path="/exhibition/*" component={App} />

        <Route path="/" component={App} />
      </Switch>
    </Router>
  </Provider>
);

ReduxStore.propTypes = {
  store: PropTypes.object.isRequired,
};

export default ReduxStore;
