import { connect } from 'react-redux';
import Main from './Main';
import { loadEvent } from '@actions';

const mapStateToProps = (state) => {
  return {
    eventId: state.api.policy.data.eventId,
    policy: state.api.policy.data,
    freeAccess: state.api.policy.data.freeAccess === 1,
    ready: state.api.event.ready,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId, policy, freeAccess) =>
      dispatch(loadEvent(eventId, policy, freeAccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
