import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import {
  changeScreen,
  changeSection,
  togglePasswordVisibility,
  login,
  logout,
  resetPassword,
  validateToken,
  requestResetPassword,
  toggleMobile,
  setCaptchaCheck,
} from '@actions';

const mapStateToProps = (state) => {
  return {
    policy: state.api.policy.data,
    recaptchaLoginIsValid: state.api.recaptcha.login.valid,
    recaptchaPasswordIsValid: state.api.recaptcha.passwordReset.valid,
    loginFailAttempts: state.api.recaptcha.login.failedAttempts,
    cooldown: state.api.requestThrottle.password.cooldown,
    visibility: state.api.policy.visibility,
    screen: state.api.policy.screen,
    email: state.api.policy.email,
    password: state.api.policy.password,
    resetPasswordAvailable: state.api.policy.resetPasswordAvailable,
    errorMessage: state.api.user.errorMessage,
    language: state.api.language.selected,
    user: state.api.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMobile: () => {
      dispatch(toggleMobile());
    },
    changeScreen: (screen) => {
      dispatch(changeScreen(screen));
    },
    logout: () => {
      dispatch(logout());
    },
    changeSection: (section) => {
      dispatch(changeSection(section));
    },
    togglePasswordVisibility: (key) => {
      dispatch(togglePasswordVisibility(key));
    },
    login: (eventId, policyId, fields) => {
      dispatch(login(eventId, policyId, fields));
    },
    validateToken: (token) => dispatch(validateToken(token)),
    resetPassword: (fields, token, email) => {
      dispatch(resetPassword(fields, token, email));
    },
    requestResetPassword: (email) => {
      return dispatch(requestResetPassword(email));
    },
    setCaptchaToken: (token, componentToVerify) =>
      dispatch(setCaptchaCheck(token, componentToVerify)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
