import React from 'react';

export const CartIcon = () => (
  <svg
    width="155"
    height="193"
    viewBox="0 0 155 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M125 39.0078C123.448 43.8046 122.416 46.5651 119.545 50.7029C115.809 56.09 112.444 58.14 107.182 62.0326C102.259 65.6743 99.399 67.7812 93.7273 70.073C85.9725 73.2064 77 74.8221 72.6364 73.7277C68.2727 72.6333 67.3812 72.1432 64.6364 70.073C59.649 66.3114 58.0909 63.8579 56.6364 56.185C55.1818 48.5121 57.0269 46.6177 60.6364 43.3915C64.8836 39.5952 70.4974 39.8193 75.9091 41.5661C81.7793 43.461 84.3704 47.9474 85.7273 53.9922C86.4148 57.0552 86.0623 58.9107 85.7273 62.0326C84.7393 71.2399 80.5303 75.5836 75.9091 83.5954C73.7951 87.2605 72.9897 89.6568 70.0909 92.7322C65.8869 97.1925 62.2526 98.3466 56.6364 100.773C51.6002 102.948 48.6231 104.126 43.1818 104.793C39.2348 105.277 33 104.793 33 104.793"
      stroke="#D4D4D7"
      strokeLinecap="round"
      strokeDasharray="2 2"
    />
    <path
      d="M59.3025 62.8438C56.5564 62.8438 53.9228 63.9346 51.981 65.8764C50.0393 67.8182 48.9484 70.4518 48.9484 73.1979C48.9484 75.944 50.0393 78.5776 51.981 80.5194C53.9228 82.4612 56.5564 83.5521 59.3025 83.5521H82.9692C85.7153 83.5521 88.3489 82.4612 90.2907 80.5194C92.2325 78.5776 93.3234 75.944 93.3234 73.1979C93.3234 70.4518 92.2325 67.8182 90.2907 65.8764C88.3489 63.9346 85.7153 62.8438 82.9692 62.8438H59.3025ZM22.9979 146.89C17.9214 126.584 15.3831 116.437 20.7081 109.609C26.0331 102.781 36.5056 102.781 57.4388 102.781H84.833C105.766 102.781 116.227 102.781 121.558 109.609C126.889 116.431 124.35 126.59 119.274 146.89C116.043 159.806 114.434 166.261 109.618 170.024C104.802 173.781 98.1455 173.781 84.833 173.781H57.4388C44.1263 173.781 37.47 173.781 32.6539 170.024C27.8377 166.261 26.2225 159.806 22.9979 146.89Z"
      fill="#C5DCFB"
    />
    <path
      d="M92.3237 77.6465C92.9872 76.2606 93.3325 74.744 93.3346 73.2074C93.3366 71.6709 92.9953 70.1533 92.3355 68.7656C96.3766 68.7952 99.5302 68.9786 102.341 70.0791C105.703 71.3942 108.627 73.6292 110.778 76.5283C112.949 79.4511 113.973 83.1964 115.375 88.3557L115.647 89.3615L118.682 106.893C116.263 105.224 113.144 104.23 109.109 103.638L106.979 91.2904C105.293 85.137 104.684 83.2023 103.654 81.8178C102.496 80.2564 100.921 79.0528 99.1101 78.3447C97.8084 77.8359 96.2405 77.6939 92.3237 77.6465ZM49.9426 68.7656C49.2829 70.1533 48.9415 71.6709 48.9436 73.2074C48.9456 74.744 49.291 76.2606 49.9545 77.6465C46.0376 77.688 44.4697 77.8359 43.168 78.3447C41.3573 79.0528 39.7826 80.2564 38.624 81.8178C37.5945 83.2023 36.9851 85.137 35.3048 91.2845L33.1689 103.644C29.1337 104.236 26.0156 105.224 23.5957 106.887L26.631 89.3615L26.9031 88.3557C28.3054 83.1964 29.329 79.4511 31.5004 76.5224C33.6532 73.6276 36.577 71.3969 39.9375 70.085C42.748 68.9845 45.9015 68.8011 49.9426 68.7715"
      fill="#1570EF"
    />
    <path
      d="M58.5 63.5078C58.5 63.5078 65.3988 73.1587 71.5 75.0078C79.3862 77.398 94 69.5078 94 69.5078"
      stroke="#BFBFC2"
      strokeDasharray="2 2"
    />
    <path
      d="M136.909 16.4606C135.714 14.9201 134.141 14.1659 132.14 14.0525L131.373 11.8711L129.919 12.3826L130.635 14.4187L130.053 14.6233C128.453 15.186 127.349 16.2281 126.595 17.8008L126.218 18.5871L123.926 18.5761C123.438 18.5842 122.951 18.5922 122.369 18.7968C121.497 19.1038 120.726 19.7015 120.204 20.539C119.536 21.4277 119.355 22.3083 119.32 23.1378C119.387 24.2581 119.694 25.1307 120.343 26.0464C121.138 26.911 121.83 27.4847 122.71 27.6652C122.896 30.0513 123.997 31.7885 126.16 32.8256C127.732 33.5798 129.246 33.7011 130.846 33.1384C131.573 32.8826 132.009 32.7292 132.394 32.4303C133.676 33.2868 135.095 33.6047 136.55 33.0932C136.986 32.9397 137.568 32.7351 137.953 32.4362C138.723 31.8384 139.1 31.0521 139.426 30.1203C139.701 29.0431 139.736 28.2136 139.429 27.341C139.224 26.7593 139.071 26.323 138.772 25.9378L137.226 23.8667L137.894 22.978C138.649 21.4053 138.668 19.601 137.908 17.9069L139.799 17.2419L139.236 15.6422L136.909 16.4606ZM123.979 24.7676C122.953 24.6382 122.457 24.1588 122.441 23.1839C122.425 22.209 122.905 21.7135 123.88 21.6974L129.147 21.8057C127.23 23.7876 125.587 24.6923 123.979 24.7676ZM136.006 29.6891C135.236 30.2869 134.552 30.2006 133.808 29.4815C132.861 28.1806 132.538 26.3331 132.883 23.597L136.214 27.4915C136.862 28.4072 136.776 29.0913 136.006 29.6891ZM135.04 21.3673L133.349 19.3473L133.298 19.2019L132.708 18.919L132.563 18.9702L129.929 18.9161C130.357 18.2752 130.836 17.7797 131.564 17.5239C132.291 17.2681 133.12 17.3032 133.906 17.6803C134.693 18.0574 135.188 18.5369 135.444 19.264C135.307 19.8026 135.272 20.6321 135.04 21.3673Z"
      fill="#D4D4D7"
    />
  </svg>
);
