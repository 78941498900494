import React from 'react';
import styles from '@config/googleMapStyle';
import PropTypes from 'prop-types';
import { deferScript } from '@helpers';
import configuration from '@config/conf';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    (this.map = null), (this.bounds = null), (this.markers = []);
    ['setMarkers', 'initMap'].forEach((fn) => (this[fn] = this[fn].bind(this)));
  }

  UNSAFE_componentWillMount() {
    if (typeof google === 'undefined') {
      deferScript(
        `https://maps.googleapis.com/maps/api/js?key=${configuration.googleMapKey}&libraries=places`
      ).then(() => {
        this.setState({ googleMapsReady: true }, () =>
          this.initMap(this.props)
        );
      });
    } else {
      this.setState({ googleMapsReady: true }, () => this.initMap(this.props));
    }
  }

  initMap(props) {
    let { zoomLocation } = props;
    const { markers } = props;
    if (!zoomLocation && markers.length === 1) {
      const { lat, lng } = markers[0];
      zoomLocation = { lat, lng };
    }

    this.map = new google.maps.Map(this.mapRef, {
      center: zoomLocation,
      zoom: props.zoom,
      mapTypeControl: false,
      styles,
    });

    this.bounds = new google.maps.LatLngBounds();

    this.setMarkers(markers);

    if (markers.length > 1) {
      this.map.fitBounds(this.bounds);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.singleMarker) {
      if (nextProps.zoomLocation) {
        this.map?.setCenter(nextProps.zoomLocation);
        this.map?.setZoom(nextProps.zoom);
      } else {
        const { markers } = nextProps;

        if (markers.length === 1) {
          const { lat, lng } = markers[0];
          this.map?.setCenter({ lat, lng });
          this.map?.setZoom(nextProps.zoom);
        }
      }
    }
  }

  setMarkers(markers) {
    const clusterer = new MarkerClusterer({
      markers: [],
      map: this.map,
      renderer: {
        render: ({ count, position }) => {
          const svg = window.btoa(`
            <svg 
            fill="${window.colorPalette.primary[600]}" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 240 240">
              <circle cx="120" cy="120" r="60" />    
            </svg>`);
          return new google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new google.maps.Size(75, 75),
            },
            label: {
              text: String(count),
              color: 'white',
              fontSize: '26px',
            },
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          });
        },
      },
    });

    const locatorIcon = `<svg
    width="58"
    height="70"
    viewBox="0 0 58 70"
    fill="${window.colorPalette.primary[600]}"
    xmlns="http://www.w3.org/2000/svg"
    >
    <circle fill="${window.colorPalette.primary[600]}" cx="29" cy="29" r="29" />
    <circle fill="white" cx="29" cy="29" r="13" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.2031 46L28.998 70L4.6875 46H53.2031Z"
      fill="${window.colorPalette.primary[600]}"
    />
    </svg>`;
    markers.forEach((m) => {
      const marker = new google.maps.Marker({
        map: this.map,
        icon: {
          scale: this.props.hasInfoWindow ? 0 : 0.7,
          strokeWeight: 0,
          strokeOpacity: 0,
          fillColor: window.colorPalette.primary[600],
          fillOpacity: 1,
          width: 0,
          url: this.props.showLocationMarker
            ? 'data:image/svg+xml;charset=UTF-8;base64,' +
              window.btoa(locatorIcon)
            : 'data:image/svg+xml;charset=UTF-8;base64,',
        },
        title: m.name,
        position: new google.maps.LatLng(m.lat, m.lng),
      });
      let infowindow = null;

      if (this.props.hasInfoWindow) {
        infowindow = new google.maps.InfoWindow({
          content: this.props.buildInfoWindow(m),
        });
      }

      marker.addListener('click', () => {
        this.props.onClickMarker(m.id, m.lng, m.lat);
      });
      this.props.hasInfoWindow ? infowindow.open(this.map, marker) : null;
      clusterer.addMarker(marker);
      this.bounds.extend(marker.position);
    });
  }

  render() {
    const { height, className } = this.props;
    return (
      <div
        className={`map-container${className ? ` ${className}` : ''}`}
        style={{ width: '100%' }}>
        <div
          ref={(ref) => (this.mapRef = ref)}
          style={{ width: '100%', height }}
        />
      </div>
    );
  }
}

Map.defaultProps = {
  zoom: 13,
  onClickMarker: () => {},
  resetted: () => {},
  buildInfoWindow: () => {},
  hasInfoWindow: false,
  singleMarker: false,
  markers: [],
  className: '',
  height: 300,
  showLocationMarker: true,
};

Map.propTypes = {
  showLocationMarker: PropTypes.bool,
  zoom: PropTypes.number.isRequired,
  zoomLocation: PropTypes.object,
  markers: PropTypes.array.isRequired,
  onClickMarker: PropTypes.func.isRequired,
  resetted: PropTypes.func.isRequired,
  singleMarker: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  buildInfoWindow: PropTypes.func.isRequired,
  hasInfoWindow: PropTypes.bool.isRequired,
  primaryColor: PropTypes.string.isRequired,
};
