import React from 'react';
import PropTypes from 'prop-types';

export default class PlanSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="btn-container">
          <button
            style={{
              color: window.colorPalette.primary[600],
              borderColor: window.colorPalette.primary[600],
            }}
            color="primary"
            disabled
            onClick={() => this.props.handlePrev()}
            className="reverse">
            BACK
          </button>
          <button
            color="primary"
            style={{ backgroundColor: window.colorPalette.primary[600] }}
            onClick={() => {
              this.props.handleNext();
            }}>
            NEXT
          </button>
        </div>
      </React.Fragment>
    );
  }
}

PlanSelection.propTypes = {
  language: PropTypes.string,
  policy: PropTypes.object,
  event: PropTypes.object,
  user: PropTypes.object,
  calculateSubscription: PropTypes.func,
  setAvailableProducts: PropTypes.func,
  knownUserFields: PropTypes.object,
  setUserFields: PropTypes.func,
  setSubscription: PropTypes.func,
  userFields: PropTypes.object,
  registerUser: PropTypes.func,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
};
