import { connect } from 'react-redux';
import Ebag from './Ebag';

const mapStateToProps = (state) => {
  return {
    event: state.api.event.data,
    language: state.api.language.selected,
  };
};

export default connect(mapStateToProps, undefined)(Ebag);
