import React from 'react';
import NavigateModal from '../../../../layout/NavigateModal';
import PropTypes from 'prop-types';

export default class Sponsors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      linkToNavigateTo: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  navigateOutOfSiteHandler = () => {
    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  };

  setSiteToNavigateTo = (link) => {
    if (link.includes('bluetree')) {
      window.open(link);
    } else {
      this.setState({ linkToNavigateTo: link }, () =>
        this.navigateOutOfSiteHandler()
      );
    }
  };

  render() {
    const sponsorSettings = this.props.event.clientPanelSettings.sponsors;
    let sponsorSchema = this.props.event.clientPanelSponsorSettings || '{}';
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;

    try {
      sponsorSchema = JSON.parse(sponsorSchema);
    } catch (e) {
      sponsorSchema = {};
      console.error('Error parsing the sponsor schema!');
    }
    if (sponsorSettings.sponsorsSchema.length > 0) {
      return (
        <React.Fragment>
          {sponsorSettings.sponsorsTypes.map((type) => {
            return (
              <React.Fragment key={type.id}>
                {Boolean(sponsorSettings.sponsorsShowTypes) && (
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: 30,
                      fontWeight: 500,
                    }}>
                    {type.name}
                  </div>
                )}
                <div className="sponsor-section">
                  {sponsorSettings.sponsorsSchema
                    .sort((a, b) => a.index - b.index)
                    .map((sponsor) => {
                      if (sponsor.eventClientPanelSponsorTypeId === type.id) {
                        return (
                          <div key={sponsor.id} style={{ flexBasis: '31.3%' }}>
                            <img
                              src={
                                sponsor.clientSponsorImage[0]
                                  ? sponsor.clientSponsorImage[0].url
                                  : null
                              }
                              onClick={() =>
                                sponsor.url &&
                                this.setSiteToNavigateTo(sponsor.url)
                              }
                              style={{
                                cursor: sponsor.url ? 'pointer' : 'default',
                                flexBasis: '31.3%',
                                width: '100%',
                              }}
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              </React.Fragment>
            );
          })}
          {this.state.modalIsOpen && (
            <NavigateModal
              changeModalStatus={this.navigateOutOfSiteHandler}
              linkToNavigateTo={this.state.linkToNavigateTo}
              language={this.props.language}
              color={primaryColor}
            />
          )}
        </React.Fragment>
      );
    } else {
      if (sponsorSchema.sponsors && sponsorSchema.sponsors.length > 0) {
        return (
          <div className="sponsor-section">
            {sponsorSchema.sponsors.length > 0 &&
              sponsorSchema.sponsors.map((sponsor, i) => {
                return (
                  <div className="sponsor" key={i}>
                    <img
                      src={sponsor.image ? sponsor.image : null}
                      onClick={() => sponsor.url && window.open(sponsor.url)}
                      style={{
                        padding: 15,
                        cursor: sponsor.url ? 'pointer' : 'default',
                        maxWidth: '80%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                );
              })}
          </div>
        );
      } else {
        return null;
      }
    }
  }
}

Sponsors.propTypes = {
  event: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};
