import createBrowserHistory from 'history/createBrowserHistory';
let history = null,
  bsn = null;

export const initHistory = (basename) => {
  bsn = basename;
  history = createBrowserHistory({
    basename,
  });
};
export const getHistory = () => history;

export const getBasename = () => bsn;
