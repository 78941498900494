import React from 'react';
import Body from './Body/';
import PropTypes from 'prop-types';
import AppTopbar from './AppTopbar';
import AppMobileTopbar from './AppMobileTopbar';

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.featureKeyMap = {
      generalInfo: 'home',
      schedule: 'schedule',
      abstractManagement: 'abstracts',
      videos: 'videos',
      speakers: 'speakers',
      hotelManagement: 'accommodation',
      exhibition: 'exhibition',
      sponsors: 'sponsors',
      ebag: 'ebag',
      products: 'products',
    };
  }

  UNSAFE_componentWillMount() {
    this.props.loadEvent(
      this.props.eventId,
      this.props.policy,
      this.props.freeAccess
    );
  }

  render() {
    return (
      <div>
        <div className="bt-container">
          <AppTopbar
            {...this.props}
            featureKeyMap={this.featureKeyMap}
            language={this.props.language}
            policy={this.props.policy}
          />
          <AppMobileTopbar
            {...this.props}
            featureKeyMap={this.featureKeyMap}
            language={this.props.language}
            policy={this.props.policy}
          />
          {this.props.ready && <Body />}
        </div>
      </div>
    );
  }
}

Main.propTypes = {
  language: PropTypes.string.isRequired,
  loadEvent: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  policy: PropTypes.object,
  freeAccess: PropTypes.bool,
  ready: PropTypes.bool.isRequired,
};
