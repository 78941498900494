import { connect } from 'react-redux';
import Body from './Body';

const mapStateToProps = (state) => {
  return {
    menu: state.menu.items,
    active: state.menu.active,
    event: state.api.event.data,
    loading: state.api.event.fetching || state.api.speakers.fetching,
    showingSpeeches: state.api.sessions.showingSpeeches,
    showingVideo: state.api.videos.showingVideo,
    showingSpeaker: state.api.speakers.showingSpeaker,
    showingExhibitor: state.api.exhibition.showingExhibitor,
    showingHotel: state.api.hotels.showingHotel,
    showingProduct: state.api.products.showingProduct,
    showingAbstract: state.api.abstracts.showingAbstract,
    showingAbstractPreview: state.api.abstracts.showingAbstractPreview,
    showingPayment: state.api.payments.showingPayment || false,
    showingLogin: { active: state.menu.active === 'login' },
    language: state.api.language.selected,
  };
};

export default connect(mapStateToProps, undefined)(Body);
