import { connect } from 'react-redux';
import BookRoom from './BookRoom';
import { getCurrencySymbol } from '@helpers';
import {
  createHotelReservation,
  cancelHotelReservation,
  changeSection,
  showPayment,
  getUser,
} from '@actions';

const mapStateToProps = (state) => {
  return {
    currency: getCurrencySymbol(state.api.event.data.currencyIso),
    user: state.api.user.data,
    counter: state.api.counter,
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  showPayment: (products, info) => dispatch(showPayment(products, info)),
  cancelHotelReservation: (eventId, eventHotelId, reservationId) =>
    dispatch(cancelHotelReservation(eventId, eventHotelId, reservationId)),
  changeSection: (section) => dispatch(changeSection(section)),
  createHotelReservation: (
    eventHotelId,
    orgHotelRoomId,
    startDate,
    endDate,
    notes,
    rooms
  ) =>
    dispatch(
      createHotelReservation(
        eventHotelId,
        orgHotelRoomId,
        startDate,
        endDate,
        notes,
        rooms
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookRoom);
