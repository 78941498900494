import { connect } from 'react-redux';
import SpeakerInner from './SpeakerInner';
import { changeSection, loadSpeaker, showSpeaker, showVideo } from '@actions';

const mapStateToProps = (state) => {
  return {
    speaker: state.api.speakers.showingSpeaker.data,
    speakerId: state.api.speakers.showingSpeaker.speakerId,
    ready: state.api.speakers.showingSpeaker.ready,
    language: state.api.language.selected,
    event: state.api.event.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSection: (section) => dispatch(changeSection(section)),
  loadSpeaker: () => dispatch(loadSpeaker()),
  showSpeaker: (id) => dispatch(showSpeaker(id)),
  showVideo: (session, speech) => dispatch(showVideo(session, speech)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerInner);
