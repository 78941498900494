import React from 'react';
import PropTypes from 'prop-types';
import Speeches from '../../Schedule/Speeches/Speeches';
import NoImageIcon from '@public/no_img.svg';
import { AccountIconGray25 } from '@dynamic_assets/accounticon.svg';

export default class SpeakerInner extends React.Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const { loadSpeaker, speakerId } = this.props;
    loadSpeaker(speakerId);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.speakerId !== this.props.speakerId) {
      this.props.loadSpeaker(nextProps.speakerId);
    }
  }

  render() {
    let { speaker } = this.props;
    const { ready } = this.props;
    speaker = speaker[0];
    if (!ready) return null;
    const hasImage = speaker.speakerThumbnail[0];
    return (
      <div>
        <div className="profile">
          <div className="upper-area">
            <div className="left-side">
              <div className={`person-thumbnail`}>
                {hasImage ? (
                  <img
                    src={speaker.speakerThumbnail[0].url}
                    width="500"
                    height="500"
                  />
                ) : (
                  <AccountIconGray25 />
                )}
              </div>
            </div>
            <div className="right-side">
              <h2 className="speaker-name">
                {`${speaker.firstName} ${speaker.lastName}`}
              </h2>
              {speaker.jobTitle || speaker.institution ? (
                <div className="info">{`${speaker.jobTitle} - ${speaker.institution}`}</div>
              ) : null}

              <div className="resume">{speaker.shortBio}</div>
              <div className="back-cut" />
            </div>
          </div>
          {speaker.email && (
            <div className="bottom-area">
              <a href={`mailto:${speaker.email}`}>
                <i className="fa fa-envelope-o" />
              </a>
            </div>
          )}
        </div>
        <div className="profile-mobile">
          <div className={`person-thumbnail`}>
            <img
              src={hasImage ? speaker.speakerThumbnail[0].url : NoImageIcon}
              width="500"
              height="500"
            />
          </div>
          <h2 className="speaker-name">
            {`${speaker.firstName} ${speaker.lastName}`}
            {speaker.jobTitle || speaker.institution ? (
              <div className="info">{`${speaker.jobTitle} - ${speaker.institution}`}</div>
            ) : null}
          </h2>

          <div className="resume">{speaker.shortBio}</div>
        </div>

        {speaker.sessions.length > 0 && (
          <div className="speeches">
            <Speeches
              speeches={speaker.speeches}
              sessions={speaker.sessions}
              title={window.lang[this.props.language]['participatingSpeeches']}
              activeSpeaker={speaker.id}
              showSpeaker={this.props.showSpeaker}
              language={this.props.language}
              showVideo={this.props.showVideo}
              event={this.props.event}
            />
          </div>
        )}
      </div>
    );
  }
}

SpeakerInner.propTypes = {
  speakerId: PropTypes.number.isRequired,
  speaker: PropTypes.object,
  loadSpeaker: PropTypes.func.isRequired,
  changeSection: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  showSpeaker: PropTypes.func.isRequired,
  showVideo: PropTypes.func,
  event: PropTypes.object,
  language: PropTypes.string,
};
