import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadSpeakers, showSpeaker } from '@actions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AccountIconGray25 } from '@dynamic_assets/accounticon.svg';

class Speakers extends React.Component {
  componentDidMount() {
    this.props.loadSpeakers();
  }

  render() {
    const speakers = this.props.event.clientPanelSettings.speakers.keynote;

    return (
      <div className="row section speaker-cards-wrapper">
        <div className="">
          <p className="section-title">Key Speakers</p>
        </div>
        <div className="">
          <div className="card-container">
            {speakers &&
              speakers.map((s) => {
                const hasImage = s.speakerThumbnail[0];
                return (
                  <div
                    className="speaker-card"
                    key={s.id}
                    onClick={() => this.props.showSpeaker(s.id)}>
                    <div className="card-content">
                      {hasImage ? (
                        <LazyLoadImage
                          className="speaker-img"
                          src={s.speakerThumbnail[0].url}
                          alt="Avatar"
                        />
                      ) : (
                        <AccountIconGray25 />
                      )}

                      <div className="card-header-custom">
                        <h4>
                          <b>{s.firstName + ' ' + s.lastName}</b>
                        </h4>
                        <div className="card-subheader-custom">
                          <p>
                            {s.shortBio?.length > 128
                              ? s.shortBio.slice(0, 128) + '...'
                              : s.shortBio}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
Speakers.propTypes = {
  event: PropTypes.object.isRequired,
  loadSpeakers: PropTypes.func.isRequired,
  speakers: PropTypes.array,
  showSpeaker: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  loadSpeakers: () => dispatch(loadSpeakers()),
  showSpeaker: (id) => dispatch(showSpeaker(id)),
});
export default connect(undefined, mapDispatchToProps)(Speakers);
