import React from 'react';
import PropTypes from 'prop-types';

export default class KMBLoader extends React.Component {
  constructor(props) {
    super(props);
    this.html = new Array();
    const margin = props.margin;

    const rows = props.rows;
    for (let i = 0; i < rows; i++) {
      const lineHeight = props.thin ? 1 : 'normal';
      this.html.push(
        <li
          key={'loader-bar-' + i}
          style={{
            width: props.width,
            margin: `${margin}px 0 ${margin}px`,
            height: props.height ? `${props.height}px` : lineHeight,
          }}>
          &nbsp;
        </li>
      );
    }
  }

  render() {
    return <ul className="kmb-loader">{this.html}</ul>;
  }
}

KMBLoader.propTypes = {
  margin: PropTypes.number,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.any,
  height: PropTypes.number,
  thin: PropTypes.bool,
};

KMBLoader.defaultProps = {
  rows: 3,
  margin: 10,
  width: null,
  thin: false,
};
