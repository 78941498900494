import React from 'react';
import PropTypes from 'prop-types';
import { getType } from '@helpers';
import Select from '@components/layout/Select';
import Radio from '@components/layout/Radio';
import Recaptcha from '@components/layout/Recaptcha';
import CircularProgress from '@material-ui/core/CircularProgress';
import configuration from '@config/conf';
const queryString = require('query-string');

export default class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      verified: null,
      completed: 0,
      info: null,
      submitting: false,
      valid: null,
    };
    this.query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    this.onChange = this.onChange.bind(this);
    this.getJSX = this.getJSX.bind(this);
    this.userHasPassword = false;
    this.userIsRegistered = false;
    this.fields = [];
    this.props.setCaptchaToken(null, 'questionnaire');
  }

  UNSAFE_componentWillMount() {
    // ckeck token if provided
    this.props
      .validateToken(this.query.t)
      .then((questionnaire) => {
        const fields = questionnaire.data;
        for (const i in fields) {
          if (fields[i].type === 'dropdown')
            if (fields[i].hasOwnProperty('value')) {
              if (typeof fields[i].value === 'string') {
                try {
                  fields[i].value = JSON.parse(fields[i].value);
                } catch (e) {
                  console.error(e);
                }
              }
            }
          const field =
            fields[i].type === 'dropdown'
              ? {
                  key: i,
                  ...fields[i],
                  options: fields[i].value,
                  value: fields[i].data,
                }
              : { key: i, ...fields[i], ...{ value: fields[i].data } };

          fields[i] =
            fields[i].type === 'dropdown'
              ? {
                  ...fields[i],
                  options: fields[i].value,
                  value: fields[i].data,
                }
              : { ...fields[i], ...{ value: fields[i].data } };
          this.fields.push(field);
        }
        let valid = true;
        for (const [k, f] of Object.entries(fields)) {
          if (k === name) {
            fields[k].value = f.value;
          }

          if (f.required && !f.value) {
            valid = false;
          }
        }
        this.setState({
          verified: 1,
          fields,
          valid,
        });
      })
      .catch(() => {
        this.setState({ verified: 0 });
      });
  }

  onChange(e, type) {
    let { value } = e.target;
    const { name } = e.target;
    if (type === 'yes_no') {
      if (value == 1 || value == 'yes') {
        value = 'yes';
      } else {
        value = 'no';
      }
    }
    const fields = Object.assign({}, this.state.fields);
    let valid = true;
    for (const [k, f] of Object.entries(fields)) {
      if (k === name) {
        fields[k].value = f.value = value;
      }

      if (f.required && !f.value) {
        valid = false;
      }
    }
    this.setState({
      fields,
      valid,
    });
  }

  getJSX(f) {
    const { key: k } = f;
    switch (f.type) {
      case 'dropdown': {
        const options = {};
        [...this.state.fields[f.key].options].map((v) => (options[v] = v));

        return (
          <span>
            <label>{`${f.name}${f.required ? ' *' : ''}`}</label>
            <Select
              options={options}
              value={this.state.fields[f.key].value}
              onChange={(value) =>
                this.onChange({ target: { name: k, value } })
              }
            />
          </span>
        );
      }

      case 'text': {
        return (
          <span>
            <label>{`${f.name}${f.required ? ' *' : ''}`}</label>
            <input
              type={f.type}
              className="form-control"
              value={this.state.fields[f.key].value}
              onChange={this.onChange}
              name={f.key}
              placeholder={`${f.name}${f.required ? ' *' : ''}`}
            />
          </span>
        );
      }
      case 'textarea': {
        return (
          <span>
            <label>{`${f.name}${f.required ? ' *' : ''}`}</label>
            <textarea
              className="form-control"
              value={this.state.fields[f.key].value}
              rows="5"
              onChange={this.onChange}
              name={f.key}
              placeholder={`${f.name}${f.required ? ' *' : ''}`}
            />
          </span>
        );
      }
      case 'yes_no': {
        let val = this.state.fields[f.key].value;
        if (val === undefined) {
          val = '';
        } else {
          val = val == 1 || val == 'yes' ? 'yes' : 'no';
        }
        return (
          <span>
            <label>{`${f.name}${f.required ? ' *' : ''}`}</label>
            <div className="kmb-radio">
              <Radio
                id={'radio-input-' + k}
                checked={val}
                onChange={(value) =>
                  this.onChange({ target: { name: k, value } }, 'yes_no')
                }
              />
            </div>
          </span>
        );
      }
    }
  }

  render() {
    const { visibility } = this.props;
    if (this.state.verified === 0) {
      return (
        <form>
          <span>Expired or invalid token.</span>
          <div className="form-group submit-container">
            <button
              type="button"
              className={`btn login btn-primary`}
              onClick={() =>
                window.location.replace(location.href.split('?')[0])
              }>
              {window.lang[this.props.language]['ok']}
            </button>
          </div>
        </form>
      );
    } else if (this.state.completed) {
      return (
        <form>
          <span>
            {window.lang[this.props.language]['completedSuccessfully']}
          </span>
          <div className="form-group submit-container">
            <button
              type="button"
              className={`btn login btn-primary`}
              onClick={() =>
                window.location.replace(location.href.split('?')[0])
              }>
              OK
            </button>
          </div>
        </form>
      );
    }
    return (
      <form>
        <div className="row" style={{ width: '100%' }}>
          {(() => {
            if (this.state.verified === null) {
              return <span>Verifing request...</span>;
            } else if (this.state.verified) {
              return this.fields.map((f, index) => {
                const type = getType(f.key, visibility);
                return (
                  <div key={`field-${index}`} className="form-group col-sm-6">
                    {f.hasOwnProperty('type') ? (
                      this.getJSX(f)
                    ) : (
                      <span>
                        <label>{`${f.name}${f.required ? ' *' : ''}`}</label>
                        <input
                          disabled={f.key === 'email'}
                          type={type}
                          className="form-control"
                          value={this.state.fields[f.key].value}
                          onChange={this.onChange}
                          name={f.key}
                          placeholder={`${f.name}${f.required ? ' *' : ''}`}
                        />
                      </span>
                    )}
                  </div>
                );
              });
            }
          })()}
        </div>
        {this.props.errorMessage && (
          <div className="alert danger">{this.props.errorMessage}</div>
        )}
        <div className="form-group submit-container">
          {this.props.questionnaireFailedAttempts >=
            configuration.allowedNoCaptcha && (
            <Recaptcha componentToVerify="questionnaire" />
          )}
          <button
            disabled={
              this.props.questionnaireFailedAttempts >=
                configuration.allowedNoCaptcha && !this.props.recaptchaIsValid
            }
            type="button"
            className={`btn login btn-primary${
              this.state.valid ? '' : ' disabled'
            }`}
            onClick={() => {
              this.setState({ submitting: true });
              this.props
                .submitQuestionnaire(this.state.fields, this.query.t)
                .then((response) => {
                  if (response) {
                    this.setState({ completed: 1, submitting: false });
                  }
                })
                .catch((e) => {
                  this.setState({ submitting: false });
                  console.error(e);
                });
            }}>
            {this.state.submitting ? (
              <CircularProgress size={24} className="submit-loader" />
            ) : (
              window.lang[this.props.language]['submit']
            )}
          </button>
        </div>
      </form>
    );
  }
}

SignupPage.propTypes = {
  policy: PropTypes.object.isRequired,
  visibility: PropTypes.object.isRequired,
  submitQuestionnaire: PropTypes.func.isRequired,
  recaptchaIsValid: PropTypes.bool.isRequired,
  questionnaireFailedAttempts: PropTypes.number.isRequired,
  errorMessage: PropTypes.string,
  language: PropTypes.string,
  setCaptchaToken: PropTypes.func,
  validateToken: PropTypes.func,
};
