import React from 'react';
import PropTypes from 'prop-types';

export const PaymentsIcon = ({ color }) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.44444 2.25C2.66777 2.25 2 2.90027 2 3.75V5.25H22V3.75C22 2.90027 21.3322 2.25 20.5556 2.25H3.44444ZM22 7.25H2V13.75C2 14.5997 2.66777 15.25 3.44444 15.25H20.5556C21.3322 15.25 22 14.5997 22 13.75V7.25ZM0 6.25V13.75C0 15.6617 1.52106 17.25 3.44444 17.25H20.5556C22.4789 17.25 24 15.6617 24 13.75V6.25V3.75C24 1.83831 22.4789 0.25 20.5556 0.25H3.44444C1.52106 0.25 0 1.83831 0 3.75V6.25ZM4.9317 11.256C4.92843 10.7272 5.35444 10.2958 5.88322 10.2924L10.2445 10.2642C10.7733 10.2608 11.2046 10.6866 11.2079 11.2154C11.2112 11.7442 10.7852 12.1756 10.2564 12.1791L5.89506 12.2073C5.36628 12.2107 4.93497 11.7848 4.9317 11.256Z"
      fill={color}
    />
  </svg>
);

PaymentsIcon.propTypes = {
  color: PropTypes.string,
};
