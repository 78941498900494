import React from 'react';
import PropTypes from 'prop-types';

export const PrimaryButton = (props) => {
  return (
    <button
      {...props}
      className={`btn ${props.className ? props.className : ''}`}
      style={{ background: window.colorPalette.primary[600], color: 'white' }}>
      {props.children}
    </button>
  );
};

PrimaryButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};
