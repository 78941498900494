import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

const Player = (props) => {
  return (
    <div className="react-jw-player-container" style={{ padding: 15 }}>
      <ReactPlayer
        url={props.content}
        controls={true}
        className="react-player"
        width="100%"
        height="100%"
        playing={false}
      />
    </div>
  );
};

Player.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Player;
