import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Backdrop } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextField from '@material-ui/core/TextField';
import CopyToClipboard from '@dynamic_assets/copyToClipboard.svg.js';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

const ShareComponent = (props) => {
  return (
    <React.Fragment>
      <Backdrop
        className="backdrop-share"
        open={props.open}
        onClick={props.handleClose}>
        <div className="share-card">
          <div className="top-header">
            <div className="share-title">
              {lang[props.language][props.shareTitle]}
            </div>
            <IconButton
              className="close-share-wrapper"
              onClick={(e) => {
                e.stopPropagation();
                props.handleClose();
              }}>
              <CloseRoundedIcon className="close-share" />
            </IconButton>
          </div>
          <div className="share-body">
            <div className="shareable-media-wrapper">
              <FacebookShareButton url={props.shareUrl}>
                <FacebookIcon size={30} round={true} />
              </FacebookShareButton>
              <LinkedinShareButton url={props.shareUrl}>
                <LinkedinIcon size={30} round={true} />
              </LinkedinShareButton>
              <TwitterShareButton url={props.shareUrl}>
                <TwitterIcon size={30} round={true} />
              </TwitterShareButton>
            </div>
            <div className="url-wrapper">
              <div className="url-title">
                {lang[props.language]['copyLink']}
              </div>
              <div className="url-copy-container">
                <TextField
                  className="url-field"
                  autoFocus={true}
                  disabled
                  variant="outlined"
                  value={props.shareUrl}
                />
                <IconButton
                  className="copy-icon-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(props.shareUrl);
                  }}>
                  <CopyToClipboard
                    className="copy-icon"
                    color={window.colorPalette.primary[600]}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Backdrop>
    </React.Fragment>
  );
};

ShareComponent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  language: PropTypes.string,
  shareTitle: PropTypes.string,
  shareUrl: PropTypes.string,
};

ShareComponent.defaultProps = {
  shareTitle: 'share',
};

const mapStateToProps = (state) => {
  return {
    language: state.api.language.selected,
  };
};

export default connect(mapStateToProps)(ShareComponent);
