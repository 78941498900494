import React from 'react';
import PropTypes from 'prop-types';

export default class CallToAction extends React.Component {
  render() {
    return (
      <div className="row cta-container">
        <div className="col-sm-7" style={{ paddingLeft: '0px' }}>
          {(this.props.session.callToActionTitle || '').length > 0 && (
            <h6 className="col-sm-12 cta-title">
              {this.props.session.callToActionTitle}
            </h6>
          )}
          <p className="col-sm-12 cta-desc">
            {this.props.session.callToActionDesc}
          </p>
        </div>
        <div className="col-sm-5 cta-url-container">
          <div
            onClick={() =>
              this.props.setSiteToNavigateTo(this.props.session.callToActionUrl)
            }
            target="_blank"
            className="btn btn-light col-sm-12 cta-btn"
            style={{ backgroundColor: this.props.color }}>
            {this.props.session.callToActionButton?.length <= 0
              ? window.lang[this.props.language]['clickHereCaps']
              : this.props.session.callToActionButton}
          </div>
        </div>
      </div>
    );
  }
}

CallToAction.propTypes = {
  session: PropTypes.object.isRequired,
  language: PropTypes.string,
  color: PropTypes.string,
  setSiteToNavigateTo: PropTypes.func,
};
