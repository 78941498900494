import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REMOVING_NOTIFICATION,
} from '@actions';
let index = 0;

const notifications = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const newState = [
        ...[
          {
            message: action.message,
            className: action.className,
            index,
          },
        ],
        ...state,
      ];
      index++;
      return newState;
    }

    case REMOVING_NOTIFICATION: {
      return [...state].map((n, i) => {
        if (i === state.length - 1) {
          n.className += ' removing';
        }
        return n;
      });
    }

    case REMOVE_NOTIFICATION: {
      return [...state].filter((n, i) => i < state.length - 1);
    }

    default:
      return state;
  }
};

export default notifications;
