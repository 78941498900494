import React from 'react';
import PropTypes from 'prop-types';

export const AbstractsIcon = ({ color }) => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895429 0.895432 0 2 0H9.3151C9.84312 0 10.3497 0.208804 10.7244 0.580869L13.4093 3.24712C13.7874 3.62259 14 4.13341 14 4.66626V12C14 13.1046 13.1046 14 12 14H8V14.6816C8.14808 14.7672 8.28753 14.8733 8.41421 15L11.7071 18.2929C12.0976 18.6834 12.0976 19.3166 11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071L8 17.4142V19C8 19.5523 7.55228 20 7 20C6.44772 20 6 19.5523 6 19V17.4142L3.70711 19.7071C3.31658 20.0976 2.68342 20.0976 2.29289 19.7071C1.90237 19.3166 1.90237 18.6834 2.29289 18.2929L5.58579 15C5.71247 14.8733 5.85192 14.7672 6 14.6816V14H2C0.895429 14 0 13.1046 0 12V2ZM2 2V12L12 12V7H9C7.89543 7 7 6.10457 7 5V2H2ZM9 2V5H12V4.66626L9.3151 2H9Z"
      fill={color}
    />
  </svg>
);

AbstractsIcon.propTypes = {
  color: PropTypes.string,
};
