import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';

export default class SuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.handleGetReceipt = this.handleGetReceipt.bind(this);
  }

  handleGetReceipt() {
    this.setState({ isLoading: true });
    this.props
      .getReceipt()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="page-container">
        <div className="empty-results">
          <span className="icon-check" />
          <p>{window.lang[this.props.language]['transactionCompleted']}</p>
          <span>
            <p>
              {window.lang[this.props.language]['orderId']}:{' '}
              {this.props.orderId}
            </p>
            <p>
              {window.lang[this.props.language]['paymentReference']}:{' '}
              {this.props.paymentRef}
            </p>
          </span>
          {this.state.isLoading && <Loader size="32px" />}
          {!this.state.isLoading && (
            <p
              className="receipt"
              onClick={this.handleGetReceipt}
              style={{ color: '#02A9F4', fontSize: '13px' }}>
              {window.lang[this.props.language]['downloadReceipt']}
            </p>
          )}
          <button
            className="btn btn-primary"
            onClick={() => this.props.goToBilling('billing')}>
            {window.lang[this.props.language]['goToPayments']}
          </button>
        </div>
      </div>
    );
  }
}

SuccessPage.propTypes = {
  language: PropTypes.string,
  goToBilling: PropTypes.func,
  orderId: PropTypes.number,
  paymentRef: PropTypes.string,
  getReceipt: PropTypes.func.isRequired,
  token: PropTypes.string,
  validateToken: PropTypes.func,
};
