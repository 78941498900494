import React from 'react';
import PropTypes from 'prop-types';

export default class ErrorPage extends React.Component {
  render() {
    return (
      <div className="page-container">
        <div className="empty-results">
          <span className="icon-close" />
          <p>{window.lang[this.props.language]['transactionFailed']}</p>
          <span>
            <p>
              {window.lang[this.props.language]['transactionFailedMessage1']}
              <p>
                {window.lang[this.props.language]['transactionFailedMessage2']}
              </p>
            </p>
          </span>
          <button
            className="btn btn-primary"
            onClick={() => this.props.goToBilling('billing')}>
            {window.lang[this.props.language]['goToPayments']}
          </button>
        </div>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  language: PropTypes.string,
  goToBilling: PropTypes.func,
};
