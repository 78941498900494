import moment from 'moment';
import { createStore } from 'redux';
import rootReducer from '../reducers';

export const dateFormat = 'DD-MM-Y';

export const isArray = (variable) => {
  return variable?.constructor === Array;
};
export const findByTestAttr = (component, attr) => {
  const wrapper = component.find(`[data-test='${attr}']`);
  return wrapper;
};
export const storeFactory = (initialState) => {
  return createStore(rootReducer, initialState);
};
export const isFileArray = (variable) => {
  if (variable) {
    variable[0] instanceof File;
    return true;
  } else {
    return false;
  }
};

export const isValidEmail = (email) => {
  // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case 'EUR': {
      return '€';
    }
  }
};

export const isNumeric = (variable) => {
  const test = variable.trim();
  return test && !isNaN(test);
};

export const saveByteArray = (reportName, buffer) => {
  const blob = new Blob([buffer], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  // link.click();
  link.dispatchEvent(
    new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window })
  );
};
export const checkMeta = (meta, prevMeta, cache = true) => {
  if (isEmpty(prevMeta)) {
    return false;
  }

  for (const [key, value] of Object.entries(meta)) {
    if (!prevMeta.hasOwnProperty(key)) {
      cache = false;
      break;
    }
    if (value !== prevMeta[key]) {
      cache = false;
      break;
    }
  }

  return cache;
};

export const isEmpty = (variable) => {
  if (!variable) {
    return true;
  }
  if (variable.constructor === Object) {
    return Object.keys(variable).length === 0;
  } else if (variable.constructor === Array) {
    return variable.length === 0;
  }
  return false;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const buildTokenName = (eventId, policyId) => {
  return `bluetree_client_eid:${eventId}_pid:${policyId}`;
};

export const go404 = () => {
  window.location.replace('/client/404.html');
};

export const isObject = (variable) =>
  variable !== null && typeof variable === 'object' && !Array.isArray(variable);

export const formatRange = (startDate, endDate) => {
  const start = moment(startDate),
    end = moment(endDate);

  if (start.month() === end.month() && start.year() === end.year()) {
    // case 1.. the event starts
    // and ends in the same month
    // in the same year

    if (start.date() === end.date()) {
      return `${start.date()} ${end.format('MMMM')} ${end.year()}`;
    }

    return `${start.date()} - ${end.date()} ${end.format(
      'MMMM'
    )} ${end.year()}`;
  } else {
    if (start.year() === end.year()) {
      return `${start.date()} ${start.format(
        'MMMM'
      )} - ${end.date()} ${end.format('MMMM')} ${end.year()}`;
    } else {
      return `${start.date()} ${start.format(
        'MMMM'
      )} ${start.year()} - ${end.date()} ${end.format('MMMM')} ${end.year()}`;
    }
  }
};

export const buildSessionsCalendar = (event, sessions) => {
  let startDate = moment(event.startDate.tz);
  const endDate = moment(event.endDate.tz);
  let diffDays = endDate.diff(startDate, 'days') + 2;

  if (startDate.isSame(endDate, 'days')) {
    diffDays--;
  }

  let additional = 0;
  if (diffDays > 7) {
    const mod = diffDays % 7;
    additional = mod === 0 ? 0 : 7 - mod;
  } else {
    additional = 7 - diffDays;
  }

  const calendar = { weeks: {}, activeDay: '0', activeWeek: '1' };
  let indexWeek = 1;
  let index = 0;

  while (startDate.isSameOrBefore(endDate, 'days')) {
    const today = moment().diff(startDate, 'days') === 0;
    if (today) {
      calendar.activeWeek = indexWeek;
      calendar.activeDay = index - 1;
    }

    index++;
    if (index > 7) {
      index = 1;
      indexWeek++;
    }

    const weekKey = `week_${indexWeek}`;
    if (!calendar.weeks.hasOwnProperty(weekKey)) {
      calendar.weeks[weekKey] = new Array();
    }
    calendar.weeks[weekKey].push({
      moment: startDate,
      sessions: [...sessions].filter((s) => {
        return (
          startDate.format('DD/MM/YYYY') ===
            moment(s.startDate.tz).format('DD/MM/YYYY') ||
          startDate.format('DD/MM/YYYY') ===
            moment(s.endDate.tz).format('DD/MM/YYYY') ||
          startDate.isBetween(
            moment(s.startDate.tz),
            moment(s.endDate.tz),
            'day'
          )
        );
      }),
      disabled: false,
      today,
    });

    startDate = startDate.clone().add(1, 'day');
  }

  if (additional > 0) {
    const targetKey = Object.keys(calendar.weeks).pop();
    let date = startDate.clone();
    for (let i = 0; i < additional; i++) {
      calendar.weeks[targetKey].push({
        moment: date,
        sessions: [],
        disabled: true,
      });
      date = date.clone().add(1, 'day');
    }
  }

  return calendar;
};

export const deferScript = (src) => {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
};

export const getType = (fieldName, visibility) => {
  switch (fieldName) {
    case 'email': {
      return fieldName;
    }

    case 'password':
    case 'confirmPassword': {
      return visibility[fieldName] ? 'text' : 'password';
    }

    default:
      return 'text';
  }
};

export const checkIfConditionIsFulfilled = (field, userInfo = {}) => {
  if (!field?.conditionSchema?.filters) return true;

  // or blocks
  return field.conditionSchema.filters
    ?.map((conditionBlock) => {
      // and blocks
      return conditionBlock
        .map((condition) => {
          const valueToCheck =
            condition?.type === 'yes_no'
              ? userInfo[condition.key]?.value == 'yes' ||
                userInfo[condition.key] == '1'
                ? '1'
                : '0'
              : userInfo[condition.key]?.value || userInfo[condition.key];
          if (condition.operator === '=') {
            if (valueToCheck != (condition.rendered_value || condition.value))
              return false;
            return true;
          }
          if (valueToCheck != (condition.rendered_value || condition.value))
            return true;
          return false;
        })
        .reduce((acc, curr) => acc && curr, true);
    })
    .reduce((acc, curr) => acc || curr, false);
};

export const bluetreeInternalURLs = [
  'staging-client.bluetree.events',
  'medlive-gr-assets.s3.amazonaws.com',
  'client.bluetree.events',
  process.env.REACT_APP_IS_LOCAL_HOST && 'http://localhost:3002',
];
