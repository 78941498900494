import React from 'react';
import PropTypes from 'prop-types';

export default class Agenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roomEnd: 2,
      roomStart: 0,
      left: 0,
      originalOffset: 0,
      touchStartX: 0,
      prevTouchX: 0,
      beingTouched: false,
      activeRoomNames: [],
      activeRoomIds: [],
      reachedStart: true,
      reachedEnd: Object.keys(props.sessionRooms).length <= 3 ? true : false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clearStatus) {
      this.setState({ activeRoomIds: [], activeRoomNames: [] });
    }
  }
  handleMove(clientX) {
    if (this.state.beingTouched) {
      const touchX = clientX;
      const deltaX =
        touchX - this.state.touchStartX + this.state.originalOffset;

      if (deltaX < -70) {
        this.nextRoomGroup();
      } else if (deltaX > 70) {
        this.previousRoomGroup();
      }
      this.setState({
        left: deltaX,
        prevTouchX: touchX,
      });
    }
  }
  handleStart(clientX) {
    this.setState({
      originalOffset: this.state.left,
      touchStartX: clientX,
      beingTouched: true,
    });
  }
  handleEnd() {
    this.setState({
      touchStartX: 0,
      beingTouched: false,
    });
  }
  conditionalLoadRooms() {
    if (this.state.activeRoomIds.length === 1) {
      this.props.loadSessions({ roomId: this.state.activeRoomIds[0] });
    } else if (this.state.activeRoomIds.length > 1) {
      this.props.loadSessions({ roomIds: this.state.activeRoomIds });
    } else {
      this.props.loadSessions();
    }
  }
  addRoomToFilters(name, id) {
    if (this.state.activeRoomNames.includes(name)) {
      this.setState(
        {
          activeRoomNames: this.state.activeRoomNames.filter(
            (rName) => rName !== name
          ),
          activeRoomIds: this.state.activeRoomIds.filter((rId) => rId !== id),
        },
        () => {
          if (
            this.state.activeRoomIds.length === 0 &&
            this.state.activeRoomNames.length === 0
          ) {
            this.props.setRoomSelected(false);
          } else {
            this.props.setRoomSelected();
          }
          this.conditionalLoadRooms();
        }
      );
    } else {
      this.setState(
        {
          activeRoomNames: this.state.activeRoomNames.concat(name),
          activeRoomIds: this.state.activeRoomIds.concat(id),
        },
        () => {
          this.props.setRoomSelected();
          this.conditionalLoadRooms();
        }
      );
    }
  }
  checkIfSameRoom(id) {
    if (this.state.activeRoomIds.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  nextRoomGroup() {
    this.state.roomEnd < Object.keys(this.props.sessionRooms).length
      ? this.setState(
          {
            roomEnd: this.state.roomEnd + 3,
            roomStart: this.state.roomStart + 3,
          },
          () => {
            if (
              this.state.roomEnd >= Object.keys(this.props.sessionRooms).length
            ) {
              this.setState({ reachedEnd: true, reachedStart: false });
            } else {
              this.setState({ reachedEnd: false, reachedStart: false });
            }
          }
        )
      : null;
  }
  previousRoomGroup() {
    this.state.roomStart > 1
      ? this.setState(
          {
            roomEnd: this.state.roomEnd - 3,
            roomStart: this.state.roomStart - 3,
          },

          () => {
            if (this.state.roomStart < 3) {
              this.setState({ reachedEnd: false, reachedStart: true });
            } else {
              this.setState({ reachedEnd: false, reachedStart: false });
            }
          }
        )
      : null;
  }

  filterSelector(i, rName) {
    if (i <= 2) {
      return rName;
    }
    if (i === 3) {
      return '....';
    }
    if (i > 4) {
      return null;
    }
  }
  render() {
    const { sessionRooms } = this.props;

    return (
      <div className="rooms">
        <div className="room-title">
          {`${window.lang[this.props.language]['room']} `}
          {this.state.activeRoomNames.map((rName, i) => {
            return (
              <span className="selected-filters" key={i}>
                {this.filterSelector(i, rName)}
              </span>
            );
          })}
        </div>
        <div className="rooms-container">
          <div className="arrow-card-wrapper">
            <div
              className={`arrow-card ${this.state.reachedStart && 'disabled'}`}>
              <span
                className={`icon-arrow-left`}
                onClick={() => this.previousRoomGroup()}
              />
            </div>
          </div>
          {Object.keys(sessionRooms).map((key, i) => {
            if (i <= this.state.roomEnd && i >= this.state.roomStart) {
              return (
                <div
                  key={key}
                  className={`room-card-wrapper`}
                  onTouchStart={(touchStartEvent) =>
                    this.handleStart(touchStartEvent.targetTouches[0].clientX)
                  }
                  onTouchMove={(touchMoveEvent) =>
                    this.handleMove(touchMoveEvent.targetTouches[0].clientX)
                  }
                  onTouchEnd={() => this.handleEnd()}>
                  <div
                    className={`room-card ${
                      this.checkIfSameRoom(key) && 'active'
                    }`}
                    onClick={() =>
                      // chaining set state, so we can create a better UI
                      // simulating a faster response in case our request takes time
                      // to respond and provide us with a key
                      {
                        this.addRoomToFilters(sessionRooms[key], key);
                      }
                    }>
                    {sessionRooms[key]}
                  </div>
                </div>
              );
            }
          })}
          <div className="arrow-card-wrapper">
            <div
              className={`arrow-card ${this.state.reachedEnd && 'disabled'}`}>
              <span
                className={`icon-arrow-right`}
                onClick={() => this.nextRoomGroup()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Agenda.propTypes = {
  sessionRooms: PropTypes.object.isRequired,
  loadSessions: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  clearStatus: PropTypes.bool,
  setRoomSelected: PropTypes.func,
};
