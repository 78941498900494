import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { changeSection, getSchema, getAbstract } from '@actions';
import { bytesToMegabytes, isValidFileType } from '@helpers/fileHelpers';
import NoImageIcon from '@public/no_img.svg';
import AbstractHeader from './CardComponents/AbstractHeader';
import AbstractInfo from './CardComponents/AbstractInfo';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ShareComponent from '@components/layout/ShareComponent';
import KMBLoader from '@components/layout/KMBLoader';
import FileViewer from '@components/layout/FileViewer';

const PreviewAbstractCard = (props) => {
  window.scrollTo(0, 0);

  //!!! TEST AND/OR TEMPORARY PLACEHOLDER VARIABLES !!! //
  const withThumbnail = true;
  //!!! TEST AND/OR TEMPORARY PLACEHOLDER VARIABLES !!! //

  const [shareComponentOpen, setShareComponentOpen] = React.useState(false);
  const [fileViewerOpen, setFileViewerOpen] = React.useState(false);

  React.useEffect(() => {
    props.getAbstract(props.eventId, props.abstractId);
    props.getSchema(props.orgId, props.eventId, props.eventAbstractSchemaId);
  }, []);

  const publishedAbstract =
    props.abstract?.publishedAbstractFile?.length > 0
      ? props.abstract.publishedAbstractFile[0]
      : null;

  const canViewFile = publishedAbstract && isValidFileType(publishedAbstract);

  const publishedAbstractThumbnail =
    props.abstract?.publishedAbstractThumbnail?.length > 0
      ? props.abstract.publishedAbstractThumbnail[0]
      : null;

  const navigateBack = () => {
    props.changeSection('abstractManagement', true);
  };

  const getAbstractShareUrl = () => window.location.href;

  const openShareComponent = () => {
    setShareComponentOpen(true);
  };

  return (
    <React.Fragment>
      {props.fetching ? (
        <div className="single-abstract-card-wrapper">
          <div className="navigate-back-abstracts" onClick={navigateBack}>
            <ArrowBackIcon />
            {lang[props.language]['backToPublishedAbstracts']}
          </div>
          <KMBLoader height={400} rows={1}></KMBLoader>
        </div>
      ) : (
        <div className="single-abstract-card-wrapper">
          <div className="navigate-back-abstracts" onClick={navigateBack}>
            <ArrowBackIcon />
            {lang[props.language]['backToPublishedAbstracts']}
          </div>
          <div className={`abstract-card abstract-${props.abstract?.id}`}>
            {withThumbnail && (
              <div className="thumbnail-wrapper">
                {publishedAbstractThumbnail ? (
                  <img
                    className={`warning-icon`}
                    src={publishedAbstractThumbnail.url}
                  />
                ) : (
                  <img className={`warning-icon`} src={NoImageIcon} />
                )}
              </div>
            )}
            <div
              className={`abstract-info ${
                withThumbnail ? '' : 'no-thumbnail'
              }`}>
              <AbstractHeader abstract={props.abstract} />
              <div className="card-section-divider" />
              <AbstractInfo abstract={props.abstract} />
              <div className="abstract-main-text show-full single-abstract-preview">
                {props.abstract?.abstractText}
              </div>
              <div className="abstract-action-bar single-abstract-preview">
                <div className="left-side-actions">
                  <div className="top-actions preview-card">
                    <Button
                      disabled={!canViewFile}
                      className="view-file"
                      onClick={() => setFileViewerOpen(true)}
                      variant="contained">
                      {lang[props.language]['viewFile']}
                    </Button>

                    <div className="download-wrapper">
                      <Button
                        disabled={!publishedAbstract}
                        onClick={() => window.open(publishedAbstract?.url)}
                        className="download-file"
                        variant="contained">
                        {lang[props.language]['download']}
                      </Button>
                      {publishedAbstract && publishedAbstract.size && (
                        <div className="file-size-text preview">
                          {`${
                            lang[props.language]['fileSize']
                          } (${bytesToMegabytes(publishedAbstract.size)}MB)`}
                        </div>
                      )}
                    </div>
                    {publishedAbstract && publishedAbstract.size && (
                      <div className="file-size-text preview-mobile">
                        {`${
                          lang[props.language]['fileSize']
                        } (${bytesToMegabytes(publishedAbstract.size)}MB)`}
                      </div>
                    )}
                    <Button
                      className="share-abstract"
                      onClick={openShareComponent}
                      variant="contained">
                      <ShareOutlinedIcon />
                      {lang[props.language]['share']}...
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ShareComponent
            open={shareComponentOpen}
            shareTitle="shareAbstract"
            shareUrl={getAbstractShareUrl()}
            handleClose={() => setShareComponentOpen(false)}
          />
        </div>
      )}
      {canViewFile && fileViewerOpen && (
        <FileViewer
          fileViewerOpen={fileViewerOpen}
          handleClose={() => setFileViewerOpen(false)}
          abstract={props.abstract}
          infoComponent={() => <AbstractInfo abstract={props.abstract} />}
          headerComponent={() => <AbstractHeader abstract={props.abstract} />}
        />
      )}
    </React.Fragment>
  );
};

PreviewAbstractCard.propTypes = {
  changeSection: PropTypes.func.isRequired,
  getAbstract: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  abstractId: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  abstract: PropTypes.object,
  language: PropTypes.string,
  getSchema: PropTypes.func,
  orgId: PropTypes.number,
  eventAbstractSchemaId: PropTypes.number,
  schema: PropTypes.object,
  event: PropTypes.object,
  policyType: PropTypes.string,
  meta: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.event.data.id,
    abstractId: state.api.abstracts.showingAbstractPreview.abstractId,
    abstract: state.api.abstracts.showingAbstractPreview.data,
    user: state.api.user.data,
    fetching: state.api.abstracts.fetching,
    language: state.api.language.selected,
    eventAbstractSchemaId: state.api.event.data.eventAbstractSchemaId,
    orgId: state.api.event.data.orgId,
    schema: state.api.schema.data,
    event: state.api.event.data,
    policyType: state.api.policy.data.type,
    meta: state.api.abstracts.meta,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSection: (section) => dispatch(changeSection(section)),
  getSchema: (orgID, eventID, eventAbstractSchemaID) =>
    dispatch(getSchema(orgID, eventID, eventAbstractSchemaID)),
  getAbstract: (eventID, abstractId) =>
    dispatch(getAbstract(eventID, abstractId, { published: true })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewAbstractCard);
