import React from 'react';
import PropTypes from 'prop-types';
// import { colorPalette } from "@config/colorPalette";

export default class StepperConnector extends React.Component {
  render() {
    return (
      <div
        className={`line-wrapper ${
          this.props.orientation === 'horizontal'
            ? 'line-wrapper-horizontal'
            : 'line-wrapper-vertical'
        }`}
        style={{
          borderColor:
            this.props.active || this.props.completed
              ? window.colorPalette.primary[600]
              : '',
        }}
      />
    );
  }
}

StepperConnector.propTypes = {
  colors: PropTypes.object,
  active: PropTypes.bool,
  alternativeLabel: PropTypes.bool,
  completed: PropTypes.bool,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  orientation: PropTypes.string,
};
