import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const HotelCard = (props) => {
  const { hotel, currency } = props;

  const cheaperRoom = (hotel) => {
    let cheapestPrice = Infinity;

    if (hotel.rooms.length > 0) {
      for (const room in hotel.rooms) {
        if (Number(hotel.rooms[room].sellingPrice) < cheapestPrice)
          cheapestPrice = hotel.rooms[room].sellingPrice;
      }
    } else {
      return 'N/A';
    }

    return cheapestPrice;
  };

  return (
    <div key={hotel.id} className="hotel-card">
      <div
        className={`hotel-image-wrapper ${
          hotel.hotelImages.length <= 0 ? 'image-missing' : ''
        }`}>
        {hotel.hotelImages.length > 0 ? (
          <img className="hotel-image" src={hotel.hotelImages[0].url} />
        ) : (
          <div className="image-placeholder">
            <ImageOutlinedIcon
              className="icon-presentation"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div className="hotel-info">
        <h2 className="hotel-card-name">{hotel.name}</h2>
        <div
          className="hotel-stars"
          title={`${hotel.stars} star${hotel.stars > 1 ? 's' : ''} hotel`}>
          {props.buildStarsHtml(hotel.id, hotel.stars)}
        </div>
        <div className="address-wrapper" title="Address">
          <svg
            width="14"
            height="14"
            viewBox="0 0 58 70"
            fill="var(--primary-600)"
            xmlns="http://www.w3.org/2000/svg"
            className="address-icon">
            <circle fill="var(--primary-600)" cx="29" cy="29" r="29" />
            <circle fill="white" cx="29" cy="29" r="13" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M53.2031 46L28.998 70L4.6875 46H53.2031Z"
              fill="var(--primary-600)"
            />
          </svg>
          <span
            onClick={() =>
              props.locateHotel(hotel.id, hotel.longitude, hotel.latitude)
            }
            className="address">
            {hotel.address}
          </span>
        </div>
        {hotel.facilities && (
          <Tooltip title={hotel.facilities}>
            <div className="hotel-facilities">
              {hotel.facilities.slice(0, 80) + '...'}
            </div>
          </Tooltip>
        )}

        <div className="end-wrapper">
          <div className="price-per-night">
            {cheaperRoom(hotel) === 'N/A'
              ? null
              : `${window.lang[props.language]['pricePerNight']} ${cheaperRoom(
                  hotel
                )} ${currency}`}
          </div>

          <button
            disabled={cheaperRoom(hotel) === 'N/A'}
            className="btn btn-primary proceed-to-booking"
            onClick={() => props.selectHotel(hotel.id)}>
            {cheaperRoom(hotel) === 'N/A' ? (
              window.lang[props.language]['noAvailableRooms']
            ) : props.hasReservation ? (
              window.lang[props.language]['viewReservation']
            ) : (
              <>
                {window.lang[props.language]['seeRooms']}
                <span className="icon-right-down">
                  <ArrowForwardIosIcon fontSize="inherit" />
                </span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

HotelCard.propTypes = {
  language: PropTypes.string,
  hotel: PropTypes.object.isRequired,
  selectHotel: PropTypes.func,
  locateHotel: PropTypes.func,
  hasReservation: PropTypes.bool,
  clickedHotel: PropTypes.string,
  buildStarsHtml: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  primaryColor: PropTypes.string.isRequired,
};

export default HotelCard;
