import React from 'react';
import PropTypes from 'prop-types';
import NavigateModal from '@components/layout/NavigateModal';

export default class Ebag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      linkToNavigateTo: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  navigateOutOfSiteHandler = () => {
    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  };

  setSiteToNavigateTo = (link) => {
    if (link.includes('bluetree')) {
      window.open(link);
    } else {
      this.setState({ linkToNavigateTo: link }, () =>
        this.navigateOutOfSiteHandler()
      );
    }
  };

  render() {
    const ebagSettings = this.props.event.clientPanelSettings.ebag;
    let ebagSchema = this.props.event.clientPanelEbagSettings || '{}';
    ebagSchema = JSON.parse(ebagSchema);
    const primaryColor =
      this.props.event?.clientPanelSettings?.general?.colors?.primary;

    if (ebagSettings.ebagSchema.length > 0) {
      return (
        <React.Fragment>
          <div className="sponsor-section" data-test="sponsor-section">
            {ebagSettings.ebagSchema
              .sort((a, b) => a.index - b.index)

              .map((item) => {
                const imgUrl = item.clientEbagBrochure[0]?.url || item.url;
                return (
                  <div key={item.id} style={{ flexBasis: '31.3%' }}>
                    <img
                      src={
                        item.clientEbagImage[0]
                          ? item.clientEbagImage[0].url
                          : null
                      }
                      onClick={() => imgUrl && this.setSiteToNavigateTo(imgUrl)}
                      style={{
                        cursor: imgUrl ? 'pointer' : 'default',
                        flexBasis: '31.3%',
                        width: '100%',
                      }}
                    />
                  </div>
                );
              })}
          </div>
          {this.state.modalIsOpen && (
            <NavigateModal
              changeModalStatus={this.navigateOutOfSiteHandler}
              linkToNavigateTo={this.state.linkToNavigateTo}
              language={this.props.language}
              color={primaryColor}
            />
          )}
        </React.Fragment>
      );
    } else {
      if (ebagSchema.ebagItems && ebagSchema.ebagItems.length > 0) {
        return (
          <div className="sponsor-section" data-test="sponsor-section">
            {ebagSchema.ebagItems.length > 0 &&
              ebagSchema.ebagItems.map((item, i) => {
                return (
                  <div className="sponsor" key={i}>
                    <img
                      src={item.image ? item.image : null}
                      onClick={() => item.url && window.open(item.url)}
                      style={{
                        padding: 15,
                        cursor: item.url ? 'pointer' : 'default',
                        maxWidth: '80%',
                      }}
                    />
                  </div>
                );
              })}
          </div>
        );
      } else {
        return null;
      }
    }
  }
}

Ebag.propTypes = {
  language: PropTypes.any,
  event: PropTypes.object.isRequired,
};
