import React from 'react';
import PropTypes from 'prop-types';

const CheckMail = ({ color1, color2, color3 }) => {
  return (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 33C5 17.536 17.536 5 33 5C48.464 5 61 17.536 61 33C61 48.464 48.464 61 33 61C17.536 61 5 48.464 5 33Z"
        fill={color1}
      />
      <path
        d="M44.6673 25.9974C44.6673 24.7141 43.6173 23.6641 42.334 23.6641H23.6673C22.384 23.6641 21.334 24.7141 21.334 25.9974M44.6673 25.9974V39.9974C44.6673 41.2807 43.6173 42.3307 42.334 42.3307H23.6673C22.384 42.3307 21.334 41.2807 21.334 39.9974V25.9974M44.6673 25.9974L33.0007 34.1641L21.334 25.9974"
        stroke={color2}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 56C20.2975 56 10 45.7025 10 33H0C0 51.2254 14.7746 66 33 66V56ZM56 33C56 45.7025 45.7025 56 33 56V66C51.2254 66 66 51.2254 66 33H56ZM33 10C45.7025 10 56 20.2975 56 33H66C66 14.7746 51.2254 0 33 0V10ZM33 0C14.7746 0 0 14.7746 0 33H10C10 20.2975 20.2975 10 33 10V0Z"
        fill={color3}
      />
    </svg>
  );
};

CheckMail.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
};

export default CheckMail;
