import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = ({ size = '100px' }) => {
  return (
    <div className="global-loader-container">
      <CircularProgress size={size} />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
};

export default Loader;
