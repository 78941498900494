import React from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CopyToClipboard from '@dynamic_assets/copyToClipboard.svg.js';

const Bank = (props) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <div className="iban-wrapper">
        {window.lang[props.language]['iban']}: {props.policy.billing.iban}
        <div
          className="iban-copy-button"
          onClick={() => {
            navigator.clipboard.writeText(props.policy.billing.iban);
          }}>
          <CopyToClipboard
            color={window.colorPalette.primary[600]}
            className="iban-copy-icon"
          />
        </div>
      </div>

      {props.policy.billing?.bankInfo?.length > 0 && (
        <div
          className="bank-policy-message"
          style={{
            background: window.colorPalette.primary[25],
            borderColor: window.colorPalette.primary[700],
          }}>
          <InfoOutlinedIcon className="bank-policy-message-icon" />
          {props.policy.billing.bankInfo}
        </div>
      )}
      <p className="bank-message" style={{ fontSize: '13px' }}>
        {window.lang[props.language]['bankMessage']}
      </p>
      {(props.supportEmail || '').length > 0 && (
        <p style={{ fontSize: '13px' }}>
          {window.lang[props.language]['paymentsContactUsMessage']}{' '}
          <a
            href={`mailto:${props.supportEmail}?Subject=Payment%20Support%20-%20${props.event.name}`}
            target="_blank"
            rel="noreferrer">
            {props.supportEmail}
          </a>
        </p>
      )}
    </div>
  );
};

Bank.propTypes = {
  policy: PropTypes.object,
  event: PropTypes.object,
  supportEmail: PropTypes.string,
  language: PropTypes.string,
};

export default Bank;
