import React from 'react';

export const ShoppingCartIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.832031 0.828125H4.16536L6.3987 11.9865C6.4749 12.3701 6.68362 12.7148 6.98832 12.96C7.29302 13.2053 7.67428 13.3356 8.06536 13.3281H16.1654C16.5564 13.3356 16.9377 13.2053 17.2424 12.96C17.5471 12.7148 17.7558 12.3701 17.832 11.9865L19.1654 4.99479H4.9987M8.33203 17.4948C8.33203 17.955 7.95893 18.3281 7.4987 18.3281C7.03846 18.3281 6.66536 17.955 6.66536 17.4948C6.66536 17.0346 7.03846 16.6615 7.4987 16.6615C7.95893 16.6615 8.33203 17.0346 8.33203 17.4948ZM17.4987 17.4948C17.4987 17.955 17.1256 18.3281 16.6654 18.3281C16.2051 18.3281 15.832 17.955 15.832 17.4948C15.832 17.0346 16.2051 16.6615 16.6654 16.6615C17.1256 16.6615 17.4987 17.0346 17.4987 17.4948Z"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
