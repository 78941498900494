import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KMBLoader from '@components/layout/KMBLoader';
import Heading from './partials/Heading';
import Files from './partials/Files';
import GMaps from './partials/GMaps';
import Iframe from './partials/Iframe';
import Image from './partials/Image';
import Video from './partials/Video';
import Message from '@components/layout/Message';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
  changeSection,
  getExhibitorById,
  createCustomEmail,
  addNotification,
} from '@actions';
import NavigateModal from '@components/layout/NavigateModal';

class ExhibitorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      miniComponents: [],
      header: <div />,
      modalIsOpen: false,
      messageModalPosition: 'fixed',
      logoImg: '',
      backImg: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    this.props
      .getExhibitorById(this.props.exhibitorId)
      .then((res) => this.createPageLayout(res.publishedSchema));
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled > 0.82) {
      this.setState({ messageModalPosition: 'absolute' });
    }
    if (scrolled < 0.82) {
      this.setState({ messageModalPosition: 'fixed' });
    }
  };

  componentSelector(component, actualHeight, params) {
    switch (component.type) {
      case 'html':
        return <Heading content={component.content.value} />; // DONE
      case 'map':
        return (
          <GMaps
            content={component.content.value}
            customHeight={actualHeight - 30}
          />
        ); // DONE
      case 'image':
        return (
          <Image
            content={component.content}
            customHeight={actualHeight - 30}
            pos={params.idx === 0 ? 'left' : 'right'}
            fullRow={params.slots === 1 ? true : false}
            setSiteToNavigateTo={this.setSiteToNavigateTo}
          />
        ); //DONE
      case 'video':
        return <Video content={component.content.url} />; //DONE
      case 'file':
        return <Files content={component.content} />;
      case 'iframe':
        return (
          <Iframe
            content={component.content.url}
            customHeight={actualHeight - 30}
          />
        ); //DONE
    }
  }

  navigateOutOfSiteHandler = () => {
    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  };

  setSiteToNavigateTo = (link) => {
    if (link.includes('bluetree')) {
      window.open(link);
    } else {
      this.setState({ linkToNavigateTo: link }, () =>
        this.navigateOutOfSiteHandler()
      );
    }
  };

  createPageLayout(data) {
    const miniComponents = [];
    const setHeight = 294;
    const backImg = data.header.exhibitorPageBackground.actionUrl;
    const logoImg = data.header.exhibitorPageLogo.url;
    this.setState({ backImg, logoImg });
    const header = (
      <a
        onClick={() =>
          this.setSiteToNavigateTo(
            data.header.exhibitorPageBackground.actionUrl
          )
        }
        target="_blank"
        style={{
          width: '100%',
          height: '150px',
          cursor: 'pointer',
        }}>
        {data.header.exhibitorPageBackground.url ? (
          <img
            className="background"
            src={data.header.exhibitorPageBackground.url}
          />
        ) : null}
        {data.header.exhibitorPageLogo.url ? (
          <img className="logo" src={data.header.exhibitorPageLogo.url} />
        ) : null}
      </a>
    );
    data.main.forEach((row) => {
      const actualHeight = setHeight * row.rowHeight;

      row.components.forEach((component, idx) => {
        miniComponents.push(
          <div
            className={`col-sm-${row.rowSlots === 1 ? 12 : 6}`}
            style={{
              maxHeight: actualHeight,
              height: actualHeight,
              padding: 0,
              ...(component?.type === 'video' ? { height: actualHeight } : {}),
            }}>
            {this.componentSelector(component, actualHeight, {
              idx,
              slots: row.rowSlots,
            })}
          </div>
        );
      });
    });
    this.setState({ miniComponents, header });
  }

  render() {
    const {
      exhibitorReady,
      changeSection,
      language,
      userMail,
      exhibitor,
      createCustomEmail,
    } = this.props;
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;
    const emailCommunicationAllowed = exhibitor?.enableEmailCommunication;
    const supportEmail = exhibitor?.supportEmail;
    const showHeader = this.state.logoImg || this.state.backImg;

    return (
      <div>
        <div
          className="navigate-back-exhibition"
          onClick={() => changeSection('billing', true)}>
          <ArrowBackIcon />
          {window.lang[this.props.language]['backToExhibition']}
        </div>
        {exhibitorReady ? (
          <div className="main-content">
            {showHeader ? (
              <div className="exhibition-header">{this.state.header}</div>
            ) : null}
            <div className="row" style={{ margin: 0 }}>
              {this.state.miniComponents}
            </div>
          </div>
        ) : (
          <KMBLoader rows={20} />
        )}
        {this.state.modalIsOpen && (
          <NavigateModal
            changeModalStatus={this.navigateOutOfSiteHandler}
            linkToNavigateTo={this.state.linkToNavigateTo}
            language={this.props.language}
            color={primaryColor}
          />
        )}
        {emailCommunicationAllowed ? (
          <Message
            position={'fixed'}
            color="var(--primary-600)"
            event={this.props.event}
            language={language}
            userMail={userMail}
            supportEmail={supportEmail}
            createCustomEmail={createCustomEmail}
            addNotification={this.props.addNotification}
          />
        ) : null}
      </div>
    );
  }
}

ExhibitorPage.propTypes = {
  exhibitorId: PropTypes.number.isRequired,
  getExhibitorById: PropTypes.func.isRequired,
  exhibitorReady: PropTypes.bool.isRequired,
  exhibitor: PropTypes.array.isRequired,
  changeSection: PropTypes.func.isRequired,
  event: PropTypes.object,
  language: PropTypes.string,
  userMail: PropTypes.string,
  createCustomEmail: PropTypes.func,
  addNotification: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    exhibitorId: state.api.exhibition.showingExhibitor.exhibitorId,
    exhibitorReady: state.api.exhibition.showingExhibitor.ready,
    exhibitor: state.api.exhibition.showingExhibitor.data,
    event: state.api.event.data,
    language: state.api.language.selected,
    userMail: state.api.user.data.info.email,
  };
};
const mapDispatchToProps = (dispatch) => ({
  changeSection: () => dispatch(changeSection('exhibition')),
  getExhibitorById: (exhibitorId) => dispatch(getExhibitorById(exhibitorId)),
  createCustomEmail: (eventId, data) =>
    dispatch(createCustomEmail(eventId, data)),
  addNotification: (msg, code) => dispatch(addNotification(msg, code)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorPage);
