import React from 'react';
import PropTypes from 'prop-types';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Tooltip } from '@material-ui/core';

const WarningBar = (props) => {
  if (props.message) {
    return (
      <div className="warning-bar">
        <div className="warning-bar-inner-wrapper">
          <div className="wrapper-responsive wrapper-desktop-view">
            <ReportProblemOutlinedIcon className="warning-icon" />
            <Tooltip title={props.message}>
              <div className="warning-message">{props.message}</div>
            </Tooltip>
          </div>
          {props.button}
        </div>
      </div>
    );
  } else {
    return <div className="warning-bar hidden" />;
  }
};

WarningBar.propTypes = {
  message: PropTypes.string,
  button: PropTypes.element,
};

export default WarningBar;
