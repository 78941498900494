import 'react-hot-loader/patch';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { AppContainer } from 'react-hot-loader';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import reducer from '@reducers';
import { validateUrl } from '@actions';
import { isNumeric } from '@helpers';
import { initHistory } from '@helpers/history';
import ReduxStore from './store';
import lang from '@config/languages';
window.lang = lang;

const loggerMiddleware = createLogger();
let storeRef;
if (process.env.REACT_APP_LOG_STATE) {
  storeRef = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  );
} else {
  storeRef = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunkMiddleware)
  );
}

export const store = storeRef;

const { protocol, host, pathname } = window.location;

let path = pathname.split('/').filter((n) => n);
const trim = path.length > 1 && !isNumeric(path[1]) ? 1 : 2;

if (path.length > trim) {
  path = path.filter((p, i) => i < trim);
}

store.dispatch(validateUrl(`${protocol}//${host}${path}`, path));

const basename = `/${path.join('/')}`;
initHistory(basename);
const elRoot = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ReduxStore store={store} />
    </AppContainer>,
    elRoot
  );
};

render();

if (module.hot) {
  module.hot.accept();
}
