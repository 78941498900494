import React from 'react';
import PropTypes from 'prop-types';

const Backdrop = (props) => {
  const [visibility, setVisibility] = React.useState(false);
  const [escapeable] = React.useState(props.escapeable);

  const handleClose = (e) => {
    e.stopPropagation();
    document.body.style.overflow = 'auto';
    setVisibility(false);
  };

  const handleClick = (e) => (escapeable ? handleClose(e) : null);

  React.useEffect(() => {
    setVisibility(props.visible);
  }, [props.visible]);

  React.useEffect(() => {
    if (props.visible) {
      setVisibility(true);
      document.body.style.overflow = 'hidden';
    } else setVisibility(false);
  }, []);

  return (
    <div
      className="backdrop"
      style={{ display: visibility ? 'initial' : 'none' }}
      onClick={(e) => handleClick(e)}
    />
  );
};

Backdrop.propTypes = {
  visible: PropTypes.bool,
  escapeable: PropTypes.bool,
};

export default Backdrop;
