import React from 'react';
import PropTypes from 'prop-types';
import PaymentWays from '@components/layout/PaymentWays';
import TermsOfService from '@components/layout/TermsOfService';
import SuccessPage from '@components/layout/SuccessPage';
import InfoPage from '@components/layout/InfoPage';
import ErrorPage from '@components/layout/ErrorPage';
import { saveByteArray } from '@helpers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Backdrop from '@components/layout/Backdrop';
import CancelRegistrationModal from '@components/layout/CancelRegistrationModal';
import CircularProgress from '@material-ui/core/CircularProgress';

const queryString = require('query-string');

export default class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.handleAgreeToTerms = this.handleAgreeToTerms.bind(this);
    this.query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    this.totalCostColumn = {
      totalCost: {
        name: window.lang[this.props.language]['orderTotal'],
      },
    };
    this.columns = {
      cosmetic: {
        name: '',
      },
      quantity: {
        name: window.lang[this.props.language]['quantity'],
      },
      type: {
        name: window.lang[this.props.language]['type'],
      },
      paidAmount: {
        name: window.lang[this.props.language]['amount'],
      },
    };
    this.items = [];
    this.state = {
      loading: false,
      registrationDone: false,
      paymentRef: null,
      orderId: null,
      availableProducts: [],
      fields: { firstName: '', lastName: '', email: ' ' },
      totalCost: 0,
      subscriptionInfo: {},
      agreeToTerms: false,
      cancelConfirmation: false,
    };
  }

  componentDidMount() {
    let totalCost = 0;

    if (this.query.status === 'CAPTURED' && this.query.tk) {
      this.props
        .validateToken(this.query.tk)
        .then((d) => {
          this.setState({
            paymentRef: d.data.paymentRef,
            orderId: d.data.orderId,
          });
        })
        .catch((e) => console.error(e));
    }

    this.props.products.map((product) => {
      const newProduct = {};
      totalCost = totalCost + parseFloat(product['totalPrice'].split(' ')[0]);
      if (product.quantity > 0) {
        newProduct.quantity = product.quantity;
        newProduct.type = product.name;
        newProduct.paidAmount = parseFloat(product['totalPrice'].split(' ')[0]);
        this.items.push(newProduct);
      }
    });
    const subscriptionCost = this.props.policy.mandatoryPayment
      ? this.props.subscriptionInfo.eventSubscriptionPrice
      : 0;

    totalCost = parseFloat(totalCost) + parseFloat(subscriptionCost);

    this.setState({
      availableProducts: this.props.products,
      subscriptionInfo: this.props.subscriptionInfo,
      totalCost: parseFloat(totalCost).toFixed(2),
    });
    this.items.push({
      quantity: 1,
      type: `${window.lang[this.props.language]['registration']} ${
        this.props.subscriptionInfo.name || ''
      }`,
      paidAmount: this.props.policy.mandatoryPayment
        ? this.props.subscriptionInfo.eventSubscriptionPrice
        : 0,
    });
  }

  handleAgreeToTerms(event) {
    this.setState({ agreeToTerms: event.target.checked });
  }

  getReceipt(id, token) {
    this.props
      .getReceipt(this.props.user.eventId, id, this.state.paymentRef, token)
      .then((buffer) => {
        const reportName = `receipt_${
          id ? id : 'ref' + this.state.paymentRef
        }.pdf`;
        saveByteArray(reportName, buffer);
      })
      .catch((e) => console.error(e));
  }

  returnProductsDTO() {
    const parsedProducts = [];
    parsedProducts.push('registration');

    this.state.availableProducts.length > 0 &&
      this.state.availableProducts.map((prod) => {
        if (prod.quantity > 0) {
          parsedProducts.push(`${prod.quantity}_product_${prod.id}`);
        }
      });
    return parsedProducts;
  }
  onRender(col, item) {
    switch (col.key) {
      case 'totalCost':
        return <span>{`${item.totalCost} ${this.props.currency}`}</span>;
      case 'quantity':
        return <span>{item.quantity || 1}</span>;
      case 'paidAmount':
        return (
          <span>{`${parseFloat(item.paidAmount).toFixed(2)} ${
            this.props.currency
          }`}</span>
        );
    }
  }
  render() {
    const bank = this.state.availableProducts
      .filter((product) => product.quantity > 0)
      .map((product) => product.availablePaymentMethodsKeys)
      .reduce(
        (prev, next) =>
          prev.map((method) => next.indexOf(method) !== -1 && method),
        ['bank']
      )[0];
    const card = this.state.availableProducts
      .filter((product) => product.quantity > 0)
      .map((product) => product.availablePaymentMethodsKeys)
      .reduce(
        (prev, next) =>
          prev.map((method) => next.indexOf(method) !== -1 && method),
        ['card']
      )[0];
    const subscriptionExists = this.state.subscriptionInfo.hasOwnProperty(
      'eventSubscriptionPrice'
    )
      ? true
      : false;
    if (this.props.policy.mandatoryPayment == 1 && !subscriptionExists) {
      return (
        <React.Fragment>
          <div style={{ margin: 'auto' }}>
            {window.lang[this.props.language]['noSubscriptionSet']}
          </div>
          <div className="btn-container single-btn-container padding-bot single-return">
            <button
              onClick={() => this.props.handlePrev()}
              className="reverse step-btn back-btn full-back-btn">
              <div className="arrow-container btn-previous">
                <ArrowBackIcon />
              </div>
              <div className="txt-container">
                <div className="btn-txt">
                  <span className="txt-mobile">
                    {window.lang[this.props.language].previous}
                  </span>
                  <span className="txt-1">
                    {window.lang[this.props.language].previousStep}:
                  </span>
                  <br />
                  <span className="txt-2">{this.props.prevStep}</span>
                </div>
              </div>
            </button>
          </div>
        </React.Fragment>
      );
    } else if (this.query.status === 'CAPTURED') {
      return (
        <SuccessPage
          goToBilling={this.props.goToBilling}
          language={this.props.language}
          orderId={this.state.orderId}
          paymentRef={this.state.paymentRef}
          token={this.query.tk}
          getReceipt={() => this.getReceipt(undefined, this.query.tk)}
          validateToken={this.props.validateToken}
        />
      );
    } else if (this.query.status === 'REFUSED') {
      return (
        <InfoPage
          goToBilling={this.props.goToBilling}
          language={this.props.language}
        />
      );
    } else if (this.query.status === 'ERROR') {
      return (
        <ErrorPage
          goToBilling={this.props.goToBilling}
          language={this.props.language}
        />
      );
    } else {
      return (
        <React.Fragment>
          <Backdrop escapeable={false} visible={this.state.loading} />
          <div className="payment-info-text">
            {window.lang[this.props.language]['contactUs']}
            <span
              onClick={() =>
                window.open(`mailto:${this.props.policy.supportEmail}`)
              }
              className="email-support">
              {this.props.policy.supportEmail}
            </span>

            {this.props.policy.supportPhoneNumber
              ? `, ${window.lang[this.props.language]['orAt']} ${
                  this.props.policy.supportPhoneNumber
                }`
              : null}
            {'.'}
          </div>
          {this.props.policy.mandatoryPayment == 0 ? null : (
            <div
              className="total-costs"
              style={this.state.totalCost == 0 ? { flexBasis: '100%' } : null}>
              <div className="total-costs-title">
                {`1. ${window.lang[this.props.language]['youHaveChosen']}`}
              </div>
              <div className="costs-table">
                {this.items.map((item) => {
                  let foundRegistration = false;
                  this.props.products.forEach((product) => {
                    if (item.type === product.name) foundRegistration = true;
                  });
                  if (foundRegistration) return;
                  return (
                    <div
                      className="product-cost"
                      key={`product-item${item.id}`}>
                      <div className="item-general-info">
                        <div className="item-title">{`${item.quantity}x ${item.type}`}</div>
                        <div className="item-price">{`
                        ${
                          this.props.currencyIso === 'USD'
                            ? this.props.currency
                            : ''
                        }
                        ${(
                          item.quantity * Number.parseFloat(item.paidAmount)
                        ).toFixed(2)}
                        ${
                          this.props.currencyIso !== 'USD'
                            ? this.props.currency
                            : ''
                        }
                      `}</div>
                      </div>
                      <div className="item-payment-info">
                        <div className="payments-wrapper">
                          {
                            <React.Fragment>
                              {this.props.policy.billing.cardOptions.includes(
                                'registration'
                              ) &&
                              this.props.policy.billing.creditCard === 1 ? (
                                <img
                                  className={`payment-icon payment-icon-card`}
                                  src={`${process.env.PUBLIC_URL}/ecommerce/cardPayment.svg`}
                                />
                              ) : null}
                              {this.props.policy.billing.cardOptions.includes(
                                'registration'
                              ) && this.props.policy.billing.paypal === 1 ? (
                                <img
                                  className={`payment-icon payment-icon-paypal`}
                                  src={`${process.env.PUBLIC_URL}/ecommerce/paypalPayment.svg`}
                                />
                              ) : null}
                              {this.props.policy.billing.bankOptions.includes(
                                'registration'
                              ) && this.props.policy.billing.bank === 1 ? (
                                <img
                                  className={`payment-icon payment-icon-bank`}
                                  src={`${process.env.PUBLIC_URL}/ecommerce/bankPayment.svg`}
                                />
                              ) : null}
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </div>
                  );
                })}
                {this.props.products.map((item) => {
                  if (item.quantity > 0) {
                    return (
                      <div className="product-cost">
                        <div className="item-general-info">
                          <div className="item-title">{`${item.quantity}x ${item.name}`}</div>
                          <div className="item-price">{`
                            ${
                              this.props.currencyIso === 'USD'
                                ? this.props.currency
                                : ''
                            }
                            ${(
                              item.quantity * Number.parseFloat(item.price)
                            ).toFixed(2)}
                            ${
                              this.props.currencyIso !== 'USD'
                                ? this.props.currency
                                : ''
                            }
                          `}</div>
                        </div>
                        <div className="item-payment-info">
                          <div className="payments-wrapper">
                            {item.availablePaymentMethodsKeys.map(
                              (paymentKey) => {
                                if (paymentKey === 'bank') {
                                  return (
                                    <img
                                      className={`payment-icon payment-icon-bank`}
                                      src={`${process.env.PUBLIC_URL}/ecommerce/bankPayment.svg`}
                                    />
                                  );
                                } else if (paymentKey === 'card') {
                                  return (
                                    <React.Fragment>
                                      {this.props.policy.billing.creditCard ===
                                      1 ? (
                                        <img
                                          className={`payment-icon payment-icon-card`}
                                          src={`${process.env.PUBLIC_URL}/ecommerce/cardPayment.svg`}
                                        />
                                      ) : null}
                                      {this.props.policy.billing.paypal ===
                                      1 ? (
                                        <img
                                          className={`payment-icon payment-icon-paypal`}
                                          src={`${process.env.PUBLIC_URL}/ecommerce/paypalPayment.svg`}
                                        />
                                      ) : null}
                                    </React.Fragment>
                                  );
                                }
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="final-cost-sum">
                <div className="cost-sum-title">
                  {window.lang[this.props.language]['totalCost']}
                  <br />
                  <span className="tax-warning">{`${
                    window.lang[this.props.language]['taxesIncluded']
                  }`}</span>
                </div>
                <div className="cost-sum-total">{`
                  ${this.props.currencyIso === 'USD' ? this.props.currency : ''}
                  ${this.state.totalCost}
                  ${this.props.currencyIso !== 'USD' ? this.props.currency : ''}
                `}</div>
              </div>
            </div>
          )}
          {this.state.totalCost > 0 ? (
            <PaymentWays
              loading={this.state.loading}
              bank={
                !!this.props.policy.billing.bank &&
                this.props.policy.billing.bankOptions.includes(
                  'registration'
                ) &&
                bank && {
                  submitPayment: () => {
                    this.setState({ loading: true }, () => {
                      this.props
                        .registerUser(
                          this.props.fields,
                          this.query.t,
                          this.props.orgCrmUserId,
                          this.returnProductsDTO()
                        )
                        .then(() => {
                          return window.location.replace(
                            location.href.split('?')[0]
                          );
                        })
                        .catch(() => {
                          this.props
                            .validateToken(this.query.t)
                            .then((user) => {
                              let orgCrmUserId;
                              if (
                                this.props.policy.type === 'private' &&
                                this.props.policy.saveUsersToCrm &&
                                user.data.id
                              ) {
                                orgCrmUserId =
                                  user.data.orgCrmUserId || user.data.id;
                                this.setState({
                                  orgCrmUserId,
                                  loading: false,
                                });
                              } else {
                                this.setState({ loading: false });
                              }
                            })
                            .catch(() => {
                              this.setState({ loading: false });
                            });
                        });
                    });
                  },
                }
              }
              user={{
                info: {
                  firstName: this.props.fields?.['firstName']?.value,
                  lastName: this.props.fields?.['lastName']?.value,
                  email: this.props.fields?.['email']?.value,
                },
              }}
              {...this.props}
              products={this.state.availableProducts}
              totalPrice={this.state.totalCost}
              clientId={this.props.policy.billing.paypalClientId}
              paypal={
                !!this.props.policy.billing.paypal &&
                this.props.policy.billing.cardOptions.includes(
                  'registration'
                ) &&
                card
                  ? {
                      amount: this.state.totalCost,
                      createOrder: (data, actions) => {
                        const userFields = {};
                        Object.keys(this.props.fields).map((k) => {
                          userFields[k] =
                            this.props.fields[k].type === 'yes_no'
                              ? this.props.fields[k].value === 'yes'
                              : this.props.fields[k].value;
                        });
                        return this.props
                          .paypalCreateOrder(this.props.eventId, {
                            products: this.returnProductsDTO(),
                            orderAmount: this.state.totalCost,
                            currencyIso: this.props.currencyIso,
                            signup: true,
                            policyId: this.props.policy.id,
                            eventId: this.props.policy.eventId,
                            userFields: this.props.fields,
                            orgCrmUserId: this.props.orgCrmUserId,
                          })
                          .then((data) => {
                            this.user = data.data.theUser;
                            return actions.order.create({
                              purchase_units: [
                                {
                                  reference_id: data.data.finalOrderId,
                                  description: data.data.productDesc,
                                  amount: {
                                    currency_code: 'EUR',
                                    value: data.data.values.data.orderAmount,
                                  },
                                },
                              ],
                            });
                          });
                      },
                      onApprove: (data, actions) => {
                        return actions.order.capture().then((details) => {
                          this.props
                            .paypalTransactionCompleted({
                              fromPaypal: true,
                              status:
                                details.intent == 'CAPTURE'
                                  ? 'CAPTURED'
                                  : details.intent,
                              orderid: details.purchase_units[0].reference_id,
                              orderAmount:
                                details.purchase_units[0].amount.value,
                              txId: details.id,
                              paymentRef: details.id,
                              details: JSON.stringify(details),
                              currency:
                                details.purchase_units[0].amount.currency_code,
                              productDesc:
                                details.purchase_units[0].description,
                              eventId: this.props.eventId,
                              eventPolicyId: this.props.policy.id,
                              eventUserId: this.user.id,
                              signup: 'true',
                            })
                            .then((response) => {
                              window.location.replace(
                                response.data.redirectLink
                              );
                            })
                            .catch(console.error);
                          return true;
                        });
                      },
                    }
                  : false
              }
              card={
                this.props.policy.billing.cardOptions.includes(
                  'registration'
                ) &&
                !!this.props.policy.billing.creditCard &&
                card
                  ? {
                      submitPayment: (fields) => {
                        this.setState({ loading: true });
                        const userFields = {};
                        Object.keys(this.props.fields).map((k) => {
                          userFields[k] =
                            this.props.fields[k].type === 'yes_no'
                              ? this.props.fields[k].value === 'yes'
                              : this.props.fields[k].value;
                        });
                        this.props
                          .submitPayment(
                            this.props.eventId,
                            this.returnProductsDTO(),
                            this.state.totalCost,
                            this.props.currencyIso,
                            fields,
                            this.props.fields,
                            true,
                            'card',
                            this.props.orgCrmUserId
                          )
                          .catch(() => {
                            this.setState({ loading: false });
                            this.props
                              .validateToken(this.query.t)
                              .then((user) => {
                                let orgCrmUserId;
                                if (
                                  this.props.policy.type === 'private' &&
                                  this.props.policy.saveUsersToCrm &&
                                  user.data.id
                                ) {
                                  orgCrmUserId =
                                    user.data.orgCrmUserId || user.data.id;
                                  this.setState({
                                    orgCrmUserId,
                                  });
                                }
                              })
                              .catch(() => {
                                this.setState({ loading: false });
                              });
                          });
                      },
                      onChange: this.onChange,
                      countriesOptions: this.countriesOptions,
                    }
                  : false
              }
              language={this.props.language}
            />
          ) : (
            <div style={{ width: '80%', maxWidth: 500, margin: 'auto' }}>
              <TermsOfService
                {...this.props}
                checked={this.state.agreeToTerms}
                handleAgreeToTerms={this.handleAgreeToTerms}
              />
            </div>
          )}
          <div className="btn-container single-btn-container padding-bot single-return">
            <button
              onClick={() => this.props.handlePrev()}
              disabled={this.state.registrationDone}
              className="reverse step-btn back-btn full-back-btn">
              <div className="arrow-container btn-previous">
                <ArrowBackIcon />
              </div>
              <div className="txt-container">
                <div className="btn-txt">
                  <span className="txt-mobile">
                    {window.lang[this.props.language].previous}
                  </span>
                  <span className="txt-1">
                    {window.lang[this.props.language].previousStep}:
                  </span>
                  <br />
                  <span className="txt-2">{this.props.prevStep}</span>
                </div>
              </div>
            </button>
            {this.state.totalCost == 0 ? (
              <button
                className={`complete-button ${
                  !this.state.agreeToTerms || this.state.registrationDone
                    ? 'disabled'
                    : ''
                }`}
                disabled={
                  !this.state.agreeToTerms || this.state.registrationDone
                }
                onClick={() => {
                  this.setState({ loading: true });
                  this.props
                    .registerUser(
                      this.props.fields,
                      this.query.t,
                      this.props.orgCrmUserId,
                      this.returnProductsDTO()
                    )
                    .then(() => {
                      window.location.replace(location.href.split('?')[0]);
                    })
                    .catch(() => {
                      this.props.validateToken(this.query.t).then((user) => {
                        let orgCrmUserId;
                        if (
                          this.props.policy.type === 'private' &&
                          this.props.policy.saveUsersToCrm &&
                          user.data.id
                        ) {
                          orgCrmUserId = user.data.orgCrmUserId || user.data.id;
                          this.setState({
                            orgCrmUserId,
                            loading: false,
                          });
                        } else {
                          this.setState({ loading: false });
                        }
                      });
                    });
                }}>
                {this.state.loading ? (
                  <CircularProgress size={24} className="submit-loader" />
                ) : (
                  window.lang[this.props.language].complete
                )}
              </button>
            ) : null}
            <div className="bottom-info">
              <div
                onClick={() => {
                  this.setState({ cancelConfirmation: true });
                }}
                style={{
                  color: window.colorPalette.primary[600],
                  textDecorationColor: window.colorPalette.primary[600],
                }}
                className={`cancel-registration`}>
                {window.lang[this.props.language].cancelRegistration}
              </div>
            </div>
          </div>
          {this.state.cancelConfirmation && (
            <CancelRegistrationModal
              language={this.props.language}
              setModalVisibility={() =>
                this.setState({ cancelConfirmation: false })
              }
            />
          )}
        </React.Fragment>
      );
    }
  }
}

Payment.propTypes = {
  products: PropTypes.array,
  language: PropTypes.string,
  policy: PropTypes.object,
  user: PropTypes.object,
  productCost: PropTypes.number,
  currency: PropTypes.string,
  validateToken: PropTypes.func,
  registerUser: PropTypes.func.isRequired,
  paypalCreateOrder: PropTypes.func,
  changeSection: PropTypes.func.isRequired,
  eventId: PropTypes.number,
  currencyIso: PropTypes.string,
  submitPayment: PropTypes.func,
  paypalTransactionCompleted: PropTypes.func,
  fields: PropTypes.object,
  orgCrmUserId: PropTypes.number,
  subscriptionInfo: PropTypes.object,
  getReceipt: PropTypes.func,
  goToBilling: PropTypes.func,
  knownUserFields: PropTypes.object,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  prevStep: PropTypes.string,
};
