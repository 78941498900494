import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

export default class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: this.props.images,
      type: this.props.type,
    };
  }
  manageImages(props) {
    const images = [];
    props.images.map((v) => images.push({ original: v.url, thumbnail: v.url }));
    this.setState({ images, type: props.type });
  }

  UNSAFE_componentWillMount() {
    this.manageImages(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.images.length > 0) {
      this.manageImages(nextProps);
      this._imageGallery.slideToIndex(0);
    }
  }

  render() {
    const { images } = this.state;
    return (
      <div
        className="slider-container"
        style={{
          width: '100%',
          objectFit: 'contain',
          position: 'relative',
        }}>
        {typeof images !== 'undefined' && images.length > 0 ? (
          <ImageGallery
            ref={(i) => (this._imageGallery = i)}
            showThumbnails={this.props.showThumbnails}
            showPlayButton={false}
            items={this.state.images}
            thumbnailPosition="right"
            additionalClass={`${
              !this.props.showThumbnails ? 'full-slider' : ''
            }`}
          />
        ) : (
          <i className="icon-presentation" aria-hidden="true" />
        )}
      </div>
    );
  }
}

Slider.propTypes = {
  height: PropTypes.number,
  images: PropTypes.array,
  type: PropTypes.string,
  showThumbnails: PropTypes.bool,
};

Slider.defaultProps = {
  height: 100,
};
