import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TermsOfService from '@components/layout/TermsOfService';
import StepperButtons from '@components/layout/StepperButtons';
import { PrimaryButton } from '@components/layout/Buttons';

const CompleteRegistration = ({
  buttonText,
  onSubmit,
  message,
  policy,
  language,
  currency,
  handlePrev,
  changeSection,
  prevStep,
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="complete-or-validate">
      <p>{message}</p>
      <div
        className="agree"
        style={{
          maxWidth: '100%',
          width: 400,
          marginBottom: 38,
          marginTop: 24,
        }}>
        <TermsOfService
          checked={checked}
          handleAgreeToTerms={(e) => setChecked(e.target.checked)}
          policy={policy}
          language={language}
        />
        <PrimaryButton onClick={onSubmit} disabled={!checked}>
          {buttonText}
        </PrimaryButton>
      </div>

      <StepperButtons
        language={language}
        currency={currency}
        handlePrev={handlePrev}
        changeSection={changeSection}
        prevStep={prevStep}
      />
    </div>
  );
};

CompleteRegistration.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  changeSection: PropTypes.func.isRequired,
  message: PropTypes.string,
  language: PropTypes.string,
  currency: PropTypes.string,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  policy: PropTypes.object.isRequired,
};

export default CompleteRegistration;
