import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Mail from '@components/layout/Mail';
import { Backdrop } from '@material-ui/core';
import { connect } from 'react-redux';
import configuration from '@config/conf';

const modalStyle = (newPosition, color) => {
  return {
    width: 48,
    minHeight: 48,
    backgroundColor: color,
    borderRadius: '100%',
    position: newPosition,
    bottom: 20,
    right: 40,
    zIndex: 1,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.2)',
  };
};

const Message = (props) => {
  const [className, setClassname] = useState(props.openState ? 'grow' : 'init');
  const [formVisible, setFormVisibility] = useState('');
  const [modalIsOpen, setModalStatus] = useState(false);
  const [iconAnimation, setIconAnimation] = useState('');
  const [firstRender, setFirstRender] = useState(true);

  React.useEffect(() => {
    if (props.openState) {
      setFirstRender(false);
      openModal();
    } else if (!props.openState && !firstRender) {
      closeModal();
    }
  }, [props.openState]);

  const openModal = (e) => {
    e?.stopPropagation();
    setClassname('grow');
    setFormVisibility('appear');
    setModalStatus(true);
    setIconAnimation('disappear');
  };

  const closeModal = (e) => {
    e?.stopPropagation();
    setClassname('shrink');
    setFormVisibility('disappear');
    setModalStatus(false);
    setIconAnimation('appear');
  };

  const innerModalCloseHandler = (e) => {
    closeModal(e);
    if (props.onClose) props.onClose();
  };

  return (
    <React.Fragment>
      <Backdrop
        className="backdrop-message"
        open={props.openState}
        onClick={innerModalCloseHandler}
      />
      <div
        style={modalStyle(props.position, props.color)}
        className={`${className}${props.externalControl ? '-external' : ''} ${
          props.messageFailAttempts >= configuration.allowedNoCaptcha
            ? 'captcha'
            : ''
        }`}
        onClick={(e) => openModal(e)}>
        {modalIsOpen && (
          <div className={`${formVisible} mail-form`}>
            <React.Fragment>
              <Mail
                event={props.event}
                sessionName={''}
                mailto={props.supportEmail}
                userId={''}
                language={props.language}
                isMessage={true}
                userMail={props.userMail}
                createCustomEmail={props.createCustomEmail}
                closeModal={innerModalCloseHandler}
                openState={props.openState}
                subNoteText={props.subNoteText}
                addNotification={props.addNotification}
              />
            </React.Fragment>
          </div>
        )}

        <span
          className={`${iconAnimation} icon-bubble`}
          style={{ color: 'white', margin: 'auto' }}
        />
      </div>
    </React.Fragment>
  );
};

Message.propTypes = {
  position: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  messageFailAttempts: PropTypes.number.isRequired,
  event: PropTypes.object.isRequired,
  language: PropTypes.string,
  userMail: PropTypes.string,
  supportEmail: PropTypes.string,
  subNoteText: PropTypes.string,
  createCustomEmail: PropTypes.func,
  addNotification: PropTypes.func,
  onClose: PropTypes.func,
  openState: PropTypes.bool,
  externalControl: PropTypes.bool,
};

Message.defaultProps = {
  openState: false,
  externalControl: false,
};

const mapStateToProps = (state) => {
  return {
    messageFailAttempts: state.api.recaptcha.message.failedAttempts,
  };
};

export default connect(mapStateToProps, {})(Message);
