import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import NavigateModal from '@components/layout/NavigateModal';

const sortByIndex = (a, b) => {
  if (a.index > b.index) {
    return 1;
  }
  if (a.index < b.index) {
    return -1;
  }
  return 0;
};
const Banner = (props) => {
  const { event } = props;
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [linkToNavigateTo, setLinkToNavigateTo] = React.useState(false);

  const homeBannerSettings = event.clientPanelSettings.home;
  const useJumbotron = homeBannerSettings.homeJumbotronAsBackground;
  const homeTitle = homeBannerSettings.homeTitle;
  const homeSubtitle = homeBannerSettings.homeSubtitle;
  const textColor = homeBannerSettings.colors.text;
  const dateBefore =
    window.location.host === 'bluetree.events' ? '2021-02-02' : '2019-12-31';
  const mask = homeBannerSettings.colors.backgroundMask;
  const checkDate =
    moment(event.createdAt.tz).isBefore(dateBefore) &&
    !homeBannerSettings.clientHomeJumbotron[0]?.url &&
    !homeBannerSettings.clientHomeForegroundLogo[0]?.url &&
    !homeBannerSettings.homeTitle &&
    !homeBannerSettings.homeSubtitle;
  const jumbotron = checkDate
    ? event.banners[0]?.url
    : homeBannerSettings.clientHomeJumbotron[0]?.url;
  const jumbotronForeground =
    homeBannerSettings.clientHomeForegroundLogo[0]?.url;
  const ctaBtns =
    homeBannerSettings.homeCallToActions.length > 0
      ? homeBannerSettings.homeCallToActions.sort(sortByIndex)
      : [];

  return (
    <React.Fragment>
      <div
        className={`jumbo-container ${useJumbotron ? 'background' : ''}`}
        style={{
          height: useJumbotron ? '695px !important' : 'auto !important',
          padding: useJumbotron ? 20 : 0,
        }}>
        {jumbotron && (
          <img
            src={jumbotron}
            style={{
              position: useJumbotron ? 'absolute' : 'relative',
              width: '100%',
              objectFit: useJumbotron ? 'cover' : 'contain',
              height: useJumbotron ? '100%' : 'auto !important',
              maxHeight: checkDate ? 500 : 'unset',
            }}
            className="jumbotron-img"
          />
        )}

        {useJumbotron ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: mask,
            }}
          />
        ) : null}
        {homeBannerSettings && useJumbotron ? (
          <div
            className="jumbo-content"
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <img
              src={jumbotronForeground}
              style={{
                maxWidth: '90%',
                objectFit: 'contain',
                marginBottom: 30,
              }}
            />
            <h3
              style={{
                maxWidth: '90%',
                color: textColor,
                marginTop: 20,
                marginBottom: 10,
                textAlign: 'center',
              }}>
              {homeTitle}
            </h3>
            <h5
              style={{
                maxWidth: '90%',
                color: textColor,
                margin: '10px 0',
                textAlign: 'center',
              }}>
              {homeSubtitle}
            </h5>
          </div>
        ) : null}
        <div className="cta-container">
          {useJumbotron
            ? ctaBtns.map((btn) => {
                return (
                  <a
                    key={btn.id}
                    onClick={() => {
                      setModalIsOpen(true);
                      setLinkToNavigateTo(btn.url);
                    }}>
                    {btn.name}
                  </a>
                );
              })
            : null}
        </div>
      </div>
      {modalIsOpen && (
        <NavigateModal
          changeModalStatus={() => {
            setModalIsOpen(false);
            setLinkToNavigateTo('');
          }}
          linkToNavigateTo={linkToNavigateTo}
          language={props.language}
          color={props.event?.clientPanelSettings?.general?.colors?.primary}
        />
      )}
    </React.Fragment>
  );
};
Banner.propTypes = {
  time: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired,
  language: PropTypes.string,
};

export default Banner;
