import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from '@components/layout/Select';
import IconButton from '@material-ui/core/IconButton';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const Widget = (props) => {
  const { getHotels, checkAvailability, hotelDates, userHasAccommodation } =
    props;

  const [checkIn, setcheckIn] = useState(
    hotelDates?.from ? moment(hotelDates.from) : null
  );
  const [checkOut, setcheckOut] = useState(
    hotelDates?.to ? moment(hotelDates.to) : null
  );

  const [hotelRank, sethotelRank] = useState('');

  const [guests, setGuests] = useState(props.guests ?? 1);

  const [focusedInput, setFocusedInput] = useState();

  const renderStarOption = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<span className="icon-fill-1" key={i} />);
    }
    return (
      <div className="filter-stars">
        {stars}
        <span>
          {' '}
          {rating} Star{rating > 1 ? 's' : ''}
        </span>
      </div>
    );
  };

  const parsedStars = {
    1: renderStarOption(1),
    2: renderStarOption(2),
    3: renderStarOption(3),
    4: renderStarOption(4),
    5: renderStarOption(5),
  };

  const resetFilters = () => {
    setcheckIn(null);
    setcheckOut(null);
    sethotelRank('');
    setGuests(1);
    if (props.roomWidget) {
      checkAvailability(props.hotel.id, {});
      return;
    }
    getHotels(userHasAccommodation, {});
  };

  const applyFilters = () => {
    const meta = {};

    if (checkIn && checkOut) {
      meta.dateRange = JSON.stringify({
        from: checkIn.format('DD-MM-YYYY'),
        to: checkOut.format('DD-MM-YYYY'),
      });
    }

    if (hotelRank && !props.roomWidget) {
      meta.stars = hotelRank;
    }

    if (guests && guests !== window.lang[props.language]['selectMaxGuests']) {
      meta.guests = guests;
    }
    if (props.roomWidget) {
      checkAvailability(meta);
      return;
    }
    getHotels(userHasAccommodation, meta);
  };

  return (
    <div className={`hotel-filters ${!props.roomWidget ? 'sticky' : 'inner'}`}>
      <div className="tools-wrapper">
        <div className="date-selector hotel-filter-wrapper">
          <label className="checkin-title">
            {window.lang[props.language]['checkIn_checkOut']}
          </label>

          <DateRangePicker
            startDate={checkIn}
            startDateId="checkIn"
            endDate={checkOut}
            endDateId="checkOut"
            onDatesChange={({ startDate, endDate }) => {
              setcheckIn(startDate);
              setcheckOut(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            displayFormat={'DD/MM/YYYY'}
          />
        </div>
        {!props.roomWidget && (
          <div className="hotel-rank-wrapper hotel-filter-wrapper">
            <label className="hotel-rank-title">
              {window.lang[props.language]['hotelRank']}
            </label>
            <Select
              value={hotelRank}
              options={parsedStars}
              className="hotel-rank-picker"
              placeholder={window.lang[props.language]['selectStarRank']}
              placeholderInsideSelect={false}
              onChange={(rank) => {
                sethotelRank(rank);
              }}
            />
          </div>
        )}

        <div className="max-guests-wrapper hotel-filter-wrapper">
          <label className="max-guests-title">
            {window.lang[props.language]['guests']}
          </label>
          <div className="add-remove">
            <IconButton
              className="quantity-icon-button"
              aria-label="remove-Guests"
              disabled={guests == 1}
              onClick={() => {
                setGuests(Number(guests) - 1);
              }}>
              <RemoveCircleOutlineIcon
                style={{
                  color:
                    guests == 1
                      ? 'var(--gray-100)'
                      : window.colorPalette.primary[400],
                }}
                className={`remove-icon ${
                  guests == 1 ? 'disabled' : ''
                } quantity-icon`}
              />
            </IconButton>
            <span className="quantity-number">{guests}</span>
            <IconButton
              disabled={guests >= Math.max(props.guests)}
              className="quantity-icon-button"
              aria-label="add-Guests"
              onClick={() => {
                // if number of guests is bigger or equal to max props guests then return
                if (guests >= Math.max(props.guests)) {
                  return;
                }
                setGuests(Number(guests) + 1);
              }}>
              <AddCircleOutlineIcon
                style={{
                  color:
                    guests >= Math.max(props.guests)
                      ? 'var(--gray-100)'
                      : window.colorPalette.primary[400],
                }}
                className="add-icon quantity-icon"
              />
            </IconButton>
          </div>
        </div>
      </div>
      <button
        className="btn btn-primary check-availability"
        onClick={applyFilters}>
        {window.lang[props.language]['applyFilters']}
      </button>
      <div className="reset-button" aria-label="delete" onClick={resetFilters}>
        <span>{window.lang[props.language]['reset']}</span>
        <SettingsBackupRestoreIcon className="reset-button-icon" />
      </div>
    </div>
  );
};

Widget.propTypes = {
  language: PropTypes.string.isRequired,
  roomWidget: PropTypes.bool,
  event: PropTypes.object.isRequired,
  checkAvailability: PropTypes.func,
  getHotels: PropTypes.func,
  hotel: PropTypes.object,
  guests: PropTypes.array,
  userHasAccommodation: PropTypes.bool,
  hotelDates: PropTypes.object,
};

Widget.defaultProps = {
  roomWidget: false,
  hotel: null,
};
export default Widget;
