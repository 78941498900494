import React, { useRef, useState } from 'react';
import SingleProduct from '../SingleProduct';
import PropTypes from 'prop-types';
import CartComponent from './CartComponent';
import CartMobileComponent from './CartMobileComponent';
import { debounce } from 'lodash';
import { useEffect } from 'react';

const IS_MOBILE_LIMIT_PIXELS = 1004;
export default function AllProducts({
  products,
  language,
  color,
  currency,
  setProductPrices,
  handleProceedToPurchase,
  userProducts,
}) {
  const [cart, setCart] = useState([]);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= IS_MOBILE_LIMIT_PIXELS
  );
  const windowSizeRef = useRef(window.innerWidth);

  useEffect(() => {
    const handleResize = debounce(() => {
      const isMobileNew = window.innerWidth <= IS_MOBILE_LIMIT_PIXELS;
      if (isMobileNew === windowSizeRef.current) return;
      windowSizeRef.current = isMobileNew;
      setIsMobile(isMobileNew);
    }, 10);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel(); // Cancel the debounce when component unmounts
    };
  }, []);

  const clearCart = () => {
    setCart([]);
  };
  const productCards = [];

  products.map((product) => {
    product.prices.map((price) => {
      const numberOfProductsInCart = cart
        .filter((item) => {
          return item.eventProductId == price.eventProductId;
        })
        .reduce((total, item) => total + item.quantity, 0);
      const remainingQuantity =
        product.remainingOnlineQuantity != null
          ? product.remainingOnlineQuantity - numberOfProductsInCart
          : Infinity;

      const purchasedQuantity = userProducts
        .filter((item) => item.eventProductId == product.id)
        .reduce((total, item) => total + item.quantity, 0);

      const maxQuantityReached =
        product.maxQuantityPerUser != null &&
        purchasedQuantity + numberOfProductsInCart >=
          product.maxQuantityPerUser;

      console.info(
        purchasedQuantity,
        numberOfProductsInCart,
        product.maxQuantityPerUser,
        maxQuantityReached,
        product.maxQuantityPerUser != null,
        product.maxQuantityPerUser != null &&
          purchasedQuantity + numberOfProductsInCart >=
            product.maxQuantityPerUser,
        remainingQuantity,
        product.remainingOnlineQuantity
      );
      productCards.push(
        <SingleProduct
          key={price.id}
          name={`${product.name} - ${price.name}`}
          description={product.description}
          language={language}
          setProductPrices={setProductPrices}
          color={color}
          price={price.sellingPrice}
          currency={currency}
          numberOfProductsInCart={numberOfProductsInCart}
          remainingQuantity={remainingQuantity}
          maxQuantityReached={maxQuantityReached}
          onChangeQuantity={(newQuantity) => {
            const newCart = [...cart];
            const index = newCart.findIndex((item) => item.id === price.id);
            if (index !== -1) {
              if (newQuantity === 0) {
                newCart.splice(index, 1);
              } else {
                newCart[index].quantity = newQuantity;
              }
            } else {
              newCart.push({ ...price, quantity: newQuantity });
            }
            setCart(newCart);
          }}
          onAction={() => {
            const newCart = [...cart];
            // if there is already a product in the cart with the same id, add the quantity +1 or add a quantity of 1
            const index = newCart.findIndex((item) => item.id === price.id);
            if (index !== -1) {
              newCart[index].quantity += 1;
            } else {
              newCart.push({ ...price, quantity: 1 });
            }
            setCart(newCart);
          }}
          onCancel={() => {}}
        />
      );
    });
  });

  return (
    <>
      <div className="all-products-container">
        {productCards.length === 0 && (
          <div className="empty-results">
            <span className="icon-warning-2" />

            <p>{window.lang[language]['noProducts']}</p>
          </div>
        )}
        {productCards}
      </div>
      {!isMobile && (
        <CartComponent
          cart={cart}
          products={products}
          language={language}
          color={color}
          currency={currency}
          setProductPrices={setProductPrices}
          handleProceedToPurchase={handleProceedToPurchase}
          userProducts={userProducts}
          totalItemsInCart={cart.length}
          clearCart={clearCart}
          totalAmount={cart.reduce(
            (sum, item) => sum + item.sellingPrice * item.quantity,
            0
          )}
          className={'desktop-cart'}
        />
      )}
      {isMobile && (
        <CartMobileComponent
          cart={cart}
          products={products}
          language={language}
          color={color}
          currency={currency}
          setProductPrices={setProductPrices}
          handleProceedToPurchase={handleProceedToPurchase}
          userProducts={userProducts}
          totalItemsInCart={cart.length}
          clearCart={clearCart}
          totalAmount={cart.reduce(
            (sum, item) => sum + item.sellingPrice * item.quantity,
            0
          )}
          className={'mobile-cart'}
        />
      )}
    </>
  );
}

AllProducts.propTypes = {
  userProducts: PropTypes.array,
  products: PropTypes.array,
  language: PropTypes.string,
  color: PropTypes.string,
  currency: PropTypes.string,
  setProductPrices: PropTypes.func,
  handleProceedToPurchase: PropTypes.func,
};
