import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
// import { colorPalette } from "@config/colorPalette";
import UserNotifications from '@components/layout/UserNotifications';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import UserMenuMobile from '@components/layout/UserMenuMobile';

const AppMobileTopbar = (props) => {
  const [navOpen, setNavOpen] = React.useState(false);
  const [paymentsDue, setPaymentsDue] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [enabledMenuItems, setEnabledMenuItems] = React.useState([]);
  const [masterFeatureControl, setMasterFeatureControl] = React.useState([]);

  const handleResize = () => {
    if (window.innerWidth > 834) {
      closeNav();
      setIsMobile(false);
    } else if (window.innerWidth <= 834) {
      setIsMobile(true);
    }
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    const newEnabledItems = [];
    Object.entries(props.menu).forEach(([key, value]) => {
      if (value.enabled && !value.hidden && key !== 'billing') {
        newEnabledItems.push({ key, ...value });
      }
    });

    if (props.event?.featuresOrder?.length > 0) {
      setMasterFeatureControl(props.event.featuresOrder);
    }

    const sortedEnabledItems = new Array(props.event?.featuresOrder?.length);

    masterFeatureControl.forEach((feat_control) => {
      newEnabledItems.forEach((feature) => {
        if (
          feat_control.key === props.featureKeyMap[feature.key] &&
          !!feat_control.enabled
        ) {
          sortedEnabledItems[feat_control.index - 1] = feature;
        }
      });
    });

    setEnabledMenuItems(
      sortedEnabledItems.filter((n) => {
        if (n) return n;
      })
    );
  }, [props.menu]);

  React.useEffect(() => {
    let totalPaymentsDue = 0;
    props.user?.data?.products?.forEach((product) => {
      if (product.dueAmount > 0) {
        totalPaymentsDue++;
      }
    });
    setPaymentsDue(totalPaymentsDue);
  }, [props.user.data]);

  const closeNav = () => {
    setNavOpen(false);
    document.body.style.overflow = 'auto';
  };

  const openNav = () => {
    setNavOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const showRegister =
    (props.policy.data.onlineRegistration === 1 &&
      props.policy.data.type === 'private' &&
      props.active !== 'login' &&
      props.policy.data.screen !== 'register') ||
    (props.policy.data.type === 'private' &&
      props.active === 'login' &&
      props.policy.data.screen === 'login');

  return (
    <React.Fragment>
      <div className="app-mobile-topbar">
        {props.policy.data.clientPanelSettings?.header?.banner[0]?.url !==
          null && (
          <div className="topbar-logo">
            <div className="event-logo-wrapper">
              <img
                src={
                  props.policy.data.clientPanelSettings?.header?.banner[0]?.url
                }
                className="event-logo"
              />
            </div>
          </div>
        )}
        <div className="topbar-actions">
          {props.user.data.id && (
            <UserNotifications
              isMobile={isMobile}
              color={window.colorPalette.primary[600]}
            />
          )}

          {navOpen ? (
            <CloseIcon className="close-indicator-wrapper" onClick={closeNav} />
          ) : (
            <React.Fragment>
              <MenuIcon className="cart-indicator-wrapper" onClick={openNav}>
                {' '}
              </MenuIcon>
              {paymentsDue > 0 && (
                <div
                  className="cart-indicator"
                  style={{ backgroundColor: window.colorPalette.primary[600] }}
                />
              )}
            </React.Fragment>
          )}

          <Drawer anchor="top" open={navOpen && isMobile}>
            <div className="drawer-wrapper">
              <div
                className={`top-drawer-section ${
                  props.user.data.id ? 'logged-in' : 'logged-out'
                }`}>
                {props.user.data.id !== null && (
                  <div className="account-drawer">
                    <UserMenuMobile
                      logout={props.logout}
                      changeSection={props.changeSection}
                      language={props.language}
                      user={props.user}
                      backgroundColor={window.colorPalette.primary[100]}
                      backgroundCart={window.colorPalette.primary[600]}
                      currency={props.currency}
                      getUser={props.getUser}
                      closeNav={closeNav}
                    />
                  </div>
                )}
                <div
                  className={`nav-drawer ${
                    props.user.data.id ? 'logged-in' : 'logged-out'
                  }`}>
                  {enabledMenuItems.map((item) => {
                    return (
                      item.key !== 'billing' && (
                        <div
                          className="nav-link"
                          key={item.key}
                          style={{
                            color:
                              props.active === item.key &&
                              window.colorPalette.primary[600],
                          }}
                          onClick={() => {
                            closeNav();
                            props.changeSection(item.key);
                          }}>
                          {item.label}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
              <div className="actions-drawer">
                {props.user.data.id ? (
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: window.colorPalette.primary[600],
                      color: 'white',
                    }}
                    onClick={() => {
                      closeNav();
                      window.open(`mailto:${props.policy.data.supportEmail}`);
                    }}
                    disableElevation
                    className="contact-button"
                    variant="contained">
                    {window.lang[props.language].contactText}
                  </Button>
                ) : (
                  <React.Fragment>
                    {showRegister && (
                      <Button
                        style={{
                          textTransform: 'none',
                          color: 'white',
                          backgroundColor: window.colorPalette.primary[600],
                        }}
                        onClick={() => {
                          closeNav();
                          props.changeScreen('register');
                          props.changeSection('login');
                        }}
                        disableElevation
                        className="registration-nav"
                        variant="contained">
                        {window.lang[props.language].registration}
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        props.changeSection('login');
                        props.changeScreen('login');
                        closeNav();
                      }}
                      className="login-nav"
                      variant="contained">
                      {window.lang[props.language]['login']}
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </React.Fragment>
  );
};

AppMobileTopbar.propTypes = {
  policy: PropTypes.object,
  featureKeyMap: PropTypes.object,
  language: PropTypes.string,
  currency: PropTypes.string,
  menu: PropTypes.object.isRequired,
  active: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
  event: PropTypes.object,
  changeScreen: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getUser: PropTypes.func,
  updateInViewMenuItem: PropTypes.func.isRequired,
  itemsInView: PropTypes.object.isRequired,
};

export default AppMobileTopbar;
