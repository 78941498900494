import { connect } from 'react-redux';
import GeneralInfo from './GeneralInfo';

const mapStateToProps = (state) => {
  return {
    event: state.api.event.data,
    ready: state.api.event.ready,
    language: state.api.language.selected,
  };
};

export default connect(mapStateToProps, undefined)(GeneralInfo);
