import React from 'react';
import Select from '@components/layout/Select2';
import { getData } from 'country-list';
import PropTypes from 'prop-types';

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export default function PaymentCardBtn(props) {
  const [countriesOptions, setCountriesOptions] = React.useState({});

  React.useEffect(() => {
    const newCountriesOptions = {};
    getData()
      .sort(compare)
      .map((v) => (newCountriesOptions[v.code] = v.name));
    setCountriesOptions(newCountriesOptions);
  }, []);

  return (
    <div id="card-option" style={{ display: 'inline-block' }}>
      <span className="bank-data">
        <div
          style={{ display: 'inline-block' }}
          className={`col-sm-12 col-md-6 form-group select-border-bottom`}>
          <label>{window.lang[props.language]['country']} *</label>
          <Select
            options={Object.keys(countriesOptions)
              .map((key) => ({ key: key, value: countriesOptions[key] }))
              .reduce((a, b) => ({ ...a, [b.key]: b.value }), {})}
            value={props.fields['country']}
            onChange={(e) =>
              props.onChange('country', { target: { value: e } })
            }
          />
        </div>
        <div
          style={{ display: 'inline-block' }}
          className={`col-sm-12 col-md-6 form-group`}>
          <label>{window.lang[props.language]['state']} *</label>
          <input
            value={props.fields['state']}
            onChange={(e) => props.onChange('state', e)}
            type="text"
          />
        </div>
        <div
          style={{ display: 'inline-block' }}
          className={`col-sm-12 col-md-6 form-group`}>
          <label>{window.lang[props.language]['city']} *</label>
          <input
            value={props.fields['city']}
            onChange={(e) => props.onChange('city', e)}
            type="text"
          />
        </div>
        <div
          style={{ display: 'inline-block' }}
          className={`col-sm-12 col-md-6 form-group`}>
          <label>{window.lang[props.language]['zipCode']} *</label>
          <input
            value={props.fields['zipCode']}
            onChange={(e) => props.onChange('zipCode', e)}
            type="text"
          />
        </div>
        <div
          style={{ display: 'inline-block' }}
          className={`col-sm-12 col-md-6 form-group`}>
          <label>{window.lang[props.language]['address']} *</label>
          <input
            value={props.fields['address']}
            onChange={(e) => props.onChange('address', e)}
            type="text"
          />
        </div>
        <div
          style={{ display: 'inline-block' }}
          className={`col-sm-12 col-md-6 form-group`}>
          <label>{window.lang[props.language]['phoneNumber']}</label>
          <input
            value={props.fields['phoneNumber']}
            onChange={(e) => props.onChange('phoneNumber', e)}
            type="text"
          />
        </div>
        <div
          style={{ display: 'inline-block' }}
          className={`col-sm-12 col-md-12 form-group`}>
          <label>{window.lang[props.language]['email']}</label>
          <input
            value={props.fields['email']}
            onChange={(e) => props.onChange('email', e)}
            type="text"
          />
        </div>
      </span>
    </div>
  );
}

PaymentCardBtn.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func,
  fields: PropTypes.object,
};
