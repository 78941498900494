import React from 'react';
import PropTypes from 'prop-types';

export default class CancelRegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    document.body.style.overflow = 'hidden';
  }

  handleClose = (e) => {
    e.stopPropagation();
    document.body.style.overflow = 'auto';
    this.props.setModalVisibility();
  };

  handleConfirm = (e) => {
    e.stopPropagation();
    document.body.style.overflow = 'auto';
    //TODO: Change this to a more react-like way
    //TODO: Tried ChangeScreen(). It doesn't work because it's in the same url
    const baseUrl =
      window.location.origin + window.location.pathname + '/login';
    window.history.pushState({}, '', baseUrl);
    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="backdrop"
          style={{ zIndex: 105 }}
          onClick={(e) => this.handleClose(e)}
        />
        <div className="cancel-registration-modal" id="modal">
          <div className="warning-icon-wrapper">
            <img
              className={`warning-icon`}
              src={`${process.env.PUBLIC_URL}/warning_icon.svg`}
            />
          </div>
          <div className="lectical">
            {window.lang[this.props.language]['cancelRegistrationConfirmation']}
          </div>
          <div className="btn-row">
            <div className="cancel btn" onClick={(e) => this.handleClose(e)}>
              {window.lang[this.props.language]['close']}
            </div>
            <div
              className="confirm btn"
              style={{ background: window.colorPalette.primary[600] }}
              onClick={(e) => {
                this.handleConfirm(e);
              }}>
              {window.lang[this.props.language]['cancelRegistration']}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CancelRegistrationModal.propTypes = {
  setModalVisibility: PropTypes.func,
  language: PropTypes.string,
};
