import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

const InViewCallChild = forwardRef((props, ref) => {
  useEffect(() => {
    if (props.isInView !== props.inView) {
      props.updateInViewMenuItem(props.item.key, props.inView);
    }
  }, [
    props.inView,
    props.isInView,
    props.item.key,
    props.updateInViewMenuItem,
  ]);

  return (
    <div
      ref={ref}
      key={`nav-item-${props.item.key}`}
      className={`nav-item${props.active === props.item.key ? ' active' : ''}`}
      onClick={() => {
        props.changeSection(props.item.key);
        props.changeScreen(props.item.key);
      }}>
      <p
        lang={props.language}
        style={{
          color:
            props.active === props.item.key
              ? window.colorPalette.primary[600]
              : 'inherit', // Fallback color or you can choose a specific color
        }}>
        {props.item.label}
      </p>
    </div>
  );
});

InViewCallChild.propTypes = {
  isInView: PropTypes.bool,
  inView: PropTypes.bool,
  item: PropTypes.object.isRequired,
  active: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
  changeScreen: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  updateInViewMenuItem: PropTypes.func.isRequired,
};

const ObservableMenuItem = (props) => {
  return (
    <InView>
      {({ inView, ref }) => (
        <InViewCallChild {...props} inView={inView} ref={ref} />
      )}
    </InView>
  );
};

ObservableMenuItem.propTypes = {
  language: PropTypes.string.isRequired,
  isInView: PropTypes.bool,
  active: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
  changeScreen: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  updateInViewMenuItem: PropTypes.func.isRequired,
};

export default ObservableMenuItem;
