import React from 'react';
import PropTypes from 'prop-types';
import Check from '@material-ui/icons/Check';
// import { colorPalette } from "@config/colorPalette";

export default class StepperIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          color:
            this.props.active || this.props.completed
              ? window.colorPalette.primary[600]
              : '',
        }}
        className={`root-dot`}>
        {this.props.completed ? (
          <Check className="completed" />
        ) : (
          <div className="outter-circle">
            <span className="dot" />
          </div>
        )}
      </div>
    );
  }
}

StepperIcon.propTypes = {
  colors: PropTypes.object,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  error: PropTypes.bool,
  icon: PropTypes.number,
};
