import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Speeches from '../Speeches/Speeches';

const format = {
  day: 'DD/MM/YYYY',
  time: 'HH:mm',
  year: 'YYYY',
};

class SessionInner extends React.Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.props.loadSessionById(this.props.sessionId);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const props = this.props;

    const { innerSession } = props;
    let start, end;
    if (innerSession.startDate && innerSession.endDate) {
      start = moment(innerSession.startDate.tz);
      end = moment(innerSession.endDate.tz);
    }

    return (
      innerSession?.name?.length > 0 && (
        <div className="component-session-inner">
          <div className="container">
            <div className="headline">
              <div className="row">
                <div className="col-sm-6">
                  <h1>{`${window.lang[this.props.language]['session']} "${
                    props.innerSession.name
                  }"`}</h1>
                  <div className="session-additional">
                    <p className="date-wrapper">
                      <span className="date">
                        {moment(start).format(format.day)}
                      </span>
                      <span className="time">
                        {moment(start).format(format.time)} -{' '}
                        {moment(end).format(format.time)}
                      </span>
                    </p>
                    <p className="room-wrapper">{innerSession.room.name}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="description">{innerSession.description}</div>
              {innerSession.sessionFiles.length > 0 && (
                <div className="session-media">
                  <h2>{window.lang[this.props.language]['sessionMedia']}</h2>
                  <ul className="media-list row" style={{ marginRight: 0 }}>
                    {innerSession.sessionFiles.map((s) => (
                      <li
                        key={`file-${s.id}`}
                        className="col-sm-3 media-file card">
                        <div className={`file-inner`}>
                          <a href={s.url} target="_blank" rel="noreferrer">
                            <div className={`icon-pdf`} />
                            <h2>{s.fileName}</h2>
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Speeches
                speeches={innerSession.speeches}
                showSpeaker={props.showSpeaker}
                language={props.language}
                title={window.lang[props.language]['speeches']}
                showVideo={this.props.showVideo}
                event={this.props.event}
                loggedIn={this.props.loggedIn}
                changeSection={this.props.changeSection}
              />
            </div>
          </div>
        </div>
      )
    );
  }
}

SessionInner.propTypes = {
  changeSection: PropTypes.func.isRequired,
  innerSession: PropTypes.object.isRequired,
  showSpeaker: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  loadSessions: PropTypes.func.isRequired,
  loadSessionById: PropTypes.func.isRequired,
  showVideo: PropTypes.func.isRequired,
  event: PropTypes.object,
  loggedIn: PropTypes.bool,
  sessionId: PropTypes.number.isRequired,
  language: PropTypes.string,
};

export default SessionInner;
