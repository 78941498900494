import React from 'react';
import PropTypes from 'prop-types';
import KMBLoader from '@components/layout/KMBLoader';
import Thumbnail from './partials/Thumbnail';
import { connect } from 'react-redux';
import {
  loadSpeakers,
  loadSessions,
  loadVideos,
  showVideo,
  searchVideo,
  getRooms,
} from '@actions';
import Toolbar from '@components/layout/Toolbar';

const Videos = (props) => {
  window.scrollTo(0, 0);
  const delayDebounceFn = React.useRef(null);
  const [speakerOptions, setSpeakerOptions] = React.useState({});
  const [roomOptions, setRoomOptions] = React.useState({});
  const [meta, setMeta] = React.useState({
    p: 1,
    rpp: 10,
    order: 'DESC',
    orderBy: 'id',
    search: '',
    speakerIds: '',
    roomIds: '',
  });
  React.useEffect(() => {
    props.loadSessions();
    props.loadSpeakers();
    props.getRooms(props.event.id);
    props.loadVideos(props.event.id, meta);
  }, []);

  React.useEffect(() => {
    const speakerOptions = {};
    props.speakers?.map((speaker) => {
      speakerOptions[speaker.eventUserId] =
        speaker.firstName + ' ' + speaker.lastName;
    });
    setSpeakerOptions(speakerOptions);
  }, [props.speakers]);

  React.useEffect(() => {
    const roomOptions = {};
    props.rooms?.map((room) => {
      roomOptions[room.id] = room.name;
    });
    setRoomOptions(roomOptions);
  }, [props.rooms]);

  const onChange = (key, value, debounce = false) => {
    const newMeta = { ...meta, [key]: value };
    setMeta(newMeta);
    if (debounce) {
      clearTimeout(delayDebounceFn.current);
      delayDebounceFn.current = setTimeout(() => {
        props.loadVideos(props.event.id, newMeta);
      }, 500);
    } else {
      props.loadVideos(props.event.id, newMeta);
    }
  };

  const sortVideos = (property) => {
    return function compare(a, b) {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    };
  };

  const getVideos = () => {
    let sorting_videos = [...props.videos];
    sorting_videos = sorting_videos.sort(sortVideos('name'));

    return sorting_videos
      .map((video) => {
        return (
          <Thumbnail
            key={`${props.language}-${video.eventSessionId}-${
              video.eventSpeechId ?? null
            }`}
            sessions={props.sessions}
            showVideo={props.showVideo}
            language={props.language}
            video={video}
          />
        );
      })
      .map((video) => video);
  };

  return props.ready ? (
    <div className="container">
      <Toolbar
        singleSelects={[
          {
            data: speakerOptions,
            callback: (e) => onChange('speakerIds', e.target.value),
            nullEntry: 'Select Speaker',
            currentVal: meta.speakerIds,
          },
          {
            data: roomOptions,
            callback: (e) => onChange('roomIds', e.target.value),
            nullEntry: 'Select Room',
            currentVal: meta.roomIds,
          },
        ]}
        handleSearch={(e) => onChange('search', e.target.value, true)}
        getItems={(pageMeta) => {
          props.loadVideos(props.event.id, { ...meta, ...pageMeta });
        }}
        items={props.videos}
        meta={props.meta}
        searchPrompt={meta.search}
        fetching={props.fetching}></Toolbar>
      <div className="row video-container video-gallery">
        {getVideos()}
        {props.videos.length === 0 && (
          <div className="empty-results">
            <span className="icon-warning-2" />
            <p>{window.lang[props.language]['emptyResults']}</p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="container">
      <div className="row video-container"></div>
      <KMBLoader rows={20} />
    </div>
  );
};

Videos.propTypes = {
  ready: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  videos: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  loadSessions: PropTypes.func.isRequired,
  sessions: PropTypes.array,
  search: PropTypes.string,
  roomIds: PropTypes.string,
  language: PropTypes.string,
  speakerIds: PropTypes.string,
  searchVideo: PropTypes.func.isRequired,
  loadSpeakers: PropTypes.func,
  loadVideos: PropTypes.func,
  totalVideos: PropTypes.number,
  showVideo: PropTypes.func,
  meta: PropTypes.object,
  fetching: PropTypes.bool,
  getRooms: PropTypes.func,
  speakers: PropTypes.array,
  rooms: PropTypes.array,
};

Videos.defaultProps = {
  roomIds: '',
  search: '',
  videos: [],
};

const mapStateToProps = (state) => {
  return {
    event: state.api.event.data,
    sessions: state.api.sessions.data,
    ready: state.api.event.ready && !state.api.sessions.fetching,
    language: state.api.language.selected,
    search: state.api.videos.data.search,
    meta: state.api.videos.meta,
    videos: state.api.videos.videos,
    totalVideos: state.api.videos.meta.totalRows,
    roomIds: state.api.videos.data.roomIds,
    speakerIds: state.api.videos.data.speakerIds,
    fetching: state.api.videos.fetching,
    user: state.api.user.data,
    mandatoryPayment: state.api.policy.data.mandatoryPayment,
    speakers: state.api.speakers.data,
    rooms: state.api.rooms.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSessions: () => {
      dispatch(loadSessions({ order: 'ASC', orderBy: 'startDate', rpp: -1 }));
    },
    loadVideos: (eventId, meta) => {
      dispatch(loadVideos(eventId, meta));
    },
    searchVideo: (search, roomIds, speakerIds) => {
      dispatch(searchVideo(search, roomIds, speakerIds));
    },
    showVideo: (sessionId, speechId) =>
      dispatch(showVideo(sessionId, speechId)),
    loadSpeakers: () => dispatch(loadSpeakers()),
    getRooms: (eventId) => {
      dispatch(getRooms(eventId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Videos);
