import React from 'react';
import { connect } from 'react-redux';
import PaymentWays from '@components/layout/PaymentWays';
import PropTypes from 'prop-types';
import { changeSection, submitPayment, setHasUnsavedChanges } from '@actions';
import { api } from '@actions/ajax';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class AlphaBankEC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlocking: false,
      agreeToTerms: false,
      methodSelected: false,
      totalCost: 0,
    };
    this.totalPrice = 0;
    this.items = [];
    Object.keys(props.products).map((k) => {
      this.totalPrice += parseFloat(props.products[k].paidAmount);
      this.totalPrice = Number(this.totalPrice.toFixed(2));
      this.items.push(props.products[k]);
    });
    this.columns = {
      cosmetic: {
        name: '',
      },
      quantity: {
        name: window.lang[this.props.language]['quantity'],
      },
      productToSend: {
        name: window.lang[this.props.language]['type'],
      },
      paidAmount: {
        name: window.lang[this.props.language]['amount'],
      },
    };
    this.onRender = this.onRender.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
  }

  componentDidMount() {
    window.document.getElementById('root').scrollTo(0, 0);
    if (this.props.info && this.state.isBlocking === false) {
      this.setState({ isBlocking: true });
      this.props.setHasUnsavedChanges(true);
    }
    this.calculateTotalPrice();
  }

  componentWillUnmount() {
    if ((this.props.info || {}).onUnmount) {
      this.props.info.onUnmount();
    }
  }

  onRender(col, item) {
    switch (col.key) {
      case 'cosmetic':
        return <i className="icon-check" aria-hidden="true" />;
      case 'quantity':
        return <span>{item.quantity || 1}</span>;
      case 'paidAmount':
        return (
          <span>
            {`${parseFloat(item.paidAmount).toFixed(2)} ${this.props.currency}`}
          </span>
        );
      case 'totalPrice':
        return (
          <span style={{ fontSize: 20, fontWeight: 'bold', float: 'right' }}>
            {`${item.totalPrice.toFixed(2)} ${this.props.currency}`}
          </span>
        );
    }
  }

  mapProductQuantity(item) {
    const purchases = this.props.user.products;
    let quantity = 1;
    Object.keys(purchases).map((purchase_key) => {
      if (purchases[purchase_key].id === item.id) {
        const pricePerItem =
          purchases[purchase_key].totalPrice / purchases[purchase_key].quantity;
        quantity = purchases[purchase_key].dueAmount / pricePerItem;
      }
    });
    return quantity;
  }

  mapProductPrice(item) {
    return parseFloat(item.paidAmount) || 0;
  }

  calculateTotalPrice() {
    let totalPrice = 0;
    this.items.map((item) => {
      const output = this.mapProductPrice(item);
      totalPrice += output;
    });
    this.setState({
      totalCost: totalPrice,
    });
  }

  navigateBack() {
    this.props.changeSection('billing', true);
  }

  render() {
    const { products = [] } = this.props;
    return (
      <div className="billing-wrapper">
        <div className="navigate-back-payments" onClick={this.navigateBack}>
          <ArrowBackIcon />
          {window.lang[this.props.language]['backToPayments']}
        </div>
        <div className="inner-centering-wrapper">
          <div className="payment-section-header">
            <div className="payment-section-title">
              {window.lang[this.props.language]['checkout']}
            </div>
            <div className="payment-section-text">
              {window.lang[this.props.language]['checkoutTextGeneral'] +
                ' ' +
                window.lang[this.props.language]['contactUs']}
              <span
                onClick={() =>
                  window.open(`mailto:${this.props.policy.supportEmail}`)
                }
                className="email-support">
                {this.props.policy.supportEmail}
              </span>
              {this.props.policy.supportPhoneNumber
                ? `, ${window.lang[this.props.language]['orAt']} ${
                    this.props.policy.supportPhoneNumber
                  }`
                : null}
              {'.'}
            </div>
          </div>
          <div
            className="total-costs"
            style={this.state.totalCost == 0 ? { flexBasis: '100%' } : null}>
            <div className="total-costs-title">
              {`1. ${window.lang[this.props.language]['youHaveChosen']}`}
            </div>
            <div className="costs-table">
              {this.items.map((item) => {
                return (
                  <div className="product-cost" key={`product-item${item.id}`}>
                    <div className="item-general-info">
                      <div className="item-title">{`${this.mapProductQuantity(
                        item
                      )}x ${item.type}`}</div>
                      <div className="item-price">{`
                        ${
                          this.props.currencyIso === 'USD'
                            ? this.props.currency
                            : ''
                        }
                        ${this.mapProductPrice(item).toFixed(2)}
                        ${
                          this.props.currencyIso !== 'USD'
                            ? this.props.currency
                            : ''
                        }
                      `}</div>
                    </div>
                    <div className="item-payment-info">
                      <div className="payments-wrapper">
                        {
                          <React.Fragment>
                            {this.props.policy.billing.bankOptions.includes(
                              'registration'
                            ) && this.props.policy.billing.bank === 1 ? (
                              <img
                                className={`payment-icon payment-icon-bank`}
                                src={`${process.env.PUBLIC_URL}/ecommerce/bankPayment.svg`}
                              />
                            ) : null}
                            {this.props.policy.billing.cardOptions.includes(
                              'registration'
                            ) && this.props.policy.billing.creditCard === 1 ? (
                              <img
                                className={`payment-icon payment-icon-card`}
                                src={`${process.env.PUBLIC_URL}/ecommerce/cardPayment.svg`}
                              />
                            ) : null}
                            {this.props.policy.billing.cardOptions.includes(
                              'registration'
                            ) && this.props.policy.billing.paypal === 1 ? (
                              <img
                                className={`payment-icon payment-icon-paypal`}
                                src={`${process.env.PUBLIC_URL}/ecommerce/paypalPayment.svg`}
                              />
                            ) : null}
                          </React.Fragment>
                        }
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="final-cost-sum">
              <div className="cost-sum-title">
                {window.lang[this.props.language]['totalCost']}
                <br />
                <span className="tax-warning">{`${
                  window.lang[this.props.language]['taxesIncluded']
                }`}</span>
              </div>
              <div className="cost-sum-total">{`
              ${this.props.currencyIso === 'USD' ? this.props.currency : ''}
              ${this.state.totalCost.toFixed(2)}
              ${this.props.currencyIso !== 'USD' ? this.props.currency : ''}
            `}</div>
            </div>
          </div>

          <PaymentWays
            {...this.props}
            products={products}
            totalPrice={this.totalPrice}
            clientId={this.props.billing.paypalClientId}
            paypal={
              this.props.billing.paypal
                ? {
                    amount: this.totalPrice,
                    createOrder: (data, actions) => {
                      return this.props
                        .paypalCreateOrder(this.props.eventId, {
                          products: Object.keys(products).map((k) =>
                            k.toLowerCase()
                          ),
                          orderAmount: this.totalPrice,
                          currencyIso: this.props.currencyIso,
                        })
                        .then((data) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                reference_id: data.data.finalOrderId,
                                description: data.data.productDesc,
                                amount: {
                                  currency_code: 'EUR',
                                  value: this.totalPrice,
                                },
                              },
                            ],
                          });
                        });
                    },
                    onApprove: (data, actions) => {
                      // Capture the funds from the transaction
                      return actions.order.capture().then((details) => {
                        // OPTIONAL: Call your server to save the transaction
                        this.props
                          .paypalTransactionCompleted({
                            fromPaypal: true,
                            status:
                              details.intent == 'CAPTURE'
                                ? 'CAPTURED'
                                : details.intent,
                            orderid: details.purchase_units[0].reference_id,
                            orderAmount: details.purchase_units[0].amount.value,
                            txId: details.id,
                            paymentRef: details.id,
                            details: JSON.stringify(details),
                            currency:
                              details.purchase_units[0].amount.currency_code,
                            productDesc: details.purchase_units[0].description,
                            eventId: this.props.eventId,
                            eventPolicyId: this.props.policy.id,
                            eventUserId: this.props.user.id,
                          })
                          .then((response) => {
                            window.location.replace(response.data.redirectLink);
                          });
                        return true;
                      });
                    },
                    onError: () => {
                      console.error(arguments);
                    },
                  }
                : false
            }
            card={
              this.props.billing.creditCard
                ? {
                    onChange: this.onChange,
                    countriesOptions: this.countriesOptions,
                  }
                : false
            }
            language={this.props.language}
          />
        </div>
      </div>
    );
  }
}

AlphaBankEC.propTypes = {};

const mapStateToProps = (state) => {
  return {
    user: state.api.user.data,
    currency: state.api.event.data.currencySymbol,
    eventId: state.api.event.data.id,
    currencyIso: state.api.event.data.currencyIso,
    products: state.api.payments.showingPayment.products,
    info: state.api.payments.showingPayment.info,
    counter: state.api.counter,
    policy: state.api.policy.data,
    language: state.api.language.selected,
    billing: state.api.event.data.settings.billing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSection: (section) => dispatch(changeSection(section)),
  setHasUnsavedChanges: (status) => dispatch(setHasUnsavedChanges(status)),
  paypalCreateOrder: (eventId, data) =>
    dispatch(
      api({
        endpoint: 'payments',
        action: 'paypalCreateOrder',
        body: { data },
        params: { eventId },
      })
    ),
  paypalTransactionCompleted: (data) =>
    dispatch(
      api({
        endpoint: 'payments',
        action: 'paypalTransactionCompleted',
        body: { data },
      })
    ),
  submitPayment: (
    eventId,
    products,
    totalPrice,
    currencyIso,
    fields,
    collaboratorName
  ) => {
    return dispatch(
      submitPayment(eventId, products, totalPrice, currencyIso, fields)
    ).then((d) => {
      if (collaboratorName.includes('alpha-bank')) {
        window.location.replace(d.data.location);
      } else if (collaboratorName.includes('piraeus-bank')) {
        const f = document.createElement('form');
        f.setAttribute('method', 'post');
        f.setAttribute(
          'action',
          'https://staging-client.bluetree.events/piraeus'
        );

        for (const i in d.data) {
          const input = document.createElement('input'); //input element, text
          input.setAttribute('name', [i]);
          input.setAttribute('type', 'hidden');
          input.setAttribute('value', d.data[i]);
          f.appendChild(input);
        }
        document.getElementsByTagName('body')[0].appendChild(f);
        f.submit();
      }
    });
  },
});

AlphaBankEC.propTypes = {
  info: PropTypes.object,
  setHasUnsavedChanges: PropTypes.func,
  products: PropTypes.object,
  language: PropTypes.string,
  changeSection: PropTypes.func,
  currency: PropTypes.string,
  eventId: PropTypes.number,
  billing: PropTypes.object,
  policy: PropTypes.object,
  user: PropTypes.object,
  submitPayment: PropTypes.func,
  paypalTransactionCompleted: PropTypes.func,
  paypalCreateOrder: PropTypes.func,
  currencyIso: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlphaBankEC);
