import { connect } from 'react-redux';
import SingleVideo from './SingleVideo';
import {
  changeSection,
  loadSessions,
  showVideo,
  watchtimeStatistics,
  createCustomEmail,
  addNotification,
  getUser,
} from '@actions';

const mapStateToProps = (state) => {
  return {
    event: state.api.event.data,
    userId: state.api.user.data.id,
    user: state.api.user.data,
    sessions: state.api.sessions.data,
    ready: !state.api.sessions.fetching,
    language: state.api.language.selected,
    sessionId: Number(state.api.videos.showingVideo.sessionId),
    speechId: Number(state.api.videos.showingVideo.speechId),
    mandatoryPayment: state.api.policy.data.mandatoryPayment,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSection: (section, pageProps) =>
    dispatch(changeSection(section, undefined, pageProps)),
  createCustomEmail: (eventId, data) =>
    dispatch(createCustomEmail(eventId, data)),
  getUser: () => dispatch(getUser()),
  showVideo: (sessionId, speechId) => dispatch(showVideo(sessionId, speechId)),
  loadSessions: () => {
    return dispatch(
      loadSessions({ order: 'ASC', orderBy: 'startDate', rpp: '-1' })
    );
  },
  addNotification: (message, number) => {
    dispatch(addNotification(message, number));
  },
  watchtimeStatistics: (eventId, data) =>
    dispatch(watchtimeStatistics(eventId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleVideo);
