import { connect } from 'react-redux';
import Overall from './Overall';
import {
  loadSessions,
  showSpeeches,
  addToAgenda,
  userInSession,
  showVideo,
  changeSection,
  getRooms,
  showSpeaker,
} from '@actions';

const mapStateToProps = (state, ownProps) => {
  const agenda = state.api.user.data.info.hasOwnProperty('favouriteSessions')
    ? state.api.user.data.info.favouriteSessions.split(',').filter((s) => s)
    : [];

  return {
    loggedIn: !!state.api.user.data.id,
    user: state.api.user.data,
    sessions: ownProps.hasOwnProperty('sessions')
      ? ownProps.sessions
      : state.api.sessions.data,
    ready: state.api.sessions.ready,
    calendarReady: state.api.sessions.calendarReady,
    meta: state.api.sessions.meta,
    event: state.api.event.data,
    rooms: state.api.rooms.data,
    sessionsFetching: state.api.sessions.fetching,
    agenda,
    mode: ownProps.hasOwnProperty('mode') ? ownProps.mode : 'overall',
    language: state.api.language.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSpeeches: (sessionId) => {
      dispatch(showSpeeches(sessionId));
    },
    loadSessions: (meta) => {
      dispatch(loadSessions(meta));
    },
    getRooms: (eventId) => {
      dispatch(getRooms(eventId));
    },
    userInSession: (sessionId, remove) => {
      dispatch(userInSession(sessionId, remove));
    },
    addToAgenda: (sessionId, remove = false) => {
      dispatch(addToAgenda(sessionId, remove));
    },
    showVideo: (session, speech) => {
      dispatch(showVideo(session, speech));
    },
    changeSection: (section) => {
      dispatch(changeSection(section));
    },
    showSpeaker: (id) => dispatch(showSpeaker(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overall);
