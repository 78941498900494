import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class Sponsors extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const sponsorSchema =
      this.props.event.clientPanelSettings.sponsors.sponsorsSchema;

    if (sponsorSchema && sponsorSchema.length > 0) {
      return (
        <div>
          <div className="">
            <p className="section-title sponsors-title">Sponsors</p>
            <p className="section-subtitle sponsors-title">Meet our sponsors</p>
          </div>
          <div className="sponsor-section">
            {sponsorSchema.length > 0 &&
              sponsorSchema.map((sponsor, i) => {
                return (
                  <div key={i} className="sponsor">
                    <a
                      target="_blank"
                      href={sponsor.url ? sponsor.url : null}
                      rel="noreferrer">
                      <LazyLoadImage
                        src={sponsor.clientSponsorImage[0]?.url}
                        style={{
                          width: '100%',
                          padding: 5,
                        }}
                      />
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
Sponsors.propTypes = {
  event: PropTypes.object.isRequired,
};
