import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Logout } from '@dynamic_assets/logout.svg';
import { AccountIcon } from '@dynamic_assets/accounticon.svg';
import { AbstractsIcon } from '@dynamic_assets/abstractsIcon.svg.js';
import { PaymentsIcon } from '@dynamic_assets/paymentsIcon.svg.js';
import { showMyAbstracts } from '@actions';
import { connect } from 'react-redux';

class UserMenuMobile extends React.Component {
  constructor(props) {
    super(props);
    this.onUserMenuSelect = this.onUserMenuSelect.bind(this);
    this.changeOptionsDisplay = this.changeOptionsDisplay.bind(this);
    this.options = {
      account: window.lang[this.props.language]['myAccount'],
      payments: window.lang[this.props.language]['myPayments'],
      abstracts: window.lang[this.props.language]['myAbstracts'],
      logout: window.lang[this.props.language]['logout'],
    };
    this.state = {
      displayOptions: false,
      payments: [],
    };
  }

  componentDidMount() {
    if (this.props.getUser) this.props.getUser();
    const { accomodation, subscription, products } = this.props.user.data;
    if (!this.props.user.data.rn) {
      this.setPayments(accomodation, subscription, products);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.user.data.products) !==
      JSON.stringify(nextProps.user.data.products)
    ) {
      const payments = [...this.state.payments];
      nextProps.user.data.products?.map((product) => {
        let productName = product.name.split(' ');
        productName.splice(0, 1);
        productName = productName.join(' ');
        if (product.dueAmount > 0) {
          payments.push({
            referenceName: 'productpurchase_' + product.id,
            id: product.id,
            transactionId: 'N/A',
            type: productName,
            checkAvailabilityName: 'product_' + product.eventProductId,
            way: 'N/A',
            paidAmount: `${product.dueAmount} ${this.props.currency}`,
            status: 'Pending',
          });
        }
      });
      this.setState({ payments });
    }
  }

  setPayments(accomodation, subscription, products) {
    const accomodationPayment = accomodation?.payments;
    const accomodationSponsorship = accomodation?.sponsorships;
    const subscriptionPayment = subscription?.payment;
    const subscriptionSponsorship = subscription?.sponsorship;

    const payments = [];

    this.props.user.data.payments?.forEach((p) => {
      if (p.amount === 0) {
        return true;
      }
      payments.push({
        id: p.id,
        type: p.type === 'accomodation' ? 'Accommodation' : p.type,
        transactionId: p.transactionId === '' ? 'N/A' : p.transactionId,
        way: p.way,
        price: `${
          p.type === 'accomodation'
            ? accomodation.price
            : subscription.eventSubscriptionPrice
        } ${this.props.currency}`,
        dueAmount: `${
          p.type === 'accomodation'
            ? Number((accomodation.price - p.sumPaid).toFixed(2))
            : Number(
                (subscription.eventSubscriptionPrice - p.sumPaid).toFixed(2)
              )
        } ${this.props.currency}`,
        paidAmount: `${Number(p.amount.toFixed(2))} ${this.props.currency}`,
        status: 'Completed',
      });
    });

    products?.map((product) => {
      let productName = product.name.split(' ');
      productName.splice(0, 1);
      productName = productName.join(' ');
      if (product.dueAmount > 0) {
        payments.push({
          referenceName: 'productpurchase_' + product.id,
          id: product.id,
          transactionId: 'N/A',
          type: productName,
          checkAvailabilityName: 'product_' + product.eventProductId,
          way: 'N/A',
          paidAmount: `${product.dueAmount} ${this.props.currency}`,
          status: 'Pending',
        });
      }
    });
    // check accomodation
    if (accomodation && Object.keys(accomodation).length > 0) {
      //case there is accomodation

      //case accomodation is sponsored
      if (Object.keys(accomodationSponsorship).length > 0) {
        //case when accomodation is sponsored
        payments.unshift({
          referenceName: 'accomodation',
          id: accomodation.sponsorships.id,
          transactionId: 'N/A',
          type: 'Accommodation',
          way: `Sponsord By ${accomodation.sponsorships.orgSponsorName}`,
          paidAmount: `${accomodation.price} ${this.props.currency}`,
          status: 'Completed',
        });
      } else if (Object.keys(accomodationPayment).length > 0) {
        // case payment is partially paid
        if (accomodationPayment.dueAmount > 0) {
          payments.unshift({
            referenceName: 'accomodation',
            id: 'N/A',
            way: 'N/A',
            type: 'Accommodation',
            transactionId: 'N/A',
            paidAmount: `${accomodationPayment.dueAmount} ${this.props.currency}`,
            status: 'Pending',
          });
        }
      } else if (accomodation.price > 0) {
        // case payment is not paid at all and over zero

        payments.unshift({
          referenceName: 'accomodation',
          id: 'N/A',
          way: 'N/A',
          type: 'Accommodation',
          transactionId: 'N/A',
          paidAmount: `${accomodation.price} ${this.props.currency}`,
          status: 'Pending',
        });
      }
    }

    // check registration
    if (
      Object.keys(subscription).length > 0 &&
      (this.props.user.data.info.sponsored == 0 ||
        !this.props.user.data.info.sponsored)
    ) {
      //case when subscription exists

      //case subscription is sponsored
      if (Object.keys(subscriptionSponsorship).length > 0) {
        // case when subscription is sponsored
        payments.unshift({
          referenceName: 'registration',
          id: subscription.sponsorship.id,
          transactionId: 'N/A',
          type: 'Registration',
          way: `Sponsord By ${subscription.sponsorship.orgSponsorName}`,
          paidAmount: `${subscription.eventSubscriptionPrice} ${this.props.currency}`,
          status: 'Completed',
        });
      } else if (Object.keys(subscriptionPayment).length > 0) {
        // case when subscription is partially paid
        if (subscriptionPayment.dueAmount > 0) {
          payments.unshift({
            referenceName: 'registration',
            id: 'N/A',
            way: 'N/A',
            type: 'Registration',
            transactionId: 'N/A',
            paidAmount: `${subscriptionPayment.dueAmount} ${this.props.currency}`,
            status: 'Pending',
          });
        }
      } else if (subscription.eventSubscriptionPrice > 0) {
        // case when subscription not paid at all
        payments.unshift({
          referenceName: 'registration',
          id: 'N/A',
          way: 'N/A',
          type: 'Registration',
          transactionId: 'N/A',
          paidAmount: `${subscription.eventSubscriptionPrice} ${this.props.currency}`,
          status: 'Pending',
        });
      }
    }
    this.setState({ payments });
  }

  onUserMenuSelect(selection) {
    this.changeOptionsDisplay();
    switch (selection) {
      case 'account': {
        this.props.changeSection('account');
        break;
      }
      case 'payments': {
        this.props.changeSection('billing');
        break;
      }
      case 'abstracts': {
        this.props.showMyAbstracts();
        this.props.changeSection('abstractManagement');
        break;
      }
      case 'logout': {
        this.props.logout(true);
        break;
      }
    }
  }

  changeOptionsDisplay() {
    this.setState({ displayOptions: !this.state.displayOptions });
  }

  returnOptionIcon(selection) {
    switch (selection) {
      case 'account': {
        return (
          <div className="option-icon">
            <AccountIcon color="var(--gray-600)" />
          </div>
        );
      }
      case 'payments': {
        return (
          <div className="option-icon">
            <PaymentsIcon color="var(--gray-600)" />
          </div>
        );
      }
      case 'abstracts': {
        return (
          <div className="option-icon">
            <AbstractsIcon color="var(--gray-600)" />
          </div>
        );
      }
      case 'logout': {
        return (
          <div className="option-icon">
            <Logout color="var(--gray-600)" />
          </div>
        );
      }
    }
  }

  render() {
    const user = this.props.user.data;
    const paymentsLength = this.state.payments.filter(
      (p) => p.status === 'Pending'
    ).length;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <div className="account-accordion-wrapper">
            {/* UPDATE SECTION WHEN PROFILE PICS ARE AVAILABLE AGAIN */}
            {!!user && (
              <div className="user-account-no-avatar">
                <AccountIcon color={window.colorPalette.primary[600]} />
                {paymentsLength > 0 && (
                  <div
                    className="cart-indicator"
                    style={{
                      backgroundColor: window.colorPalette.primary[600],
                    }}
                  />
                )}
              </div>
            )}
            {user.info?.firstName && (
              <div className="user-information-wrapper">
                <div className="user-first-name"> {user.info?.firstName}</div>
                <div className="user-email"> {user.info?.email}</div>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="account-options">
            {Object.keys(this.options).map((key) => {
              if (
                (this.props.policy.type === 'public' ||
                  !this.props.event.abstractManagement) &&
                key === 'abstracts'
              ) {
                return null;
              }
              if (
                (this.props.policy.type === 'public' ||
                  !this.props.event.billing) &&
                key === 'payments'
              ) {
                return null;
              }
              return (
                <div
                  key={key}
                  className="account-option"
                  onClick={() => {
                    this.onUserMenuSelect(key);
                    this.props.closeNav();
                  }}>
                  {this.returnOptionIcon(key)}
                  {this.options[key]}
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    policy: state.api.policy.data,
    event: state.api.event.data,
  };
};

UserMenuMobile.propTypes = {
  language: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  changeSection: PropTypes.func.isRequired,
  currency: PropTypes.string,
  getUser: PropTypes.func,
  showMyAbstracts: PropTypes.func,
  closeNav: PropTypes.func,
  policy: PropTypes.object,
  event: PropTypes.object,
};

export default connect(mapStateToProps, { showMyAbstracts })(UserMenuMobile);
